import { createSaveOrderMachine } from './machines/save-order';
import { validate } from '@fingermarkglobal/validation';
import { interpret } from '@fingermarkglobal/utilities';

const doSaveOrder = ({ payload = null, timeout = 30, ip, transition = null }) => {
  validate({ name: 'doSaveOrder', parameters: { payload } });

  const machine = createSaveOrderMachine({ payload, timeout, ip });

  const { instance: saveOrderMachine } = interpret({
    name: 'saveOrderMachine',
    machine,
    transition,
  });

  return { saveOrderMachine };
};

export { doSaveOrder };
