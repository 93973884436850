import { createMachine } from 'xstate';

const refundMachine = createMachine({
  id: 'momopay-refund',
  initial: 'idle',
  context: {},
  states: {
    idle: {
      on: {
        INITIALISE: 'cancelled',
      },
    },
    cancelled: {
      type: 'final',
    },
  },
});

export { refundMachine };
