import { assign, sendParent } from 'xstate';
import {
  Transaction,
  TransactionsMachineContext
} from '@fingermarkglobal/types';

const setTransactionsAction = assign((_, event: { data: Transaction[] }) => ({
    transactions: event.data
}));


const sendParentFailureAction = sendParent(() => ({
  type: 'TRANSACTIONS_MACHINE_FAILURE'
}));

const sendParentSuccessAction = sendParent(
  (context: TransactionsMachineContext) => ({
    type: 'TRANSACTIONS_MACHINE_SUCCESS',
    transactions: context.transactions
  })
);

export {
  setTransactionsAction,
  sendParentFailureAction,
  sendParentSuccessAction
};
