import { createCancelOrderMachine } from './machines/cancel-order';
import { validate } from '@fingermarkglobal/validation';
import { interpret } from '@fingermarkglobal/utilities';
import { formatProvider } from '../shared';

const doCancelOrder = ({ payload = null, timeout = 30, transition = null, ip, provider:type = ''}) => {
  validate({ name: 'doCancelOrder', parameters: { payload } });

  const provider = formatProvider(type);
  const machine = createCancelOrderMachine({ payload, timeout, ip, provider });

  const { instance: cancelOrderMachine } = interpret({
    name: 'cancelOrderMachine',
    machine,
    transition,
  });

  return { cancelOrderMachine };
};

export { doCancelOrder };
