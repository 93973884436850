import { validate } from '@fingermarkglobal/validation';
import ky from 'ky';
import { request } from '../request';
import { decodeJwt } from '../utils/jwtUtil';

const getJwtToken = async () => {
  const storedToken = localStorage.getItem('jwtToken');
  if (storedToken) {
    try {
      const parsedJwt = decodeJwt(storedToken);
      const expiresAt = new Date(parsedJwt.exp * 1000).getTime();

      if (expiresAt > Date.now()) {
        return storedToken;
      }
    } catch (err) {
      logger.error('utilities - getJwtToken() - badly formatted token:', err);
    }
  }
  const jwtEndpoint = process.env.POI_APP_JWT_ENDPOINT || null;
  try {
    validate({ name: 'getJwtToken', parameters: { jwtEndpoint } });

    const response = await request
      .get(jwtEndpoint, {
        headers: {
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        },
      })
      .text();

    localStorage.setItem('jwtToken', response);
    return response;
  } catch (err) {
    if (err instanceof ky.HTTPError || err instanceof ky.TimeoutError) throw err;
    logger.error('utilities - getJwtToken() - error:', err);
    throw err;
  }
};

export { getJwtToken };
