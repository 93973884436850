import { request } from '@fingermarkglobal/request';
import ky from 'ky';

const shortenUrl = (url: string): [() => Promise<string>, AbortController] => {
  const controller: AbortController = new AbortController();
  const signal: AbortSignal = controller.signal;

  const action = async (): Promise<string> => {
    try {
      const shortenerUrl: string = process.env.POI_APP_URL_SHORTENER_ENDPOINT || null;

      const token: string = process.env.POI_APP_URL_SHORTENER_TOKEN || '';

      const domain: string = process.env.POI_APP_URL_SHORTENER_DOMAIN || '';

      const response = await request
        .post(`${shortenerUrl}`, {
          signal,
          searchParams: { api_token: token },
          json: {
            originalURL: url,
            domain,
          },
          headers: {
            authorization: token,
          },
        })
        .json();

      logger.debug(`url.shortener - response received: ${JSON.stringify(response)}`);

      const { secureShortURL: tinyUrl = url } = response || {};

      return tinyUrl;
    } catch (err) {
      if (err.name === 'AbortError') return;
      if (err instanceof ky.HTTPError || err instanceof ky.TimeoutError) throw err;

      logger.error(`url.shortener-  ${JSON.stringify(err)}`);

      return url;
    }
  };

  return [action, controller];
};

export { shortenUrl };
