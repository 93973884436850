import { interpret } from '@fingermarkglobal/utilities';
import { validate } from '@fingermarkglobal/validation';
import { menuMachine as machine } from '../machines/menu';

const resolveMenu = ({ config = null, transition = null } = {}) => {
  validate({ name: 'Resolve menu', paramaters: { config } });

  const { run } = interpret({
    name: 'menuMachine',
    machine,
    transition,
  });

  return run;
};

export { resolveMenu };
