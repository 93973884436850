import { NEXO_IV_LENGTH } from './enums';

export const xor = (a, b) => {
  const values = Buffer.alloc(NEXO_IV_LENGTH);
  for (let i = 0; i < NEXO_IV_LENGTH; i += 1) {
    values[i] = a[i] ^ b[i];
  }

  return values;
};
