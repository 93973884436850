import { assign } from 'xstate';
import {
  SerialActionEvent,
  SettingsActionEvent,
  TransactionActionEvent,
  RestaurantMachineContext
} from '@fingermarkglobal/types';

const setSerialAction = assign((_, event: SerialActionEvent) => ({
  origin: event?.origin,
  serial: event?.serial,
  port: event?.port,
}));

const setSettingsAction = assign((_, event: SettingsActionEvent) => ({
  count: event?.count,
  settings: event?.settings
}));

const setTransactionsAction = assign((_, event: TransactionActionEvent) => ({
  transactions: event.transactions
}));

const setSerialFailureAction = assign((_, event: { message: string }) => ({
  message: event.message
}));

const setSettingsFailureAction = assign(
  (context: RestaurantMachineContext, event: any) => {
    logger.debug(`restaurant.resolver - setSettingsFailureAction - Failed to resolve settings for serial ${context?.serial}`);
    logger.debug(`restaurant.resolver - setSettingsFailureAction - Context: ${context}`);
    logger.debug(`restaurant.resolver - setSettingsFailureAction - Event: ${event}`);

    return {
      message: `Failed to resolve settings for serial ${context?.serial}`
    };
  }
);

const setTransactionsFailureAction = assign(
  (context: RestaurantMachineContext) => ({
    message: `Failed to resolve transactions for serial ${context?.serial}`
  })
);

export {
  setSerialAction,
  setSettingsAction,
  setTransactionsAction,
  setSerialFailureAction,
  setSettingsFailureAction,
  setTransactionsFailureAction
};
