import { createMachine, assign } from 'xstate';
import { preOrder } from '../requests/pre-order';
import { PreOrderPayload } from '@fingermarkglobal/types';

const populateMessage = assign({
  data: (_, payload: any) => {
    const message = payload?.message;
    const data = payload?.data;
    const error = message?.message || data?.message;

    if (data && !error) {
      logger.debug(`[fm-api][order/pre] - data received:`, data);
      return data
    }

    if (error) {
      logger.error(`[fm-api][order/pre] - error received:`, error);
      return error
    }

    logger.error(`[fm-api][order/pre] - message received:`, message);
    return message;
  },
});

const createPreOrderMachine = ({ payload = null, timeout = 30, ip = undefined, provider = '' } = {}) =>
  createMachine<{
    ip?: string;
    data: unknown;
    timeout: number;
    payload: null | PreOrderPayload;
    provider: string;
  }>(
    {
      id: 'preOrder',
      initial: 'processing',
      context: {
        ip,
        payload,
        timeout,
        data: null,
        provider,
      },
      states: {
        processing: {
          invoke: {
            src: ({ payload, timeout }) => preOrder(payload, timeout, ip, provider),
            onDone: {
              target: 'success',
              actions: ['populateMessage']
            },
            onError: {
              target: 'error',
              actions: ['populateMessage']
            }
          }
        },
        success: {
          on: {
            // TODO: rename this
            IDLE: 'processing'
          }
        },
        error: { type: 'final' }
      }
    },
    {
      actions: {
        populateMessage
      }
    }
  );

export { createPreOrderMachine };
