import { paymentMachine as machine } from './machines/pay';
import { interpret } from '@fingermarkglobal/utilities';

export const pay = ({ amount = null, config = null, transition = null } = {}) => {
  const { instance: paymentMachine } = interpret({
    name: 'paymentMachine',
    machine,
    transition,
  });

  paymentMachine.send('INITIALISE', { amount, config, paymentMachine });

  return { paymentMachine };
};
