import { useRecoilValue } from 'recoil';
import { settingsState } from '@fingermarkglobal/atoms';

// TODO: remove this hook and use the new below (useSettingsAtPath)
const useSettings = () => {
  const settings = useRecoilValue(settingsState);

  return { settings };
};

const useSettingsAtPath = ({ path = '', defaultValue = undefined } = {}) => {
  const settings = useRecoilValue(settingsState);

  if (typeof settings !== 'object' || settings === null || typeof path !== 'string') {
    return defaultValue;
  }

  const getPropertyAtPath = (obj, value) => {
    return value?.split('.')?.reduce((acc, part) => acc && acc[part], obj);
  };

  return getPropertyAtPath(settings, path) ?? defaultValue;
};

export { useSettings, useSettingsAtPath };
