import { validate } from '@fingermarkglobal/validation';
import { v4 as uuid } from 'uuid';

const createPurchaseRequest = ({ amount = null, terminalId, authCode }) => {
  validate({ name: '[cbq-erc] Card Pay', paramaters: { amount, terminalId, authCode } });

  const transactionId = uuid();

  const requestEndpoint =
    'https://supersonic-zodac-bqqvoxpupq-ew.a.run.app/https://cloud.amp360.amobilepayment.com/api/command/queue';

  const request = {
    sourceSerial: 'FM-AMP360', // this could be anything
    targetTerminal: terminalId,
    authCode,
    payload: {
      cmdType: 'Purchase',
      Endpoint: 'TRANSACTION',
      ReqPayload: {
        BaseAmount: amount.toString(),
        CardEntryMethod: 'AUTO',
        AutoPrint: 'false',
        UserDefinedEchoData: transactionId,
      },
    },
  };

  return {
    transactionId,
    request,
    requestEndpoint,
  };
};

export { createPurchaseRequest };
