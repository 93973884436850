import { interpret } from '@fingermarkglobal/utilities';
import { couponMachine as machine } from './machines/coupon-machine';
import { CreateCouponMachineProps } from './types';

type GetCouponProductsProps = CreateCouponMachineProps & {
  transition?: () => void;
};

const getCouponProducts = ({
  couponNumber,
  coupons,
  transition,
  settings,
}: GetCouponProductsProps) => {
  const { run, instance: couponStateMachine } = interpret({
    name: 'couponMachine',
    machine,
    transition,
  });

  run({ event: 'GET', config: { couponNumber, coupons, settings } });

  return { couponStateMachine };
};

export { getCouponProducts };
