/**
 * Data Tags determine several different things, depending on their number groupings. Those groupings are:
 * Tag 001 – 099': ecr Transmission Data
 * Tag '100 – '299': General transaction Data
 * Tag '300 – '399': customerInformation
 * Tag '400 – '499': Host Response Data
 * Tag '500 – '599': Settlement Data
 * Tag '600 – '699': Merchant/Terminal Data
 * Tag '700 – '799': Receipt Data
 * Tag '800 – '899': Report Data (for TSI '3.00 and above)
 * Tag '900 - '999': Special Data
 */
const dataTags = {
  '001': 'ecrTransactionAmount',
  '002': 'ecrTenderType',
  '003': 'ecrClerkId',
  '004': 'ecrInvoiceNumber',
  '005': 'ecrAuthCode',
  '010': 'ecrcustomerReferenceNumber',
  '011': 'ecrReferenceNumber',
  '012': 'ecrPANLast4',
  '013': 'ecrTranTypeClass',
  '050': 'ecrReprintType',
  '051': 'ecrParameterType',
  100: 'transactionType',
  101: 'transactionStatus',
  102: 'transactionDate',
  103: 'transactionTime',
  104: 'transactionAmount',
  105: 'tipAmount',
  106: 'cashbackAmount',
  107: 'curchargeAmount',
  108: 'taxAmount',
  109: 'totalAmount',
  110: 'invoiceNumber',
  111: 'purchaseOrderNumber',
  112: 'referenceNumber',
  113: 'transactionSequenceNumber',
  114: 'tableNumber',
  115: 'ticketNumber',
  117: 'shipToPostal',
  118: 'clerkId',
  119: 'clerkName',
  121: 'dccOptInFlag', // dcc= Dynamic Currency Conversion
  122: 'dccConversionRate',
  123: 'dccCurrencyAlphaCode',
  124: 'dccAmount',
  125: 'dccTipAmount',
  126: 'dccTotalAmount',
  127: 'originalTransactionAmount',
  128: 'originalTipAmount',
  129: 'originalCashbackAmount',
  130: 'originalSurchargeAmount',
  131: 'originalTaxAmount',
  132: 'originalTotalAmount',
  134: 'originaltransactionAmount',
  135: 'numberOfCustomers',
  300: 'customerCardType',
  301: 'customerCardDescription',
  302: 'customerAccountNumber',
  303: 'customerLanguage',
  304: 'customerName',
  305: 'customerAccountType',
  306: 'customerCardEntryMode',
  307: 'customerNumber',
  308: 'emvAid',
  309: 'emvTvr',
  310: 'emvTsi',
  311: 'emvApplicationLabelOrPreferredName',
  312: 'cvmResult',
  313: 'cardNotPresent',
  314: 'amountDuePaymentType',
  315: 'customerReferenceNumber',
  316: 'emvTc',
  317: 'emvTcd',
  400: 'authorizationNumber',
  401: 'hostResponseCode',
  402: 'hostResponseText',
  403: 'hostResponseISOCode',
  404: 'retrievalReferenceNumber',
  405: 'amountDue',
  406: 'traceNumber',
  407: 'avsResult',
  408: 'cvvResult',
  411: 'cashOutAmount',
  412: 'hostTransactionReferenceNumber',
  500: 'batchNumber',
  501: 'batchTotalBalanceFlag',
  502: 'batchTotalAmount',
  503: 'batchTotalCount',
  504: 'batchSaleAmount',
  505: 'batchSaleCount',
  506: 'batchRefundAmount',
  507: 'batchRefundCount',
  508: 'batchVoidAmount',
  509: 'batchVoidCount',
  510: 'batchTipAmount',
  511: 'batchVoidTipAmount',
  512: 'batchCashbackAmount',
  513: 'batchVoidCashbackAmount',
  514: 'batchSurchargeAmount',
  515: 'batchVoidSurchargeAmount',
  516: 'batchTaxAmount',
  517: 'batchVoidTaxAmount',
  530: 'saleNetworkFeeAmount',
  531: 'voidNetworkFeeAmount',
  538: 'netTipAmount',
  539: 'netCashbackAmount',
  540: 'batchReturnTipAmount',
  550: 'hostName',
  600: 'demoIndicator',
  601: 'terminalId',
  602: 'merchId',
  603: 'merchCurrencyAlphaCode', // e.g. CAD or USD
  700: 'receiptHeader1',
  701: 'receiptHeader2',
  702: 'receiptHeader3',
  703: 'receiptHeader4',
  704: 'receiptHeader5',
  705: 'receiptHeader6',
  706: 'receiptHeader7',
  707: 'receiptFooter1',
  708: 'receiptFooter2',
  709: 'receiptFooter3',
  710: 'receiptFooter4',
  711: 'receiptFooter5',
  712: 'receiptFooter6',
  713: 'receiptFooter7',
  714: 'endorsementLine1',
  715: 'endorsementLine2',
  716: 'endorsementLine3',
  717: 'endorsementLine4',
  718: 'endorsementLine5',
  719: 'endorsementLine6',
  720: 'customerendorsementLine1',
  721: 'customerendorsementLine2',
  722: 'customerendorsementLine3',
  723: 'customerendorsementLine4',
  724: 'customerendorsementLine5',
  725: 'customerendorsementLine6',
  726: 'blankTipLineIndicator',
  727: 'tipAssistLine',
  728: 'taxRegistrationNumber',
  729: 'taxLabel',
  730: 'emvRespCode',
  731: 'authCodesHistory',
  732: 'institutionIdentificationNumber',
  800: 'TERMINAL CAPABILITIES',
  801: 'ADDITIONAL CAPABILITIES',
  802: 'COUNTRY CODE',
  803: 'TERMINAL TYPE',
  804: 'TRANS. CURRENCY CODE',
  805: 'TRANS. CURR. EXPONENT',
  806: 'ACQUIRER ID',
  807: 'MERCHANT CAT. CODE',
  808: 'MERCH IDENTIFIER',
  809: 'TRANS. REF. CURRENCY CODE',
  810: 'TRANS. REF. CURR. EXPONENT',
  811: 'REF. CURRENCY CONVERSION',
  812: 'Scheme',
  813: 'AID',
  814: 'AppName',
  815: 'AppVersion/EMVAVNSingle', // EMVAVNSingle shares same tag with AppVersion
  816: 'PARTIAL NAME SEL.',
  817: 'ENABLE AUTO SELECT',
  818: 'FALLBACK INDICATOR',
  819: 'FLOOR LIMIT',
  820: 'THRESHOLD VAL RDM SELECT',
  821: 'TAC DEFAULT',
  822: 'TAC DENIAL',
  823: 'TAC ONLINE',
  824: 'MAX TARGET PERCENT',
  825: 'TARGET PERCENT',
  826: 'DDOL',
  827: 'TDOL',
  828: 'EVTRNCAT',
  829: 'Option',
  830: 'TrnLmt',
  831: 'CvmLmt',
  832: 'TTQ',
  833: 'MAGAVN',
  834: 'EMVAVN',
  835: 'TCPCVM',
  836: 'TCPNoCVM',
  837: 'MTI',
  838: 'UDOL',
  839: 'MStripe',
  840: 'EMVAVN',
  841: 'TTI',
  842: 'TOS',
  843: 'RRL',
  844: 'RID',
  845: 'KEY INDEX',
  846: 'KEY MODULUS',
  847: 'KEY EXPONENT',
  848: 'EMV Key Date',
  849: 'InputCap',
  850: 'SecureCap',
  851: 'CVMCapEMVCVM',
  852: 'CVMCapEMVNoCVM',
  853: 'CVMCapMAGCVM',
  854: 'CVMCapMAGNoCVM',
  855: 'TrnLmtCard',
  856: 'TrnLmtMobile',
  857: 'KernelConfig',
  858: 'MAGAVNSingle/ AmexTCP', // It is shared by AmexTCP and MAGAVNSingle (Mastcard)
  859: 'TornTnxLife',
  860: 'TornTnxMax',
  861: 'EMVAVN',
  862: 'UnpredictNR',
  863: 'TnxCap',
  864: 'TermRiskMngmt',
  900: 'transactionRecord',
  920: 'Raw Print Data Size',
  921: 'Raw Print Data Text',
  930: 'EMV data tag (Tag EMV)',
  931: 'EMV data value',
  932: 'Report Tag Label',
  933: 'Report Tag Value',
  934: 'Error Result Code',
  935: 'Error Message',
  936: 'Activity Event',
  937: 'Activity Data Affected',
  938: 'Total Number of Records',
};

const getTagNameByCode = code => {
  return dataTags[`${code}`];
};

export { getTagNameByCode };
