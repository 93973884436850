import { createMachine, assign } from 'xstate';
import { WebkassaUserDetails } from '@fingermarkglobal/types';
import { startShift } from '../requests/start-shift';

const populateMessage = assign({
  data: (_, payload: any) => {
    const message = payload?.message;
    const error = message?.message;
    const data = payload?.data;

    if (data) {
      logger.debug(`[fm-api][shift/start] - data received:`, data);
      return data;
    }

    if (error) {
      logger.error(`[fm-api][shift/start] - error received:`, error);
      return error;
    }

    logger.error(`[fm-api][shift/start] - message received:`, message);
    return message;
  },
});

const createStartShiftMachine = ({ payload = null, timeout = 30, ip = '', provider = '' } = {}) =>
  createMachine<{
    data: unknown;
    timeout: number;
    payload: null | WebkassaUserDetails;
    provider: string;
  }>(
    {
      id: 'startShift',
      initial: 'processing',
      context: {
        payload,
        timeout,
        data: null,
        provider,
      },
      states: {
        processing: {
          invoke: {
            src: ({ payload, timeout }) => startShift(payload, timeout, ip, provider),
            onDone: {
              target: 'success',
              actions: ['populateMessage'],
            },
            onError: {
              target: 'error',
              actions: ['populateMessage'],
            },
          },
        },
        success: {
          on: {
            IDLE: 'processing',
          },
        },
        error: { type: 'final', entry: ['populateMessage'] },
      },
    },
    {
      actions: {
        populateMessage,
      },
    },
  );

export { createStartShiftMachine };
