import { assign } from 'xstate';

const handleError = assign((context, event) => {
  logger.error('cbq-ecr - error:', event.data?.message);

  return {
    ...context,
    message: event.data?.message, // catches `Error` objects that are thrown as it cant automatically destrucutre the `message` prop
    ...event.data,
  };
});

export { handleError };
