const transactionType = {
  tag: '100',
  '00': 'Sale / Purchase',
  '01': 'Pre Auth',
  '02': 'Pre Auth Completion',
  '03': 'Refund',
  '04': 'Force',
  '05': 'Void',
  '06': 'Card Balance Inquiry',
  '07': 'Auth Only',
  '08': 'Incremental Auth',
  20: 'Settlement',
  21: 'Auto Settlement',
  22: 'Reprint Receipt',
  30: 'Detail Report',
  31: 'Summary Report',
  33: 'Clerk Summary Report',
  35: 'Open Pre Auth',
  36: 'Recent Error',
  37: 'Activity Report',
  38: 'Clerk ID List',
  39: 'EMV Parameters',
  40: 'EMV Statistic',
  41: 'EMV Public Key',
  42: 'Terminal Info',
  43: 'EMV Key Date',
};

const transactionStatus = {
  tag: '101',
  '00': 'Approved',
  '01': 'Partial Approved',
  10: 'Declined by Acquirer',
  11: 'Communication Error',
  12: 'Cancelled by User',
  13: 'Timed out on User Input',
  14: 'Transaction Not Completed',
  15: 'Batch Empty',
  16: 'Declined by Merchant',
  17: 'Record Not Found',
  18: 'Transaction Already Voided',
  30: 'Invalid ECR Parameter',
  31: 'Battery Low',
  99: 'Receipt print',
};

const tenderType = {
  tag: '002',
  0: 'All Card Types',
  1: 'Debit',
  2: 'Credit',
  7: 'Cash',
};

const cardNotPresent = {
  tag: '313',
  0: 'Card Not Present',
  1: 'Phone Order',
  2: 'Mail Order',
  3: 'E-Commerce',
};

const cardEntryMode = {
  tag: '306',
  0: 'S', // '[S] Magnetic Stripe'
  1: 'C', // '[C] Insert (Chip)',
  2: 'P', // '[P] Tap (Contactless)',
  3: 'M', // '[M] Manual Entry',
  4: 'CS', // '[CS] Chip Fallback to Swipe',
  5: 'CM', // '[CM] Chip Fallback to Manual',
  6: 'CNP', // '[CNP] Card Not Present Manual',
};

const customerVerificationMethod = {
  tag: '312',
  0: 'No Verification',
  1: 'Verified by PIN',
  2: 'Signature Required',
  3: 'PIN & Signature',
};

const demoMode = {
  tag: '600',
  1: 'DEMO Mode',
};

const blankTipLine = {
  tag: '726',
  1: 'Print blank tip line',
  2: 'Print blank tip line with Tip Approval signature line',
};

const transactionCardType = {
  tag: '300', // Customer Card Type
  '00': 'Debit',
  '01': 'Visa',
  '02': 'MasterCard',
  '03': 'Amex',
  '04': 'Diners Club',
  '05': 'Discover Card',
  '06': 'JCB',
  '07': 'Union Pay Card',
  '08': 'Other Credit Card',
  '09': 'Gift Card',
  10: 'Cash',
  11: 'EBT Food Stamp',
  12: 'EBT Cash Benefit',
};

/**
 * Use this method to filter through all enums and return an specific one based off a tag.
 * Eg. findCodeByTag(100) will return the enum transactionType.
 * @param {String|Number} tag - The tag for which codes belong to.
 * @returns {Object|undefined} - Returns either an object with codes enum, or undefined if none found.
 */
function findCodeByTag(dataTag, value) {
  let code;

  switch (dataTag) {
    case '100':
      code = transactionType[`${value}`];
      break;
    case '101':
      code = transactionStatus[`${value}`];
      break;
    case '002':
      code = tenderType[`${value}`];
      break;
    case '313':
      code = cardNotPresent[`${value}`];
      break;
    case '306':
      code = cardEntryMode[`${value}`];
      break;
    case '312':
      code = customerVerificationMethod[`${value}`];
      break;
    case '600':
      code = demoMode[`${value}`];
      break;
    case '726':
      code = blankTipLine[`${value}`];
      break;
    case '300':
      code = transactionCardType[`${value}`];
      break;
    default:
      code = undefined;
  }

  return code;
}

/**
 * For many Data Tags, there is a Message for the Value it returns. For example:
 * Data Tag = Transaction Type (100).
 * If the Value returned is '00, then Transaction Type is 'Sale / Purchase'.
 */
export { findCodeByTag };
