const getCustomisations = (productSelectedCategories, customisations) => {
  const customisationsToCart = [];

  Object.entries(customisations).forEach(([categoryId, customisedItems]) => {
    const filteredCategories = productSelectedCategories.filter(
      category => category.id.toString() === categoryId,
    );
    const originalCategory = filteredCategories.length > 0 ? filteredCategories[0] : undefined;

    const idsItemsCustomised = customisedItems.map(defaultProduct => defaultProduct.id);

    const products = originalCategory?.products || [];
    const defaultProductsRemoved = products
      .filter(defaultProduct => defaultProduct.default)
      .filter(defaultProduct => !idsItemsCustomised.includes(defaultProduct.id));

    defaultProductsRemoved.forEach(productRemoved => {
      customisationsToCart.push(productRemoved.id);
    });

    customisedItems
      .filter((v, i) => customisedItems.indexOf(v) === i)
      .filter(recipe => {
        return (
          !recipe.default ||
          (recipe.default &&
            customisedItems.filter(itemRecipe => itemRecipe.id === recipe.id).length > 1)
        );
      })
      .forEach(productCombo => {
        customisationsToCart.push(productCombo.id);
      });
  });

  return customisationsToCart;
};

export { getCustomisations };
