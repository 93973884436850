import { interpret } from '@fingermarkglobal/utilities';
import { createLoyaltyLogOutMachine } from './machines/loyalty-logout';

const doLoyaltyLogOut = ({ transition = null, token }) => {
  const machine = createLoyaltyLogOutMachine({ token });

  const { instance: loyaltyLogoutMachine } = interpret({
    name: 'loyaltyLogoutMachine',
    machine,
    transition,
  });

  return { loyaltyLogoutMachine };
};

export { doLoyaltyLogOut };
