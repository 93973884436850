import { nexoDecrypt } from '@fingermarkglobal/nexo';
import { assign } from 'xstate';

const decryptResponse = ({ response, config } = {}) => {
  const { SaleToPOIResponse } = response;
  const { settings } = config;
  const { passphrase: secret, isLocal = true } = settings;

  if (!isLocal) return response;

  const { NexoBlob: blob, SecurityTrailer: trailer } = SaleToPOIResponse;
  const data = { blob, trailer };

  const decrypted = nexoDecrypt({ data, secret });

  return decrypted;
};

const destructError = assign((context, event) => {
  return {
    ...context,
    ...event.data,
    message: event.data?.message, // catches `Error` objects that are thrown as it cant automatically destrucutre the `message` prop
  };
});

export { decryptResponse, destructError };
