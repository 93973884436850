import { setSerial, getSerial } from '../caching';

const persistSerialToCache = async (serial: string): Promise<void> => {
    try {
        return await setSerial('serial', serial);
    } catch (err) {
        logger.error('serial - persistSerialToCache - error:', err);
    }
}

const retrieveSerialFromCache = async (): Promise<null | string> => {
    try {
        return await getSerial('serial');
    } catch (err) {
        logger.error('serial - retrieveSerialFromCache - error:', err);
    }
    return null;
}

export {
    persistSerialToCache,
    retrieveSerialFromCache
}