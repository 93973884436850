import { Characters, SuccessCodes } from '../enums';

const isAcknowledged = buffer => {
  const firstByte = buffer.slice(0, 2).toString('hex');

  return firstByte === Characters.ACK;
};

const isTransactionApproved = statusCode => {
  if (!statusCode) {
    return false;
  }

  return SuccessCodes.includes(statusCode);
};

const validateRequest = payload => {
  if (!payload.transactionName) {
    throw new Error('Params do not include transactionName, mandatory field.');
  }
  if (!payload.tid) throw new Error('All requests require field tid as well.');

  switch (payload.transactionName) {
    case 'auth':
      if (!payload.amount) {
        throw new Error('Auth requires field amount as well.');
      }
      if (!payload.ecrNumber) {
        throw new Error('Auth requires field ecrNumber as well.');
      }
      break;
    case 'settlement':
      break;
    case 'void':
      if (!payload.amount) {
        throw new Error('Void requires field amount as well.');
      }
      if (!payload.messNumber) {
        throw new Error('Void requires field messNumber as well.');
      }
      if (!payload.voidRcptNum) {
        throw new Error('Void requires field voidRcptNum as well.');
      }
      break;
    default:
      throw new Error('Unsupported transaction.');
  }
};

const validateResponse = payload => {
  if (!payload.message) {
    throw new Error('Message field empty, nothing to parse.');
  }
  if (!Buffer.isBuffer(payload.message)) {
    throw new Error('Message field is not a Buffer, incorrect data type.');
  }
};

const validatePayload = payload => {
  if (typeof payload !== 'object' || Array.isArray(payload)) {
    throw new Error('Payload sent is not an object, please review params.');
  }
  if (!payload.isRequestOrResponse) {
    throw new Error('Params do not include isRequestOrResponse, mandatory field.');
  }

  switch (payload.isRequestOrResponse) {
    case 'request':
      validateRequest(payload);
      break;
    case 'response':
      validateResponse(payload);
      break;
    default:
      throw new Error('Incorrect isRequestOrResponse. Options are: request || response.');
  }
};

export { validatePayload, isAcknowledged, isTransactionApproved };
