import ky from 'ky';
import { getJwtToken, request } from '@fingermarkglobal/request';

const HEALTH_LOG_TAG = '[Health] ';

// This should probably be moved to @fingermarkglobal/fm-api, if we don't end up entirely removing it in favour of including kioskVersion in the heartBeatApi payloads.
const reportKioskVersion = async ({ serial, settings } = {}) => {
  if (!serial || !settings) {
    logger.error(`${HEALTH_LOG_TAG}[Version] - ${serial} - is missing a valid serial and settings`);
    return undefined;
  }

  const { id: deviceSettingsFirestoreId, settings: settingsCollection } = settings;
  const {
    deviceId: deviceFirestoreId,
    storeId: storeFirestoreId,
    organisation: organisationFirestoreId,
  } = settingsCollection?.properties || {};
  const endpoint = `${process.env.POI_APP_HAWKE_ENDPOINT}/update-kiosk-version`;
  const kioskVersion = process.env.KIOSK_VERSION;

  const requiredFields = [
    kioskVersion,
    endpoint,
    serial,
    organisationFirestoreId,
    storeFirestoreId,
    deviceFirestoreId,
    deviceSettingsFirestoreId,
  ];

  if (requiredFields.some(value => !value)) {
    logger.error(
      `${HEALTH_LOG_TAG}[Version] - ${serial} - missing required fields: ${requiredFields
        .filter(value => !value)
        .join(', ')})}`,
    );
    return undefined;
  }

  const jwtToken = await getJwtToken();

  if (!jwtToken) {
    logger.error(`${HEALTH_LOG_TAG}[Version] - ${serial} - could not fetch jwt token`);
    return undefined;
  }

  try {
    const response = await request
      .post(endpoint, {
        headers: { Authorization: `Bearer ${jwtToken}` },
        json: {
          kioskVersion,
          serial,
          organisationFirestoreId,
          storeFirestoreId,
          deviceFirestoreId,
          deviceSettingsFirestoreId,
        },
      })
      .then(res => res.json());

    return response;
  } catch (err) {
    // Logging errors as advised: // As advised: https://fingermarknz.slack.com/archives/C023D5VNKV4/p1701751541520309
    logger.error(
      `${HEALTH_LOG_TAG}[Version] - ${serial} - sending request failed`,
      JSON.stringify(err, Object.getOwnPropertyNames(err)),
    );
    return undefined;
  }
};

const pingHealthService = async ({ serial, settings }) => {
  if (!serial || !settings) {
    logger.error(`${HEALTH_LOG_TAG}[Ping] - ${serial} - is missing a valid serial and settings`);
    return null;
  }
  if (!process.env.POI_APP_HEARTBEAT_API) {
    logger.error(`${HEALTH_LOG_TAG}[Ping] - ${serial} - is missing POI_APP_HEARTBEAT_API`);
    return null;
  }
  const { customer: organisation } = settings?.settings?.datasource || {};
  if (!organisation) {
    logger.error(`${HEALTH_LOG_TAG}[Ping] - ${serial} - is missing organisation`);
    return null;
  }

  const formattedSerial = `${serial}`.replace(':', '');
  const endpoint = process.env.POI_APP_HEARTBEAT_API;

  try {
    await ky.post(endpoint, {
      prefixUrl: '',
      json: {
        config: {
          pretty: true,
          exit_status: 0,
          type: 'Service',
          performance_data: [],
          plugin_output: 'PING OK - Packet loss = 0%',
          filter: `host.name=="${formattedSerial}.${organisation}.fingermark.tech"`,
        },
      },
    });
    return true;
  } catch (err) {
    logger.error(`${HEALTH_LOG_TAG}[Ping] - ${serial} - could not send ping`, err);
    return false;
  }
};

const checkOnlineStatus = async ({ serial } = {}) => {
  try {
    const url = new URL(window.location.origin);
    url.searchParams.set('q', new Date().toString());
    await ky.head(url.toString(), {
      prefixUrl: '',
    });
    return true;
  } catch (err) {
    logger.error(`${HEALTH_LOG_TAG} - ${serial} - is offline`);
    return false;
  }
};

export { pingHealthService, checkOnlineStatus, HEALTH_LOG_TAG, reportKioskVersion };
