/**
 * Generate a random number from 1 to 1000
 */
const generateRandomNumber = () => {
  return Math.floor(Math.random() * 1000) + 1;
};

/**
 * Generate a random phone number from 999998000 and 999999998
 */
const generateRandomPhoneNumber = () => {
  return (Math.floor(Math.random() * 1998) + 999998000).toString();
};

export { generateRandomNumber, generateRandomPhoneNumber };
