import { interpret } from '@fingermarkglobal/utilities';
import { createloyaltyGuestClaimMachine } from './machines/loyalty-guest-claim';

const doLoyaltyGuestClaim = ({
  transition = null,
  kioskSerialKey,
  storeId,
  organisationId,
  sessionId,
  cart,
}) => {
  const machine = createloyaltyGuestClaimMachine({
    kioskSerialKey,
    storeId,
    organisationId,
    sessionId,
    cart,
  });

  const { instance: loyaltyGuestClaimMachine } = interpret({
    name: 'loyaltyGuestClaimMachine',
    machine,
    transition,
  });

  return { loyaltyGuestClaimMachine };
};

export { doLoyaltyGuestClaim };
