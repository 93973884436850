import React from 'react';

import {
  AccessibilityWrapper,
  AccessibilityButton,
  TimeoutModal,
  useTemplate,
  Footer,
} from '@fingermarkglobal/cringer.components';

import { Header } from '../../components/stateless/header';
import { Decoration } from '../../components/stateless/decoration';

import CartIconImage from '../../assets/images/cart-icon.svg';

import Standing from '@fingermarkglobal/cringer.components/src/assets/images/icons/accessibility/standing.svg';
import Wheelchair from '@fingermarkglobal/cringer.components/src/assets/images/icons/accessibility/wheelchair.svg';

const CustomDefaultTemplate = ({
  children,
  footerEnabled = true,
  headerEnabled = true,
  timeoutEnabled = true,
  templateOverride = false,
  accessibilityActionEnabled = true,
}) => {
  const {
    header,
    footer,
    isOnline,
    isCouponEnabled,
    isTimeoutEnabled,
    accessibilityEnabled,
    timeoutActionOverride,
    modalAccessibilityClass,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
  });

  const showFooterAccessibilityButton = accessibilityActionEnabled && footerEnabled;
  const isAccessibilityButtonEnabled = accessibilityActionEnabled || showFooterAccessibilityButton;

  return (
    <div className="flex flex-col justify-between w-full h-screen">
      <Decoration showLogo={accessibilityEnabled && isAccessibilityButtonEnabled} />
      <AccessibilityWrapper
        className={`flex flex-col ${!accessibilityEnabled && 'accessibility-static'}`}
        isAccessibilityButtonEnabled={isAccessibilityButtonEnabled}
      >
        <div className="flex flex-col w-screen h-full">
          {headerEnabled && <Header {...header} isCouponEnabled={isCouponEnabled} />}

          <div className="flex flex-col h-full overflow-auto">{children}</div>

          {footerEnabled && (
            <Footer
              {...footer}
              accessibilityEnabled={accessibilityEnabled}
              showAccessibilityButton={showFooterAccessibilityButton}
              OverwriteCartIcon={CartIconImage}
            />
          )}

          {accessibilityActionEnabled && (
            <AccessibilityButton
              AccessibilityIcon={accessibilityEnabled ? Standing : Wheelchair}
              className="absolute bottom-0 left-0 z-40 hidden w-24 h-24 p-2 my-8 ml-8 shadow-lg md:block"
            />
          )}

          {isTimeoutEnabled && isOnline && (
            <TimeoutModal
              onTimeout={timeoutActionOverride}
              modalAccessibilityClass={modalAccessibilityClass}
            />
          )}
        </div>
      </AccessibilityWrapper>
    </div>
  );
};

export { CustomDefaultTemplate };
