const evaluateCount = ({ categoryMax, categoryAutoBalance, categoryTotal, count } = {}) => {
  const { updatedByHandler = false, current } = count || {};

  if (categoryAutoBalance) {
    const balanceAmountDiff = categoryMax - categoryTotal;
    const evaluatedCurrentCount = updatedByHandler ? current : current + balanceAmountDiff;

    return {
      ...count,
      current: evaluatedCurrentCount,
      updatedByHandler: false,
    };
  }

  return {
    ...count,
    updatedByHandler: false,
  };
};

export { evaluateCount };
