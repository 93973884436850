const buildRefundPayload = (deviceConfig, totalPrice, settings, transactionInfo, mockState) => {
  const adyen = settings?.settings?.payment?.adyen || {};
  const noon = settings?.settings?.payment?.noon || {};
  const verifone = settings?.settings?.payment?.verifone || {};
  const fab = settings?.settings?.payment?.fab || {};
  const refundTimeout = settings?.settings?.interval?.pinpad?.refund || 30;

  const payload = {
    refundInfo: {
      amount: totalPrice,
      refundTimeout,
      transactionId: transactionInfo?.transactionId || '',
      voidRcptNum: transactionInfo?.voidRcptNum || '',
      messNumber: transactionInfo?.messNumber || '',
    },
    settings: {
      adyen,
      noon,
      verifone,
      marshal: fab,
      skip: {},
    },
    deviceConfig,
    mock: {
      state: '',
    },
  };

  if (!mockState) {
    delete payload.mock;
  }

  return payload;
};

export { buildRefundPayload };
