import { receiver } from '@fingermarkglobal/chrome-messages';
import { request as requester, HTTPError, TimeoutError } from '@fingermarkglobal/request';

class HTTPServer {
  listen() {
    receiver({ handler: this.receive });
  }

  static async receive({ request }) {
    const { id, payload = null } = request;

    try {
      // https://github.com/sindresorhus/ky#kyinput-options
      const response = await requester(payload);

      return { id, payload: response };
    } catch (err) {
      if (err instanceof HTTPError || err instanceof TimeoutError) throw err;
      logger.error('protocols - HTTPServer - error:', err);
    }

    return null;
  }
}

export { HTTPServer };
