import { queryStatus } from '../../../api/query-status';

let currentMachine;
let queryPaymentStatusTimeoutId;
let startPaymentStatusTimeoutId;

const queryPaymentStatus = async (_, event) => {
    
    const { order, config } = event;
    let { paymentMachine } = event;
    if (paymentMachine) {
      currentMachine = paymentMachine;
    } else {
      paymentMachine = currentMachine;
    }

    const txnStatus = await queryStatus({ order, settings: config.settings });

    if(txnStatus?.error) {
      logger.error('[MOMO] - query-status - error:', txnStatus.error);
      clearTimeout(startPaymentStatusTimeoutId);
      currentMachine.send('FAILURE', 
          { message: txnStatus.error });
    }
  
    if (txnStatus?.resultCode == 0) { // 0 success/paid, 1000 waiting, 1005 expired
      clearTimeout(startPaymentStatusTimeoutId);
      currentMachine.send('SUCCESSFULL', { amount: order.amount, txnId: `${txnStatus?.transId}`, config });
    
    } else if (txnStatus?.resultCode == 1005) { // qr code expired
      logger.debug('[MOMO] - query-status - qr code expired:', txnStatus);
      clearTimeout(startPaymentStatusTimeoutId);

      currentMachine.send('EXPIRED', {
        amount: order.amount, 
        config: { order: { orderId: order.orderId } }
      });
    
    } else if (txnStatus?.resultCode == 1000) { // still waiting for customer to pay
      clearTimeout(queryPaymentStatusTimeoutId);
      queryPaymentStatusTimeoutId = setTimeout(() => {
        queryPaymentStatus(null, event);
      }, 5000); // poll again after 5 sec
    
  } else {
    
    logger.error('[MOMO] - query-status - error:', { txnStatus });
    clearTimeout(startPaymentStatusTimeoutId);
    currentMachine.send('FAILURE', 
      { message: `Momo payment, service failure: ${txnStatus?.message}, error code: ${txnStatus?.resultCode}` });
  }
};

export const startPaymentStatusPinger = async (_, event) => {

  const { config = {} } = event || {};
  const { settings = {} } = config || {};
  const { timeout = 30 } = settings || {};

  startPaymentStatusTimeoutId = setTimeout(() => {
    clearTimeout(startPaymentStatusTimeoutId);
    logger.error('momo-payment - query-status - error: payment timed out');
    currentMachine.send('FAILURE', { message: 'Payment timed out' });
  }, timeout * 1000);

  queryPaymentStatus(_,event);
};
