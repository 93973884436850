const commit = ({ createUpdater, setCart, product, format, cart, staged, reformat } = {}) => {
  const update = createUpdater({ set: setCart });

  if (product) {
    const commitable = format({ product, update, isRootProduct: true });
    commitable.posItemId = product.posItemId;
    return setCart(cartItems => [...cartItems, commitable]);
  }

  /**
   * Having an uid in the staged item means that it was reformatted
   * from the cart and we should remove the old version from the cart
   * to add the new object. We also have to make sure the products will
   * keep the same order.
   */
  const editingCartItemIndex = cart.findIndex(item => item.uid === staged.uid);

  if (editingCartItemIndex >= 0) {
    const editingCartItem = reformat({ product: staged, update, isRootProduct: true });
    const newCart = [...cart];
    newCart[editingCartItemIndex] = editingCartItem;
    return setCart([...newCart]);
  }

  setCart([...cart, reformat({ product: staged, update, isRootProduct: true })]);
};

const getIsAvailable = (
  updatedItems,
  defaultValue = true,
  comparator = () => {},
  nestedComparator = undefined,
) => {
  let updatedItem = updatedItems.find(comparator);
  if (nestedComparator) updatedItem = updatedItem?.customisations?.find(nestedComparator);
  return updatedItem?.isAvailable ?? defaultValue;
};

const setValidatedValues = ({
  setCart,
  validatedOrderPayload,
  cart,
  formatValidatedValues,
} = {}) => {
  const { cart: validatedCart } = validatedOrderPayload || {};
  const { items: validatedItems = [] } = validatedCart || {};

  const formattedCart = cart.map(item => {
    const validatedItem = validatedItems.find(validatedItem => validatedItem.uid === item.uid);
    if (!validatedItem) return item;
    const { price: validatedPrice, isAvailable, product } = validatedItem;
    const {
      customisations: validatedCustomisations,
      comboProducts: validatedComboProducts,
    } = product;
    // updates availability flag for add-on items
    const updatedCustomisations = item.customisations.map(customisation => ({
      ...customisation,
      products: customisation.products.map(product => ({
        ...product,
        isAvailable: getIsAvailable(
          validatedCustomisations,
          product.isAvailable,
          validatedCustomisation =>
            validatedCustomisation.productId === product.productId.toString(),
        ),
      })),
    }));
    // updates availability flag for meal items
    const updatedOptions = item.options.map(option => ({
      ...option,
      products: option.products.map(product => {
        return {
          ...product,
          customisations: product.customisations.map(customisation => ({
            ...customisation,
            products: customisation.products.map(productItem => ({
              ...productItem,
              isAvailable: getIsAvailable(
                validatedComboProducts,
                productItem.isAvailable,
                validatedComboProduct => validatedComboProduct.id === product.id.toString(),
                validatedCustomisation =>
                  validatedCustomisation.productId === productItem.productId.toString(),
              ),
            })),
          })),
          isAvailable: getIsAvailable(
            validatedComboProducts,
            product.isAvailable,
            validatedComboProduct => validatedComboProduct.id === product.id.toString(),
          ),
        };
      }),
    }));
    const unitPrice = product?.price || item?.price?.unit;
    return formatValidatedValues({
      item,
      price: validatedPrice,
      isAvailable,
      unitPrice,
      customisations: updatedCustomisations,
      options: updatedOptions,
    });
  });
  setCart(formattedCart);
};
export { commit, setValidatedValues };
