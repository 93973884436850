const responseCodes = {
  RECEIPT_INFORMATION: '99',
  TERMINAL_BUSY: '95',
  TRANSACTION_RESPONSE: '00',
  CANCELLED: '12',
  TIMEOUT: '13',
};

const refusalReasons = {
  10: 'Declined by Acquirer',
  11: 'Communication Error',
  12: 'Cancelled by User',
  13: 'Timed out on User Input',
  14: 'Transaction Not Completed',
  15: 'Batch Empty',
  16: 'Declined by Merchant',
  17: 'Record Not Found',
  18: 'Transaction Already Voided',
  30: 'Invalid ECR Parameter',
  31: 'Battery Low',
};

const getRefusalReason = code => {
  const refusalReason = refusalReasons[`${code}`];

  if (!refusalReason) return 'Unknown refusal reason';

  return refusalReason;
};

export { responseCodes, getRefusalReason };
