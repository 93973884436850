const resultMessage = {
  rawResponse: '',
  success: false,
  message: null,
};

const parseResponse = message => {
  try {
    if (!message?.status) {
      throw new Error('Failed to get response');
    }

    if (message?.status === 'DONE') {
      if (
        message?.transInfo?.returnCode === '00' &&
        message?.transInfo?.transStatus === 'APPROVE'
      ) {
        resultMessage.rawResponse = message;
        resultMessage.success = true;
      } else {
        resultMessage.message = 'Transaction Failed';
        resultMessage.success = false;
      }
    } else {
      resultMessage.message = 'Transaction Cancelled';
      resultMessage.success = false;
    }

    return resultMessage;
  } catch (error) {
    throw new Error(error);
  }
};

export { parseResponse };
