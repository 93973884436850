import { assign } from 'xstate';

const handleError = assign((context, event) => {
  const transactionError = event.data; // Error type cannot be destructured
  const transaction = transactionError?.transaction;
  const message = transactionError?.message;
  return {
    ...context,
    message,
    previousTransaction: transaction,
  };
});

export { handleError };
