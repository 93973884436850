import { atom, selector } from 'recoil';

const customerState = atom({
  key: 'customerState',
  default: null,
});

const formattedCustomerState = selector({
  key: 'formattedCustomerState',
  get: ({ get }) => {
    const customer = get(customerState);
    const {
      firstName = '',
      lastName = '',
      email = process.env.POI_APP_CUSTOMER_EMAIL_DEFAULT,
      phoneNumber = '',
      receiveMarketingEmail = false,
    } = customer || {};

    return {
      firstName,
      lastName,
      email,
      phoneNumber,
      receiveMarketingEmail,
    };
  },
});
export { customerState, formattedCustomerState };
