const formattedHydrater = ({ find = null } = {}) => product => {
  if (!find) throw new Error('No `find` passed into `formattedHydrater`');

  const { id = null } = product || {};

  if (!id) {
    logger.warn('No object with an `id` passed into `hydrate`');
    return;
  }

  const { options = [], customisations = [] } = product;

  const found = find(product);

  const hydrate = formattedHydrater({ find });

  return {
    ...found,
    options: options.map(hydrate),
    customisations: customisations.map(hydrate),
  };
};

// This options hydrater is quick dense and hard to understand
// Ive commented on why a lot of things are happening, but this is
// meant to be alleviated by a menu restructure.
const createOptionsHydrater = ({ find = null, hydrate = null } = {}) => item => {
  if (!find) throw new Error('No `find` passed into `createOptionsHydrater`');
  if (!hydrate) throw new Error('No `hydrate` passed into `createOptionsHydrater`');

  const { id, combo = {} } = item;
  const { categories = [], filters = [] } = combo;

  // combo `filters` are groups of items that go together for this product
  // (small, medium, large combos etc)
  const groupings = filters.map(filter => {
    // These combo groups of items also have options within them
    // (sprite, coke, fanta etc)
    const options = categories.map(category => {
      // All products are in a `categories` array, but include a filter id
      // to show which filter / group theyre apart of, so we have to
      // filter out products not in this filter / group
      const filteredProducts = category.products.filter(
        product => `${product.filter}` === `${filter.id}`,
      );

      const hydratedProducts = filteredProducts.map(product => ({
        // destruct raw product /after/ so we can keep combo / item specific values
        ...hydrate(product),
        ...product,
      }));

      return { ...category, products: hydratedProducts };
    });

    const group = {
      ...filter, // the filter / group also contains info for pricing, images etc
      productId: id, // keep a reference to the actual product id as `id` is used for filtering
      options,
    };

    return group;
  });

  return groupings;
};

const createCustomisationHydrater = ({ find = null } = {}) => item => {
  if (!find) throw new Error('No `find` passed into `createCustomisationHydrater`');

  const { categories } = item.customise;

  const customisations = categories.map(category => ({
    ...category,
    // destruct raw product /after/ so we can keep combo / item specific values
    products: category.products.map(product => ({ ...find(product), ...product })),
  }));

  return customisations;
};

const rawHydrater = ({ find } = {}) => product => {
  if (!find) throw new Error('No `find` passed into `formattedHydrater`');

  const { id = null } = product || {};

  if (!id) {
    logger.warn('No object with an `id` passed into `hydrate`');
    return;
  }

  const hydrate = rawHydrater({ find });
  const found = find(product);

  const hydrateOptions = createOptionsHydrater({ find, hydrate });
  const hydrateCustomisations = createCustomisationHydrater({ find });

  const parsedPrice = typeof found?.price === 'string' ? parseFloat(found?.price) : found?.price;

  switch (found.type) {
    case 'simple':
      return { ...product, ...found, price: parsedPrice };
    case 'combo':
      return { ...product, ...found, price: parsedPrice, options: hydrateOptions(found) };
    case 'customise':
      return { ...product, ...found, price: parsedPrice, categories: hydrateCustomisations(found) };
    default:
      return found;
  }
};

export { formattedHydrater, rawHydrater };
