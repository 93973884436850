import { reformat } from '..';
import { validate } from '@fingermarkglobal/validation';

// `category` in this instance refers to a categorized grouping of products
const reformatCategory = ({ category, update, id } = {}) => {
  validate({ name: 'Cart reformatCategory', paramaters: { category, update, id } });

  const { products, min = 1, max = 1, uid } = category;
  const formatted = products.map(product =>
    reformat({ product, category, update, categoryUid: uid }),
  );
  const total = formatted.reduce((result, item) => result + item.count.current, 0);
  const count = { min, max, current: total };
  return { ...category, productId: id, count, products: formatted };
};

export { reformatCategory };
