import { buildRequest } from '../request';

const buildRefund = ({ transactionId, timestamp, config } = {}) => {
  const category = 'Reversal';

  // https://docs.adyen.com/point-of-sale/refund-payment
  const body = {
    ReversalRequest: {
      OriginalPOITransaction: {
        POITransactionID: {
          TransactionID: transactionId,
          TimeStamp: timestamp,
        },
      },
      ReversalReason: 'MerchantCancel',
    },
  };

  return buildRequest({ body, category, config });
};

export { buildRefund };
