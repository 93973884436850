import { interpret } from '@fingermarkglobal/utilities';
import { couponMachine as machine } from './machines/coupon-machine';

const resetCouponMachine = ({ transition }: { transition?: () => void }) => {
  const { run, instance: couponStateMachine } = interpret({
    name: 'couponMachine',
    machine,
    transition,
  });

  run({ event: 'RESET' });

  return { couponStateMachine };
};

export { resetCouponMachine };
