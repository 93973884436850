import ReactGA from 'react-ga4';

const registerProductClick = ({
  sessionId,
  position,
  productVariant,
  categoryName,
  product,
  listName,
  storeAddress,
  organisation,
  serial,
  upsell = '',
} = {}) => {
  return new Promise((resolve, reject) => {
    const details = {
      eventAction: 'ProductClick',
      index: 1,
      sessionId,
      storeAddress,
      serial,
      listName,
      productVariant,
      organisation,
      categoryName,
      productName: product?.name?.text,
      productSku: product?.id,
      position,
      upsell,
    };

    try {
      logger?.info(`google-analytics - google analytics 4 - Preparing product_click event.`);
      ReactGA?.event('product_click', {
        event_category: 'product_click',
        event_label: 'product_click',
        ...details,
      });
      setTimeout(resolve, 2000);
    } catch (error) {
      logger?.warn(
        `google-analytics - google analytics 4 - Error on product_click event. Err: ${error}`,
      );
      reject(error);
    }
  });
};

export { registerProductClick };
