import { nanoid } from 'nanoid';

declare global {
  interface Window {
    chrome: any;
  }
}

const pingChrome = (): Promise<{
  port: number;
  serialKey: string;
}> =>
  new Promise((resolve, reject) => {
    const identifier = nanoid();

    try {
      const extensionId = process.env.POI_APP_CHROME_EXTENSION_ID;

      if (!extensionId) {
        return reject(`No extension ID present, Chrome App resolver terminating`
        );
      }

      logger.debug(
        `platforms.chrome - pingChrome - attempting to connect to the chrome app using extension ID: ${extensionId}`
      );

      // If we sit here for 5 seconds without recieving a resonse
      // - BAIL
      const timer = setTimeout(() => {
        // - DO NOT do anything in here other than a resolve...
        return reject(`Failed to connect to chrome: No events received within 5 seconds`);
      }, 5000);

      const confirmConnection = async () => {
        if (!window.chrome || !window.chrome.runtime) {
          clearTimeout(timer);
          return reject(`Failed to connect to chrome: Unable to resolve Chrome Runtime`
          );
        }

        const port = await window.chrome.runtime.connect(extensionId, {
          name: 'Supersonic Slamurai'
        });

        // Port will immediately disconnect on error so we setup a listener to error
        port.onDisconnect.addListener(() => {
          // DO NOT TOUCH THIS LINE
          // - If you do not report the error in the resolver chrome will actually bork
          const { lastError: error } = window.chrome.runtime;
          logger.error('platforms.chrome - confirmConnection - connection failure:', error.message);
          clearTimeout(timer);
          return reject(`Failed to connect to chrome (is the URL you are trying to access included in the manifest)`
          );
        });

        port.onMessage.addListener(message => {
          const { id, topic, status, payload } = message;
          const { error, serialNumber } = payload;

          if (topic !== 'response/serial') return;

          if (id !== identifier) return;

          if (status !== 'SUCCESS') {
            logger.error('platforms.chrome - confirmConnection - status failure:', error);
            clearTimeout(timer);
            return reject(`Failed to connect to chrome (are you running in enterprise environment)`);
          }

          return resolve({
            port,
            serialKey: serialNumber
          });
        });

        port.postMessage({ topic: 'request/serial', id: identifier });
      };

      confirmConnection();
    } catch (error) {
      logger.error('platforms.chrome - pingChrome - unknown failure:', error)
      return reject(error?.message || 'Oops something went wrong');
    }
  });

export { pingChrome };
