import { interpret } from '@fingermarkglobal/utilities';
import { validate } from '@fingermarkglobal/validation';
import { refundMachine as machine } from './machines/refund';

const refund = ({
  transactionId = null,
  timestamp = null,
  config = null,
  transition = null,
} = {}) => {
  validate({ name: 'Cash Refund', paramaters: { transactionId, timestamp, config } });

  const { instance: refundMachine } = interpret({
    name: 'refundMachine',
    machine,
    transition,
  });

  refundMachine.send('INITIALISE', { transactionId, timestamp, config });

  return { refundMachine };
};

export { refund };
