const isExactProduct = (productA = {}, productB = {}) => {
  const {
    isRootProduct: isRootA = false,
    productId: productIdA,
    customisations: customisationsA = [],
    options: optionsA = [],
    count: countA,
  } = productA;

  const {
    isRootProduct: isRootB = false,
    productId: productIdB,
    customisations: customisationsB = [],
    options: optionsB = [],
    count: countB,
  } = productB;

  if (productIdA !== productIdB) return false;

  if (!isRootA && !isRootB && countA.current !== countB.current) return false;

  const sameCustomisations = customisationsA.every((catA, catIndex) => {
    const { products = [] } = catA;
    return products
      .map((prodA, index) => isExactProduct(prodA, customisationsB[catIndex].products[index]))
      .every(item => item);
  });

  const sameOptions = optionsA.every((catA, catIndex) => {
    const { products = [] } = catA;
    return products
      .map((prodA, index) => isExactProduct(prodA, optionsB[catIndex].products[index]))
      .every(item => item);
  }); // All products should have same catIndex count and index count if theyre the same product (checked by id)

  if (!sameCustomisations) return false;
  if (!sameOptions) return false;

  return true;
};

export { isExactProduct };
