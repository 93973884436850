import { interpret } from '@fingermarkglobal/utilities';
import { refundMachine as machine } from './machines/refund';

export const refund = ({ transition = null } = {}) => {
  const { instance: refundMachine } = interpret({
    name: 'refundMachine',
    machine,
    transition,
  });

  refundMachine.send('INITIALISE');

  return { refundMachine };
};
