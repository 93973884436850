import React from 'react';
import {
  ReceiptDetails,
  CartViewProduct,
  useReceiptView,
  ReceiptPayment,
} from '@fingermarkglobal/cringer.components';

import Leaf from '../../assets/images/leaf.svg';

const CustomReceiptView = () => {
  const {
    cart,
    receiptPaymentData,
    receiptDetailsData,
    t,
    formatPrice,
    showAddonAvailability,
  } = useReceiptView();

  return (
    <div
      data-test="receipt-page"
      className="flex flex-col items-center w-full p-2 space-y-4 md:h-full md:space-y-8 md:p-0"
    >
      <div className="flex items-center justify-start w-full p-0 bg-accessory md:px-8 md:py-6 md:h-32 md:mt-6">
        <div className="text-3xl font-bold uppercase sm:text-5xl">{t('receiptTitle')}</div>
        <Leaf className="w-8 ml-4 mr-2 md:w-12 md:ml-8 sm:mr-4" />
        <div className="text-2xl font-bold sm:text-3xl text-leaf-green">
          {t('receiptEcoMessage')}
        </div>
      </div>
      <div className="flex flex-col items-center w-full p-0 space-y-4 md:h-full md:space-y-8 md:overflow-y-auto md:p-8">
        <ReceiptDetails receiptDetailsData={receiptDetailsData} t={t} boxClassName="shadow-lg" />
        {cart &&
          cart.map(product => (
            <CartViewProduct
              product={product}
              key={product.uid}
              t={t}
              boxClassName="shadow-lg"
              formatPrice={formatPrice}
              showAddonAvailability={showAddonAvailability}
            />
          ))}
      </div>
      <div className="flex flex-col items-center justify-center w-full p-0 space-y-4 md:space-y-8 sm:p-4 md:p-8">
        <ReceiptPayment receiptPaymentData={receiptPaymentData} t={t} boxClassName="shadow-lg" />
      </div>
    </div>
  );
};

export { CustomReceiptView };
