/** 
    @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
*/

import { analytics, getEventTrackEnabled, JITSU_LOG_TAG } from '../utilities';

export const setIdentify = async (session_id, { kiosk }) => {
  if (!getEventTrackEnabled()) return;

  const {
    organisation,
    serial: device_serial,
    storeId: menu_name,
    timezone,
    site,
    device_name,
    currency,
  } = kiosk;

  const store_name = site?.name || '';
  const store_id = site?.storeId || undefined;

  logger.info(`${JITSU_LOG_TAG} identifying new session: ${session_id}`);

  await analytics.identify(session_id, {
    organisation,
    store_id,
    store_name,
    device_name,
    device_serial,
    menu_name,
    timezone,
    currency,
  });
};
