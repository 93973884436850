import { destructPayment, destructStatus } from '../../destructerers';
import { executeRequest } from '../request';

const executeStatusCheck = async (context, retry = false) => {
  const { config, transactionId: kioskTransactionId } = context; // kioskTransactionId used to validate if the status check is for the same transaction we are sending in the first place
  const status = true;

  try {
    logger.warn(`adyen - executeStatusCheck - trying to get transaction status`);

    const adyenResponse = await executeRequest({ context, status });

    logger.info(
      `adyen - executeStatusCheck - status response: ${JSON.stringify(adyenResponse, null, 2)}`,
    );

    // handle destruct
    // return standard format
    const {
      result,
      errorCondition,
      response,
      transactionId,
      timestamp,
      info,
      responseTransactionId,
    } = destructStatus({
      response: adyenResponse,
      config,
      status,
    });

    if (result?.toLowerCase() !== 'success') {
      if (retry) {
        logger.warn(`adyen - executeStatusCheck - the payment has not been completed`);
        throw new Error('Failed to complete payment');
      }
      // Logging here for visibility, error can get obfuscated via machine
      logger.error('adyen - executeStatusCheck - refusal reason', errorCondition);
      throw new Error(errorCondition);
    }

    // this is a failsafe check to verify if the transactionId and amount are the same between request and response.
    // this will help ensure that we're getting the correct transaction status, avoiding receiving old transaction status and validating it as successful
    if (result?.toLowerCase() === 'success') {
      if (responseTransactionId !== kioskTransactionId) {
        logger.warn(
          `adyen - executeStatusCheck - transaction IDs are different between request and response.`,
        );
        throw new Error('Transaction IDs are different between request and response');
      }
    }

    return {
      result,
      message: errorCondition,
      errorCondition,
      response,
      transactionId,
      timestamp,
      info,
    };
  } catch (error) {
    throw new Error('Failed to send request');
  }
};

const executeStatusFailureCheck = async context => {
  logger.info(`adyen - executeStatusFailureCheck - checking status request failure...`);

  if (context.message === 'Failed to send request') {
    logger.error(`adyen - executeStatusFailureCheck - failed to get transaction status`);
    throw new Error('Failed to get transaction status');
  }

  logger.info(`adyen - executeStatusFailureCheck - transaction status captured, resuming workflow`);

  return { ...context, message: '' };
};

const executePayment = async context => {
  // take built payload
  const { config } = context;
  const { retry } = config;

  let adyenResponse;
  const status = false;

  // make request
  logger.info(`adyen - executePayment - payment request: ${JSON.stringify(context, null, 2)}`);

  if (retry) {
    const statusCheckResponse = await executeStatusCheck(context, retry);

    if (statusCheckResponse) return statusCheckResponse;
  }

  try {
    adyenResponse = await executeRequest({ context });

    logger.info(
      `adyen - executePayment - payment response: ${JSON.stringify(adyenResponse, null, 2)}`,
    );

    // handle destruct
    // return standard format
    const { result, errorCondition, response, transactionId, timestamp, info } = destructPayment({
      response: adyenResponse,
      config,
      status,
    });

    if (result?.toLowerCase() !== 'success') {
      throw new Error('Payment failed.');
    }

    return {
      result,
      message: errorCondition,
      errorCondition,
      response,
      transactionId,
      timestamp,
      info,
    };
  } catch (error) {
    logger.warn(
      `adyen - executePayment - payment request failed. trying to get transaction status`,
    );

    return executeStatusCheck({ context, retry: false });
  }
};

export { executePayment, executeStatusCheck, executeStatusFailureCheck };
