import { executeRequest } from './request';
import { createTransactionResultRequest, createFlushRequest } from './builders';
import {
  checkTransactionResponse,
  startPinger,
  stopPinger,
  handleResponseStatus,
  handleResponseCode,
  sendFlush,
} from './utils';

const executeTransaction = async context => {
  const { config, request } = context || {};
  const { transactionRequest } = request || {};

  const { settings } = config || {};
  const { terminalId, authCode, timeout } = settings || {};

  logger.info(
    `cbq-ecr - executeTransaction - transaction queueing request: ${JSON.stringify(
      context,
      null,
      2,
    )}`,
  );

  const timeoutId = setTimeout(() => {
    throw new Error('Operation timed out');
  }, timeout * 1000);

  const transactionResponse = await executeRequest({
    payload: JSON.stringify(transactionRequest.request),
    endpoint: transactionRequest.requestEndpoint,
    config,
  });

  logger.info(
    `cbq-ecr - executeTransaction - transaction queueing response: ${JSON.stringify(
      transactionResponse,
    )}`,
  );

  const commandId = checkTransactionResponse(transactionResponse);

  const transactionResultRequest = createTransactionResultRequest({
    commandId,
    authCode,
    terminalId,
  });

  let pingerId;
  let initializeCount = 0;

  return new Promise((resolve, reject) => {
    const responseHandler = response => {
      logger.info(
        `cbq-ecr - executeTransaction - transaction status response: ${JSON.stringify(
          response,
          null,
          2,
        )}`,
      );

      const { result = {} } = response || {};
      const { responseData = '', status = '' } = result || {};

      if (status === 'INITIALIZED') {
        if (initializeCount > 5) {
          const flushRequest = createFlushRequest({
            authCode,
            terminalId,
          });

          stopPinger(pingerId);
          sendFlush({
            payload: flushRequest.request,
            config,
            endpoint: flushRequest.requestEndpoint,
          });

          reject(new Error('Transaction could not be sent'));
        }

        initializeCount += 1;
      }

      const parsedResponseData = JSON.parse(JSON.parse(responseData)); // needed to be done twice due to how this payload comes in the response
      const { TransactionStatus = '' } = parsedResponseData || {};

      if (!response) reject(new Error('Response not received'));
      if (TransactionStatus === 'RESULT_FAILED') reject(new Error('Transaction result failed'));
      if (TransactionStatus === 'RESULT_OK') {
        handleResponseStatus(parsedResponseData, reject);
        handleResponseCode(parsedResponseData, reject);

        resolve(parsedResponseData);
      }
    };

    pingerId = startPinger(
      {
        payload: transactionResultRequest.request,
        config,
        endpoint: transactionResultRequest.requestEndpoint,
      },
      responseHandler,
    );
  })
    .catch(error => {
      throw new Error(error.message);
    })
    .finally(() => {
      stopPinger(pingerId);
      clearTimeout(timeoutId);
    });
};

export { executeTransaction };
