import { is, string } from 'superstruct';

const Serial = string();

const validate = (value = null) => {
  if (!value) return null;

  const valid = is(value, Serial);

  if (!valid) throw new Error('Serial value does not match Serial schema');
  else return value;
};

export { Serial, validate };
