import { executeRequest } from '../request';

const startPinger = ({ payload, config, endpoint }, responseHandler) => {
  return setInterval(async () => {
    const response = await executeRequest({ payload, config, endpoint, method: 'GET' });

    responseHandler(response);
  }, 3000);
};

const stopPinger = pingerId => {
  clearInterval(pingerId);
};

const wait = async miliseconds => {
  return new Promise(resolve => setTimeout(resolve, miliseconds));
};

export { startPinger, stopPinger, wait };
