import { buildRequest } from '../request';

const buildStatus = ({ config } = {}) => {
  const category = 'TransactionStatus';

  // https://docs.adyen.com/point-of-sale/verify-transaction-status
  const body = {
    TransactionStatusRequest: {
      ReceiptReprintFlag: true,
      DocumentQualifier: ['CashierReceipt', 'CustomerReceipt'],
    },
  };

  return {
    payload: buildRequest({ body, category, config }),
  };
};

export { buildStatus };
