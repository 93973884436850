import { HTTPError, TimeoutError } from '@fingermarkglobal/request';
import ky from 'ky';

const loyaltyOffersAndRewards = async ({ token, storeId }: { token: string; storeId: string }) => {
  console.info('fm-api - loyaltyOffersAndRewards - started');
  try {
    if (!process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT) {
      throw new Error(
        'fm-api - loyaltyOffersAndRewards - failed to get loyalty endpoint in the environment file with the key POI_APP_LOYALTY_SERVICE_ENDPOINT.',
      );
    }

    const endpoint = `${process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT}/loyalty/integration/promos`;
    const response = await ky.post(endpoint, {
      timeout: 30 * 1000,
      headers: { 'x-authorization-loyalty': `Bearer ${token}` },
      json: { storeId },
    });

    if (!response.ok) {
      throw new Error('fm-api - loyaltyOffersAndRewards - request failed. No response received');
    }

    console.info('fm-api - loyaltyOffersAndRewards - finished', response);

    return response.json();
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;

    console.error('fm-api - loyaltyOffersAndRewards - error:', err);
    throw err;
  }
};

export { loyaltyOffersAndRewards };
