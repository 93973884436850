import { request, getJwtToken } from '@fingermarkglobal/request';
import { check } from '@fingermarkglobal/validation';
import { PreOrderPayload } from '@fingermarkglobal/types';
import { buildOrderPreEndpoint, buildRequestOptions } from '../../shared';

const preOrder = async (
  payload: PreOrderPayload,
  timeout: number = 30,
  ip: string = '',
  provider: string = '',
) => {

  try {
    logger.info('[fm-api][order/pre] - started');

    const invalid = check({
      name: 'Pre-order',
      parameters: { payload },
    });
    if (invalid) throw new Error(`[fm-api][order/pre] - failed to validate parameters [${invalid}]`);

    const { customPreOrderEndpoint: customEndpoint } = payload?.settings?.labs || {};
    const endpoint = buildOrderPreEndpoint({customEndpoint, ip, provider});

    const token = await getJwtToken();
    if (!token) throw new Error(`[fm-api][order/pre] error generating jwt token`);

    const options = buildRequestOptions({
      endpoint,
      payload,
      token,
      timeout,
    });

    const response = await request.post(endpoint, options).json();

    if (!response) throw new Error(`[fm-api][order/pre] - failed with response {${response}}`);

    return response;
  } catch (err) {
    logger.error('[fm-api][order/pre] error:', err);
    throw err;
  } finally {
    logger.info('[fm-api][order/pre] - finished');
  }
};

export { preOrder };
