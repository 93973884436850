import { createMachine } from 'xstate';
import { MenuMachineContext } from '@fingermarkglobal/types';

import {
  setMenuAction,
  setSettingsAction,
  setPopulatingErrorMessageAction
} from './actions';

import {
  getProductsRequest,
  getCategoriesRequest,
  getPromotionalRequest
} from '@fingermarkglobal/restaurant';

const menuMachine = createMachine<MenuMachineContext>(
  {
    id: 'menu-machine',
    initial: 'idle',
    context: {
      count: null,
      message: null,
      settings: null,
      products: null,
      promotions: null,
      categories: null
    },
    states: {
      idle: {
        on: {
          SKIP: {
            target: 'success'
          },
          INITIALISE: {
            target: 'populating',
            actions: ['setSettingsAction']
          }
        }
      },
      populating: {
        invoke: {
          src: context => Promise.all([
              getProductsRequest(context?.count, context?.settings),
              getCategoriesRequest(context?.settings),
              getPromotionalRequest(context?.count, context?.settings),
            ]),
          onDone: {
            target: 'waiting',
            actions: ['setMenuAction']
          },
          onError: {
            target: 'failure',
            actions: ['setPopulatingErrorMessageAction']
          }
        }
      },
      waiting: {
        on: {
          EXTERNAL_TRANSITION_FAILURE: 'failure',
          EXTERNAL_TRANSITION_SUCCESS: 'success'
        }
      },
      failure: {
        on: {
          EXTERNAL_TRANSITION_RETRY: 'populating'
        }
      },
      success: {
        type: 'final'
      }
    }
  },
  {
    actions: {
      setMenuAction,
      setSettingsAction,
      setPopulatingErrorMessageAction
    }
  }
);

export { menuMachine };
