import { messageType } from './tags';
import { auth, voidRes, settlement } from './responses';

const Enums = {
  messageType,
  responses: {
    auth,
    voidRes,
    settlement,
  },
};

const Characters = {
  STX: Buffer.from('\x02'),
  ETX: Buffer.from('\x03'),
  ACK: Buffer.from('\x06'),
};

export { Enums, Characters };
