const formatCount = ({ product, isRootProduct } = {}) => {
  const { defaultQuantity = 0, min = 0, max = -1, default: isDefault } = product || {};

  return {
    min,
    max,
    current: formatCurrentQuantity({ max, min, isRootProduct, defaultQuantity, isDefault }),
    initial: defaultQuantity,
  };
};

const formatCurrentQuantity = ({ max, min, isRootProduct, defaultQuantity, isDefault } = {}) => {
  // Root products should always start with 1.
  if (isRootProduct) return 1;

  // Products with default quantity greater than 0 should always start with that amount.
  if (defaultQuantity) return defaultQuantity;

  // Default products which don't have a default quantity should start with 1.
  // This is for the "change selection" feature from combos because the products on that list
  // don't have a default quantity but one of them is the default and it is the only one that should start with 1.
  if (isDefault) return 1;

  // This is for categories, both combo and customisations categories, if min and max are the same
  // then just return max value as the current.
  if (min === max) return max;

  return 0;
};

export { formatCount };
