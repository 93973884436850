import { HTTPError, TimeoutError } from '@fingermarkglobal/request';
import ky from 'ky';

const loyaltyGuestClaim = async ({
  storeId,
  kioskSerialKey,
  organisationId,
  sessionId,
  cart,
}: {
  storeId: string;
  kioskSerialKey: string;
  organisationId: string;
  sessionId: string;
  cart: any;
}) => {
  console.info('fm-api - loyaltyGuestClaim - started');
  try {
    if (!process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT) {
      throw new Error(
        'fm-api - loyaltyGuestClaim - failed to get loyalty logout endpoint in the environment file with the key POI_APP_LOYALTY_SERVICE_ENDPOINT.',
      );
    }

    const endpoint = `${process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT}/loyalty/transaction/claim`;
    const response = await ky.post(endpoint, {
      timeout: 30 * 1000,
      json: { storeId, kioskSerialKey, organisationId, sessionId, cart },
    });

    if (!response.ok) {
      throw new Error('fm-api - loyaltyGuestClaim - request failed. No response received');
    }

    console.info('fm-api - loyaltyGuestClaim - finished', response);

    return response;
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;

    console.error('fm-api - loyaltyGuestClaim - error:', err);
    throw err;
  }
};

export { loyaltyGuestClaim };
