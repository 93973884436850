const createTransactionResultRequest = ({ commandId, terminalId, authCode }) => {
  const requestEndpoint = `https://supersonic-zodac-bqqvoxpupq-ew.a.run.app/https://cloud.amp360.amobilepayment.com/api/command/status/${commandId}/${terminalId}/${authCode}`;
  const request = {};

  return {
    requestEndpoint,
    request,
  };
};

export { createTransactionResultRequest };
