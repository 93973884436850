const handleResponse = response => {
  const { topic = null, payload = null, level = 'debug' } = response || {};

  if (topic === 'request/log') {
    logger[level]('protocols - ', JSON.stringify(payload.log));
    return {};
  }

  return response;
};

export { handleResponse };
