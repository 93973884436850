import { assign } from 'xstate';
import {
  MenuActionEvent,
  MenuMachineContext,
  SettingsActionEvent
} from '@fingermarkglobal/types';

const setSettingsAction = assign((_, event: SettingsActionEvent) => ({
  count: event.count,
  settings: event.settings
}));

const setMenuAction = assign((_, event: MenuActionEvent) => {
  const [products, categories, promotions] = event.data;
  return {
    products,
    categories,
    promotions
  };
});

const setPopulatingErrorMessageAction = assign((): Partial<MenuMachineContext> => ({
    message: `There was an error populating the device menu.`
  })
);

export { setMenuAction, setSettingsAction, setPopulatingErrorMessageAction };
