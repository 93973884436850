type EndpointBuilderArgs = {
    customEndpoint?: string;
    provider?: string;
    ip?: string;
};

type InternalEndpointBuilderArgs = {
    path: EndpointPaths;
    requireProvider?: boolean;
} & EndpointBuilderArgs;

const isValidUrl = (url: string) => {
    try { 
        return Boolean(new URL(url)); 
    }
    catch(e) { 
        return false; 
    }
}

export enum EndpointPaths {
    ORDER_COUPON = 'order/coupon',
    ORDER_CANCEL = 'order/cancel',
    ORDER_PRE = 'order/pre',
    ORDER_PLACE = 'order/place',
    ORDER_RESET = 'order/reset',
    ORDER_SAVE = 'order/save',
    ORDER_POLLING = 'order/polling',
    SHIFT_START = 'shift/start',
    SHIFT_END = 'shift/end',
    SHIFT_CHECK = 'shift/check',
}

export const getHawkeLocalEndpoint = (ip: string) => {
    const endpoint: string = process.env.POI_APP_HAWKE_LOCAL_ENDPOINT?.trim();
    const trimIp = ip?.trim();
    if (endpoint) {
        if (endpoint.includes('{ip}')) {
            return trimIp ? endpoint.replace('{ip}', trimIp) : undefined;
        }
        return endpoint;
    }
    return undefined;
}

export const getHawkeBridgeServerEndpoint = (ip: string) => {
    const endpoint: string = process.env.POI_APP_BRIDGE_SERVER_ENDPOINT?.trim();
    const trimIp = ip?.trim();
    if (endpoint) {
        if (endpoint.includes('{ip}')) {
            return trimIp ? endpoint.replace('{ip}', trimIp) : undefined;
        }
        return endpoint;
    }
    return undefined;
}

export const getHawkeEndpoint = () => {
    const endpoint = process.env.POI_APP_HAWKE_ENDPOINT?.trim();
    if (endpoint) {
        if (isValidUrl(endpoint)) {
            return endpoint;
        }
        throw new Error('[fm-api] POI_APP_HAWKE_ENDPOINT is an invalid endpoint');
    }
    throw new Error('[fm-api] POI_APP_HAWKE_ENDPOINT is empty');
}

export const buildEndpoint = ({customEndpoint, ip, path, provider, requireProvider = true}: InternalEndpointBuilderArgs) => {
    //Always default first customEndpoint if we receive one
    const endpoint = customEndpoint?.trim();
    if (endpoint) {
        return endpoint;
    }

    const trimProvider = provider?.trim()?.toLocaleLowerCase();
    if (requireProvider && !trimProvider) {
        throw new Error(`[fm-api][${path}] provider is required to call /${path}/{provider} API`);
    }

    //Then check if we are being instructed to use local url
    const localEndpoint = getHawkeLocalEndpoint(ip);
    if (localEndpoint && provider !== 'local') {
        return `${localEndpoint}/${path}${requireProvider ? `/${trimProvider}` : ''}`; 
    }

    const bridgeServerEndpoint = getHawkeBridgeServerEndpoint(ip);
    if (bridgeServerEndpoint && provider === 'local') {
        return `${bridgeServerEndpoint}/${path}`; //Local provider won't have it attached as suffix for the endpoint
    }

    return `${getHawkeEndpoint()}/${path}${requireProvider ? `/${trimProvider}` : ''}`;
}

export const buildOrderCouponsEndpoint = (args: EndpointBuilderArgs) => 
    buildEndpoint({ ...args, path: EndpointPaths.ORDER_COUPON });

export const buildStartShiftEndpoint = (args: EndpointBuilderArgs) =>
    buildEndpoint({ ...args, path: EndpointPaths.SHIFT_START });

export const buildEndShiftEndpoint = (args: EndpointBuilderArgs) =>
    buildEndpoint({ ...args, path: EndpointPaths.SHIFT_END });

export const buildCheckShiftEndpoint = (args: EndpointBuilderArgs) =>
    buildEndpoint({ ...args, path: EndpointPaths.SHIFT_CHECK });

export const buildOrderCancelEndpoint = (args: EndpointBuilderArgs) =>
    buildEndpoint({ ...args, path: EndpointPaths.ORDER_CANCEL });

export const buildOrderPlaceEndpoint = (args: EndpointBuilderArgs) => 
    buildEndpoint({ ...args, path: EndpointPaths.ORDER_PLACE });

export const buildOrderPreEndpoint = (args: EndpointBuilderArgs) => 
    buildEndpoint({ ...args, path: EndpointPaths.ORDER_PRE });

export const buildOrderResetEndpoint = (args: EndpointBuilderArgs) =>
    buildEndpoint({ ...args, path: EndpointPaths.ORDER_RESET });

export const buildOrderSaveEndpoint = (args: EndpointBuilderArgs) =>
    buildEndpoint({ ...args, path: EndpointPaths.ORDER_SAVE, requireProvider: false });

export const buildOrderPollingEndpoint = (args: EndpointBuilderArgs) =>
    buildEndpoint({ ...args, path: EndpointPaths.ORDER_POLLING });