const Tags = {
  SEPARATOR: 'df',
  TRANSACTION: 'ff',
  AMOUNT: 'df0406',
  ECR_PRINTING_AREA: 'df610a',
  ECR_REFERENCE_NUMBER: 'df8110',
  ORIGINAL_RRN: 'df250c',
  ORIGINAL_DATE_TIME: 'df2408',
  CARD_PAN: 'df0210',
  APP_ID: '11',
};

export { Tags };
