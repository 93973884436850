import { Enums } from '../enums';

export const buildAuthRequest = payload => {
  const tag = Enums.messageType.auth;
  const { ecrNumber, amount, tid } = payload || {};

  const buf = Buffer.from(`${tag},${ecrNumber},${amount * 100},${tid}`);
  const hex = buf.toString('hex');

  return hex;
};
