import { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetTrack } from '../track';

export const useRouteTracker = () => {
  const history = useHistory();
  const [startTime, setStartTime] = useState(Date.now());
  const [previousRoute, setPreviousRoute] = useState('');
  const { setPage } = useSetTrack();
  const isMounted = useRef(true);

  const sendPageTrackEvent = useCallback(() => {
    const endTime = Date.now();
    const seconds = (endTime - startTime) / 1000;

    if (previousRoute) {
      setPage({ route: previousRoute, startTime, endTime, seconds });
    }

    if (isMounted.current) {
      setStartTime(endTime);
    }
  }, [startTime, previousRoute, setPage]);

  useEffect(() => {
    const unlisten = history.listen(location => {
      sendPageTrackEvent();

      if (isMounted.current) {
        setPreviousRoute(location.pathname);
      }
    });

    return () => {
      isMounted.current = false;
      unlisten();
    };
  }, [history, sendPageTrackEvent]);
};

export default useRouteTracker;
