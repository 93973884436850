import { receiver } from '@fingermarkglobal/chrome-messages';

class USBServer {
  listen() {
    receiver({ handler: this.receive });
  }

  static connect({ payload } = {}) {
    const { vid = null, pid = null } = payload;
    // TODO: handle usb connection
    return { vid, pid };
  }

  static write({ payload } = {}) {
    const { buffer = null } = payload;
    // TODO: handle usb write
    return { buffer };
  }

  static read() {
    // TODO: handle usb read
  }

  static disconnect() {
    // TODO: handle usb disconnection
  }

  receive({ request = null } = {}) {
    const { method, payload } = request;

    switch (method) {
      case 'connect':
        return this.connect({ payload });
      case 'write':
        return this.write({ payload });
      case 'read':
        return this.read();
      case 'disconnect':
        return this.disconnect();
      default:
        throw new Error('Unhandled USB method');
    }
  }
}

export { USBServer };
