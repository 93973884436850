import { HTTPError, TimeoutError } from '@fingermarkglobal/request';
import ky from 'ky';

const loyaltyClaim = async ({
  token,
  transactionId,
  cart,
}: {
  token: string;
  transactionId: string;
  cart: any;
}) => {
  console.info('fm-api - loyaltyClaim - started');
  try {
    if (!process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT) {
      throw new Error(
        'fm-api - loyaltyClaim - failed to get loyalty logout endpoint in the environment file with the key POI_APP_LOYALTY_SERVICE_ENDPOINT.',
      );
    }

    const endpoint = `${process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT}/loyalty/transaction/${transactionId}/claim`;
    const response = await ky.post(endpoint, {
      timeout: 30 * 1000,
      headers: { 'x-authorization-loyalty': `Bearer ${token}` },
      json: { cart },
    });

    if (!response.ok) {
      throw new Error('fm-api - loyaltyClaim - request failed. No response received');
    }

    console.info('fm-api - loyaltyClaim - finished', response);

    return response;
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;

    console.error('fm-api - loyaltyClaim - error:', err);
    throw err;
  }
};

export { loyaltyClaim };
