import { createMachine, assign } from 'xstate';
import { loyaltyLogout } from '../requests/loyalty-logout';

const populateMessage = assign({
  data: (_, payload: any) => {
    const message = payload?.message;
    const error = message?.message;
    const data = payload?.data;

    if (data) {
      logger.debug(`api - loyaltyLogout - FSM data recieved:`, data);
      return data;
    }

    if (error) {
      logger.error(`api - loyaltyLogout - FSM error recieved:`, error);
      return error;
    }

    logger.error(`api - loyaltyLogout - FSM message recieved:`, message);
    return message;
  },
});

const createLoyaltyLogOutMachine = ({ token = '' } = {}) =>
  createMachine<{
    token: string;
  }>(
    {
      id: 'loyaltyLogout',
      initial: 'processing',
      context: {
        token,
      },
      states: {
        processing: {
          invoke: {
            src: ({ token }) => loyaltyLogout({ token }),
            onDone: {
              target: 'success',
              actions: ['populateMessage'],
            },
            onError: {
              target: 'error',
              actions: ['populateMessage'],
            },
          },
        },
        success: {
          on: {
            IDLE: 'processing',
          },
        },
        error: { type: 'final' },
      },
    },
    {
      actions: {
        populateMessage,
      },
    },
  );

export { createLoyaltyLogOutMachine };
