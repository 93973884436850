const responseCodes = {
  '00': 'Transaction successful',
  55: 'Invalid Pin',
};

const handleResponseCode = (data, reject) => {
  const { Payload = null } = data || {};

  if (!Payload) reject(new Error('Malformed Response'));

  const { response_code_key: key = '' } = Payload || {};

  if (!key || key === null) reject(new Error('No response code found'));

  if (key !== '00') reject(new Error(`Response error [${responseCodes[key.substr(-2)]}]`));
};

export { handleResponseCode };
