import { createMachine, assign } from 'xstate';
import { checkRequestDetails } from '@fingermarkglobal/types';
import { checkShift } from '../requests/check-shift';

const populateMessage = assign({
  data: (_, payload: any) => {
    const message = payload?.message;
    const error = message?.message;
    const data = payload?.data;

    if (data) {
      logger.debug(`[fm-api][shift/check] - data received:`, data);
      return data;
    }

    if (error) {
      logger.error(`[fm-api][shift/check] - error received:`, error);
      return error;
    }

    logger.error(`[fm-api][shift/check] - message received:`, message);
    return message;
  },
});

const createFiscalManagementMachine = ({
  payload = null,
  timeout = 30,
  ip = '',
  provider = '',
} = {}) =>
  createMachine<{
    data: unknown;
    timeout: number;
    payload: null | checkRequestDetails;
    provider: string;
    retryAttempts: number;
  }>(
    {
      id: 'fiscal',
      initial: 'processing',
      context: {
        payload,
        timeout,
        data: null,
        provider,
        retryAttempts: 0,
      },
      states: {
        processing: {
          invoke: {
            src: ({ payload, timeout }) => checkShift(payload, timeout, ip, provider),
            onDone: {
              target: 'success',
              actions: ['populateMessage'],
            },
            onError: {
              target: 'retrying',
              actions: ['populateMessage'],
            },
          },
        },
        retrying: {
          after: {
            10000: [
              {
                target: 'processing',
                cond: context => context.retryAttempts < 3,
                actions: [assign({ retryAttempts: context => context.retryAttempts + 1 }), 'RETRY'],
              },
              { target: 'error', cond: context => context.retryAttempts >= 3 },
            ],
          },
        },
        success: {
          on: {
            IDLE: 'processing',
          },
        },
        error: {
          type: 'final',
          entry: ['populateMessage'],
        },
      },
    },
    {
      actions: {
        populateMessage,
      },
    },
  );

export { createFiscalManagementMachine };
