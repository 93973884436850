import { CognizantOrderProviderSettings } from '@fingermarkglobal/types';

const checkWebhookUse = async (
  provider: CognizantOrderProviderSettings,
) => {
  try {
    const { executeWebhookPolling = false, maxRetries = 'N/A' } = provider;
    if (executeWebhookPolling === false || !executeWebhookPolling) {
      return false;
    }
    logger.debug(`[fm-api][order/polling] - Get KAS information about Webhook - Execute WebhookPolling: ${executeWebhookPolling} | Max Retries:: ${maxRetries}.`);
    return true;
  } catch (err) {
    logger.error('[fm-api][order/polling] - error:', err);
    throw err;
  }
};

export { checkWebhookUse };
