const statuses = {
  idleScreen: { id: '39' },
};

const statusMap = {
  75: 'Terminal TMS not loaded',
  '4f': 'Paper Out',
  41: 'Swipe or Insert',
  47: 'User Cancelled and Timeout',
  58: 'Card Inserted',
  70: 'Card Read Error',
  53: 'Card Removed',
  66: 'Data Error',
  65: 'Blocked Card',
  42: 'No Active Application Found',
  '6d': 'Insert Card Only Use Smart Card Reader',
  77: 'Maximum Amount Limit Exceeded',
  43: 'Pin Entry',
  45: 'Pin Quit',
  49: 'Network Down Connect Cable',
  '4a': 'Insert SIM',
  46: 'Connecting',
  '4b': 'Re-Connect',
  71: 'Network is Down',
  '4d': 'Connection Made',
  '7a': 'Sending Request',
  52: 'Transaction Cancelled',
  79: 'Waiting Response',
  44: 'Wrong Pin',
  57: 'Retry Limit Exceeded',
  51: 'Connection Closed',
  50: 'Remove Card',
  38: 'SAF Processing',
  56: 'Maximum SAF Reached',
  '4c': 'Mag Stripe Not Supported',
  55: 'Card Scheme Not Supported',
  61: 'DE-SAF Failed',
  62: 'Reconciliation No Business',
  63: 'Reconciliation Failed',
  64: 'Reconciliation Successful',
  48: 'Transaction Permission Not Allowed',
  81: 'No Txn to Reverse',
  83: 'Reversal Trans',
  39: 'Idle Screen',
};

const getStatusById = id => {
  const status = statusMap[id];

  if (!status) {
    return 'Unknown error';
  }

  return status;
};

export { getStatusById, statusMap, statuses };
