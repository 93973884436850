import { SaveOrderPayload } from '@fingermarkglobal/types';
import { saveOrder } from '../requests/save-order';
import { createMachine, assign } from 'xstate';

const populateMessage = assign({
  data: (_, payload: any) => {
    const message = payload?.message;
    const error = message?.message;
    const data = payload?.data;

    if (data) {
      logger.debug(`[fm-api][order/save] - data received:`, data);
      return data
    }

    if (error) {
      logger.error(`[fm-api][order/save] - error received:`, error);
      return error
    }

    logger.error(`[fm-api][order/save] - message received:`, message);
    return message;
  },
});

const createSaveOrderMachine = ({ payload = null, timeout = 30, ip = '' } = {}) =>
  createMachine<{ payload: SaveOrderPayload; timeout: number; ip?: string; data: unknown; }>(
    {
      id: 'saveOrderMachine',
      initial: 'processing',
      context: {
        payload,
        timeout,
        data: null
      },
      states: {
        processing: {
          invoke: {
            src: ({ payload, timeout }) => saveOrder(payload, timeout, ip),
            onDone: {
              target: 'success',
              actions: ['populateMessage']
            },
            onError: {
              target: 'error',
              actions: ['populateMessage']
            }
          }
        },
        success: {
          on: {
            RETRY: 'processing'
          }
        },
        error: {
          type: 'final',
          entry: ['populateMessage']
        }
      }
    },
    {
      actions: {
        populateMessage
      }
    }
  );

export { createSaveOrderMachine };
