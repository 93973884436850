import React from 'react';
import { Decoration } from '../decoration';
import Logo from '../../assets/images/logo.svg';
import { Loading as GenericLoading } from '@fingermarkglobal/cringer.components';

const Loading = ({
  'data-test': dataTest = 'loading',
  title = null,
  message = null,
  Image = null,
  isFirstLoad = false,
}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <Decoration className="absolute top-0" />
      <Logo data-test="logo" className="w-64 h-64" />
      <GenericLoading
        data-test={dataTest}
        title={title}
        message={message}
        Image={Image}
        isFirstLoad={isFirstLoad}
      />
    </div>
  );
};

export { Loading };
