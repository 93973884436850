const lrcBuilder = hexstring => {
  const s = hexstring.match(/../g);

  const powLRC = s.reduce((reducer, hexbyte) => {
    const n = 1 * `0x${hexbyte}`;
    const item = reducer;
    const result = item ^ n;
    return result;
  }, 0);

  const lrc = powLRC.toString(16);
  if (lrc.length % 2) return `0${lrc}`;

  return lrc;
};

/**
 * Procedure to check LRC for a given payload.
 * @param {Buffer} payload
 * @throws {Error} in case LRC check fails
 */
const validateLrc = payload => {
  // Validates payload LRC matches our calculation of the LRC.
  const receivedLRC = payload.slice(-1).toString('hex');
  const bytesForLRCcalc = payload.slice(1, -1).toString('hex');
  const calculatedLRC = lrcBuilder(bytesForLRCcalc);

  if (receivedLRC !== calculatedLRC)
    throw new Error('LRC calculation did not match. Invalid payload.');
};

export { lrcBuilder, validateLrc };
