const formatStoreId = (storeId = '', maxLength = 5) => {
  if (!storeId) {
    return '';
  }

  const inputString = String(storeId);

  if (inputString.length >= maxLength) {
    return inputString;
  }

  const numberOfZerosToAdd = maxLength - inputString.length;
  const paddedString = '0'.repeat(numberOfZerosToAdd) + inputString;

  return paddedString;
}

export default formatStoreId;