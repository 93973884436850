import { format } from '..';
import { validate } from '@fingermarkglobal/validation';
import { nanoid } from 'nanoid';

// `category` in this instance refers to a categorized grouping of products
const formatCategory = ({ category, update, id } = {}) => {
  validate({ name: 'Cart formatCategory', paramater: { category, update, id } });

  const categoryUid = nanoid();
  const {
    id: categoryId,
    products,
    options,
    min = 1,
    max = 1,
    hasScalableProduct = false,
  } = category;

  const items = products || options || [];
  const formatted = items.map(product => format({ product, category, update, categoryUid }));
  const total = formatted.reduce((result, item) => result + item.count.current, 0);
  const count = { min, max, current: total };

  const selected = items.some(product => product.selected);

  return {
    ...category,
    categoryId,
    productId: id,
    count,
    products: formatted,
    selected,
    uid: categoryUid,
    hasScalableProduct,
  };
};

/**
 * Recursively reset price and count values from the current category tree, which means all items under the category.
 * A category here means an option or a customisation.
 * This function should be invoked as a "pre update" to clear previous selection/customisations.
 * The strategy for using uid instead of the original category id is necessary becasue on a combo we might have
 * multiple categories with the same id, but the uid is unique for every register.
 * @param {Object} params
 * @param {any} params.product Formatted product
 * @param {string} params.currentCategoryUid Category uid to reset products.
 * @param {string} params.parentCategoryUid The first parent in the tree (option uid).
 * @param {string} params.previousCategoryUid The parent right above.
 * @param {string} params.originUid The product that triggered the action. This product shouldn't be cleared at this stage.
 * @param {boolean} params.resetDefaultToZero Reset quantity of default products to 0 rather than the initial value
 */
const resetCategoryProductTree = ({
  isCustomisation = false,
  product,
  currentCategoryUid,
  parentCategoryUid = '',
  previousCategoryUid = '',
  originUid = '',
  resetDefaultToZero = false,
} = {}) => {
  validate({ name: 'Cart clearProductTree', paramater: { product, currentCategoryUid } });

  const { customisations = [], options = [], count, price, default: isDefault = false } = product;

  const formattedCustomisations = customisations.map(customisation => {
    const { products = [], uid } = customisation;
    return {
      ...customisation,
      products: products.map(product =>
        resetCategoryProductTree({
          isCustomisation: true,
          product,
          currentCategoryUid,
          parentCategoryUid,
          previousCategoryUid: uid,
          originUid,
          resetDefaultToZero,
        }),
      ),
    };
  });

  const formattedOptions = options.map(option => {
    const { products = [], uid } = option;
    return {
      ...option,
      products: products.map(product =>
        resetCategoryProductTree({
          product,
          currentCategoryUid,
          parentCategoryUid: uid,
          previousCategoryUid: uid,
          originUid,
          resetDefaultToZero,
        }),
      ),
    };
  });

  const isNodeChild =
    parentCategoryUid === currentCategoryUid || previousCategoryUid === currentCategoryUid;
  const isNodeOrigin = product.uid === originUid;

  const newQuantity = !resetDefaultToZero ? count.initial : 0;

  if (isNodeChild && !isNodeOrigin)
    return {
      ...product,
      customisations: formattedCustomisations,
      options: formattedOptions,
      count: {
        ...count,
        current: newQuantity,
      },
      price: {
        ...price,
        current: 0,
      },
    };

  return {
    ...product,
    customisations: formattedCustomisations,
    options: formattedOptions,
  };
};

export { formatCategory, resetCategoryProductTree };
