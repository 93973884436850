import ReactGA from 'react-ga4';

const registerProductAddedToCart = (
  { setUpsellState },
  {
    sessionId,
    productVariant,
    categoryName,
    product,
    price,
    quantity,
    listName,
    storeAddress,
    organisation,
    serial,
    upsell,
  } = {},
) => {
  return new Promise((resolve, reject) => {
    const details = {
      eventAction: 'ProductAddedToCard',
      index: 1,
      sessionId,
      storeAddress,
      serial,
      listName,
      productVariant,
      organisation,
      categoryName,
      productName: product?.name?.text,
      productSku: product?.id,
      upsell,
      price,
      quantity,
    };

    const isUpsell = upsell === 'upsell';
    if (isUpsell) setUpsellState(upsells => [...upsells, product.id]);

    try {
      logger?.info(`google-analytics - google analytics 4 - Preparing add_to_cart event.`);
      ReactGA?.event('add_to_cart', {
        event_category: 'add_to_cart',
        event_label: 'add_to_cart',
        ...details,
      });
      setTimeout(resolve, 2000);
    } catch (error) {
      logger?.warn(
        `google-analytics - google analytics 4 - Error on add_to_cart event. Err: ${error}`,
      );
      reject(error);
    }
  });
};

export { registerProductAddedToCart };
