import React from 'react';
import LogoImage from '../../../assets/images/logo.svg';

const Decoration = ({ className = '', showLogo = false } = {}) => {
  return (
    <div
      data-test="decoration"
      className={`flex flex-col items-center space-x-8 sm:space-x-12 md:space-y-16 pointer-events-none ${className}`}
    >
      <div className="flex items-center justify-center w-full">
        <div className="inline-block w-12 h-4 xs:w-16 xs:h-6 md:w-24 md:h-12 bg-primary"></div>
        <div className="inline-block w-12 h-4 mx-8 xs:w-16 xs:h-6 md:w-24 md:h-12 xs:mx-12 md:mx-24 bg-primary"></div>
        <div className="inline-block w-12 h-4 xs:w-16 xs:h-6 md:w-24 md:h-12 bg-primary"></div>
      </div>
      {showLogo && <LogoImage data-test="logo" className="w-40 h-auto " />}
    </div>
  );
};

export { Decoration };
