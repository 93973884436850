import { parseAuth } from './auth';
import { parseVoid } from './void';
import { parseSettlement } from './settlement';
import { lrcBuilder } from '../helpers/lrc';
import { Characters } from '../enums';

/**
 * This method receives the message from the FAB pinpad and parses it accordingly.
 * @param {String} hex - Message sent by the FAB pinpad.
 */
export const parseMessage = hex => {
  const payload = Buffer.from(hex, 'hex');

  const firstByte = payload.slice(0, 1);
  const secondLastByte = payload.slice(-2, -1);
  const tag = payload.slice(1, 3);
  const message = payload.slice(1, -3);

  // Validates payload has STX and ETX in correct order.
  if (!Buffer.from(firstByte).equals(Characters.STX))
    throw new Error('Message does not start with STX.');
  if (!Buffer.from(secondLastByte).equals(Characters.ETX))
    throw new Error('Message does not have ETX as second to last byte.');

  // Validates payload LRC matches our calculation of the LRC.
  const receivedLRC = payload.slice(-1);
  const bytesForLRCcalc = payload.slice(1, -1);
  const calculatedLRC = Buffer.from(lrcBuilder(bytesForLRCcalc), 'hex');
  if (!receivedLRC.equals(calculatedLRC))
    throw new Error('LRC calculation did not match. Invalid payload.');

  // Processes the payload based on tag.
  switch (tag.toString()) {
    case '02':
      return parseAuth(message);
    case '03':
      return parseVoid(message);
    case '04':
      return parseSettlement(message);
    default:
      throw new Error('Message Type not compatible.');
  }
};
