import React, { useMemo } from 'react';

import {
  PaymentMethods,
  BannerWarning,
  ServiceCardButton,
  LocaleSelection,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import Logo from '../../../assets/images/logo.svg';
import DineInImage from '../../../assets/images/dinein.svg';
import TakeawayImage from '../../../assets/images/takeaway.svg';
import WashHandsImage from '../../../assets/images/wash-hands.png';

const PrimaryHome = ({
  t = () => {},
  card,
  cash,
  icons,
  languages,
  healthMsg = true,
  className = '',
  isCurrentLang,
  primaryAction,
  secondaryAction,
  onChangeLanguage,
  accessibilityEnabled = false,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  const titleClass = useMemo(() => {
    return isCurrentLang('uae')
      ? 'text-4xl xs:text-5xl sm:text-6xl md:text-8xl'
      : 'text-6xl xs:text-8xl sm:text-9xl md:text-12xl';
  }, [isCurrentLang]);

  const subTitleClass = useMemo(() => {
    return isCurrentLang('uae')
      ? 'text-3xl xs:text-4xl sm:text-5xl md:text-7xl'
      : 'text-4xl xs:text-5xl sm:text-7xl md:text-9xl';
  }, [isCurrentLang]);

  const isBordered = !!(icons?.length || card);

  return (
    <HomeCustomisation.Provider>
      <HomeCustomisation.Background />

      <div
        data-test="primary-home"
        className={`z-10 flex flex-col justify-between items-center h-full w-full overflow-y-hidden  ${className}`}
      >
        <div
          data-test="home-upper"
          className={`flex flex-col items-center justify-start w-full h-full mb-${
            accessibilityEnabled ? '16' : '32'
          }`}
        >
          <PaymentMethods
            t={t}
            card={card}
            cash={cash}
            Logo={Logo}
            icons={icons}
            enableLogo={!accessibilityEnabled}
            className={`px-12 py-4 text-7xl ${isBordered ? 'border-2 border-black' : ''}  rounded ${
              isBordered && !accessibilityEnabled ? 'mt-32' : ''
            }`}
          />

          <div className="flex flex-col items-center justify-center mt-32 leading-none">
            <p className={`${titleClass} text-black font-extrabold`} data-test="home-title">
              {t('homeTitle')}
            </p>

            <p className={`${subTitleClass} text-black font-extrabold`} data-test="home-subtitle">
              {t('homeSubtitle')}
            </p>
          </div>
        </div>

        <div
          data-test="home-lower"
          className="flex flex-col-reverse items-center justify-start w-full h-full"
        >
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-terms"
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              className={`w-4/5 mb-${healthMsg ? '2' : '20'}`}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-health"
              image={WashHandsImage}
              className={`w-4/5 mb-${showTermsAndConditionsButton ? '2' : '20'}`}
            />
          )}

          <div
            data-test="home-services"
            className={`flex items-center justify-center w-11/12 ${
              healthMsg ? 'mb-32' : 'mb-96'
            } space-x-4 sm:items-stretch sm:w-4/5 sm:space-x-16`}
          >
            {!!secondaryAction && (
              <ServiceCardButton
                data-test="button-secondary"
                onClick={secondaryAction}
                className="w-1/2 p-4 bg-white shadow-lg sm:p-8 rounded-home-button"
              >
                <div className="mt-4 text-3xl sm:text-6xl">{t('homeSecondaryAction')}</div>
                <DineInImage className="w-32 sm:w-64" />
              </ServiceCardButton>
            )}

            {!!primaryAction && (
              <ServiceCardButton
                data-test="button-primary"
                onClick={primaryAction}
                className="w-1/2 bg-white shadow-lg rounded-home-button"
              >
                <div className="mt-4 text-3xl sm:text-6xl">{t('homePrimaryAction')}</div>
                <TakeawayImage className="w-12 sm:w-32" />
              </ServiceCardButton>
            )}
          </div>
        </div>

        <div className="flex justify-center w-full mt-8 md:mb-0" data-test="home-footer">
          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { PrimaryHome };
