import { check } from '@fingermarkglobal/validation';
import { request, getJwtToken } from '@fingermarkglobal/request';
import { SaveOrderPayload } from '@fingermarkglobal/types';
import { buildOrderSaveEndpoint, buildRequestOptions } from '../../shared';

const saveOrder = async (
  payload: SaveOrderPayload,
  timeout: number = 30,
  ip: string = ''
): Promise<unknown> => {
  try {
    logger.info('[fm-api][order/save] - started');

    const invalidSessionPayload: string = check({
      name: 'saveOrder',
      parameters: {
        sessionId: payload.session.sessionId,
        timestamp: payload.session.timestamp,
      },
    });

    if (invalidSessionPayload)
      throw new Error(`[fm-api][order/save] - failed to validate parameters: [${invalidSessionPayload}]`);

    if (payload.cart) {
      const invalidCartPayload: string = check({
        name: 'saveOrder',
        parameters: {
          cartMode: payload.cart.mode,
        },
      });

      if (invalidCartPayload)
        throw new Error(`[fm-api][order/save] - failed to validate cart parameters: [${invalidCartPayload}]`);
    }

    const { customSaveOrderEndpoint: customEndpoint } = payload?.settings?.labs || {};
    const endpoint = buildOrderSaveEndpoint({ customEndpoint, ip });

    const token: string = await getJwtToken();
    if (!token) throw new Error(`[fm-api][order/save] error generating jwt token`);

    const options = buildRequestOptions({
      endpoint,
      payload,
      token,
      timeout,
    });

    const response: unknown = await request.post(endpoint, options).json();

    if (!response) throw new Error(`[fm-api][order/save] - failed with response {${response}}`);

    return response;
  } catch (err) {
    logger.error('[fm-api][order/save] - error:', err);
    throw err;
  } finally {
    logger.info('[fm-api][order/save] - finished');
  }
};

export { saveOrder };
