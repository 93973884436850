import { createCipheriv } from 'crypto';
import { nonce } from './nonce';
import { xor } from './xor';

export const encrypt = ({ cipherKey, initVector } = {}, bytes) => {
  const algorithm = 'aes-256-cbc';
  const nonceValue = nonce();
  const finalInitVector = xor(initVector, nonceValue);

  const cipher = createCipheriv(algorithm, cipherKey, finalInitVector);

  const data = cipher.update(bytes);

  const list = [data, cipher.final()];
  const encrypted = Buffer.concat(list);

  return [encrypted, nonceValue];
};
