import { RestaurantMenuCount, ChromeSerialSettings } from '@fingermarkglobal/types';
import {
  retry,
  timeout,
  request,
  hawkeEndpoint,
  HTTPError,
  TimeoutError,
  getJwtToken,
} from '@fingermarkglobal/request';
import { isLocalUrl } from '../utils/is-local-url';

const getPromotionalRequest = async (
  count: RestaurantMenuCount,
  settings: ChromeSerialSettings,
) => {
  const organisationId = process.env.POI_APP_ORGANISATION_ID || null;

  const { storeId: id } = settings.settings.datasource;
  const { customRestaurantEndpoint = null } = settings.settings.labs || {};

  const endpoint = customRestaurantEndpoint || hawkeEndpoint;

  try {
    const token = await getJwtToken();

    const { numberOfMeals, numberOfUpsells, numberOfCoupons } = count;

    const mealsRequest =
      numberOfMeals > 0
        ? request
          .get(`${endpoint}/menu/meals/${organisationId}/${id}`, {
            retry,
            timeout,
            headers: {
              Authorization: `Bearer ${token}`,
              'x-api-apex-logging-project': process.env.POI_APP_LOGGER_PROJECT,
            },
            ...(isLocalUrl(endpoint) && { prefixUrl: '' }),
          })
          .json()
        : Promise.resolve([]);

    const upsellsRequest =
      numberOfUpsells > 0
        ? request
          .get(`${endpoint}/menu/upsells/${organisationId}/${id}`, {
            retry,
            timeout,
            headers: {
              Authorization: `Bearer ${token}`,
              'x-api-apex-logging-project': process.env.POI_APP_LOGGER_PROJECT,
            },
            ...(isLocalUrl(endpoint) && { prefixUrl: '' }),
          })
          .json()
        : Promise.resolve([]);

    const couponsRequest =
      numberOfCoupons > 0
        ? request
          .get(`${endpoint}/menu/coupons/${organisationId}/${id}`, {
            retry,
            timeout,
            headers: {
              Authorization: `Bearer ${token}`,
              'x-api-apex-logging-project': process.env.POI_APP_LOGGER_PROJECT,
            },
            ...(isLocalUrl(endpoint) && { prefixUrl: '' }),
          })
          .json()
        : Promise.resolve([]);

    const requests = [mealsRequest, upsellsRequest, couponsRequest];
    const response = await Promise.all(requests);

    const [meals, upsells, coupons] = response;

    return {
      meals,
      upsells,
      coupons,
    };
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;
    logger.error('restaurant - getPromotionalRequest - error: ', err);
    throw err;
  }
};

export { getPromotionalRequest };
