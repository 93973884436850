import { buildAuthRequest } from './auth';
import { buildSettlementRequest } from './settlement';
import { buildVoidRequest } from './void';
import { lrcBuilder } from '../helpers';

const buildMessageByTransactionName = payload => {
  const { transactionName = '' } = payload || {};
  switch (transactionName) {
    case 'auth':
      return buildAuthRequest(payload);
    case 'settlement':
      return buildSettlementRequest(payload);
    case 'void':
      return buildVoidRequest(payload);
    default:
      throw new Error('Unsupported transaction type.');
  }
};

/**
 * This method receives the full payload and creates a message based off of it.
 * @param {Object} payload - Payload received by FAB().
 */
const buildMessage = payload => {
  const STX = '\x02'; // Start Text byte.
  const ETX = '\x03'; // End Text byte.

  // Build the message.
  const message = buildMessageByTransactionName(payload);
  const messageAndETX = Buffer.from(Buffer.from(message, 'hex') + Buffer.from(ETX));

  // Build the LRC checksum.
  const lrc = lrcBuilder(messageAndETX);
  const hexLrc = lrc.toString(16);

  const fullMessage = Buffer.from(
    Buffer.from(STX) + Buffer.from(message, 'hex') + Buffer.from(ETX) + Buffer.from(hexLrc, 'hex'),
  ).toString('hex');

  return fullMessage;
};

export { buildMessage };
