import { Enums } from '../enums';

export const buildVoidRequest = payload => {
  const { messNumber, voidRcptNum, amount, tid } = payload || {};
  const tag = Enums.messageType.void;

  const payloadString = `${tag},${voidRcptNum},${amount * 100},${messNumber},${tid}`;
  const buf = Buffer.from(payloadString);
  const hex = buf.toString('hex');

  return hex;
};
