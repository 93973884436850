import { validate } from '@fingermarkglobal/validation';
import { request } from '@fingermarkglobal/request';
import { hexEncode } from '@fingermarkglobal/utilities';
import ky from 'ky';

const sendSMS = async ({ countryCode, phoneNumber, message, settings }) => {
  try {
    const { endpoint = null, password = null, source = null, username = null } =
      settings?.routemobile || {};

    validate({
      name: 'sendSMS',
      parameters: { endpoint, source, username, password, phoneNumber, message },
    });

    const routemobileUrl = `${endpoint
      .replace('{{phoneNumber}}', `+${countryCode}${phoneNumber}`)
      .replace('{{source}}', source)
      .replace('{{username}}', username)
      .replace('{{password}}', password)
      .replace('{{message}}', message)}&type=2`;

    const response = await request.get(routemobileUrl).text();
    const data = response || '';

    if (data.split('|')[0] !== '1701') throw new Error(response);

    return response;
  } catch (error) {
    if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) throw error;
    throw new Error(`[sendSMS] Fail to handle request: ${error}`);
  }
};

const buildReceiptMessage = ({
  headerText,
  orderNumberText,
  receiptUrlText,
  pendingPaymentText,
} = {}) => {
  const headerHex = hexEncode(headerText);
  const orderNumberHex = hexEncode(orderNumberText);
  const receiptUrlHex = hexEncode(receiptUrlText);
  const pendingPaymentHex = hexEncode(pendingPaymentText);

  // 000A breaks the line
  return [headerHex, orderNumberHex, receiptUrlHex, pendingPaymentHex].join('000A');
};

export { sendSMS, buildReceiptMessage };
