import { assign, createMachine } from 'xstate';
import { createResponse, fetchUrl, startPaymentStatusPinger } from './actions';

const paymentMachine = createMachine(
  {
    id: 'momo-payment',
    initial: 'idle',
    context: {},
    states: {
      idle: {
        on: {
          INITIALISE: {
            target: 'loading',
          },
        },
      },
      loading: {
        entry: fetchUrl,
        on: {
          URL: {
            target: 'statusCheck',
          },
          FAILURE: {
            target: 'failure',
            actions: assign((_, event) => ({
              paymentType: 'momopay', //This is required, otherwise retries will get the card payment provider instead
              info: {
                message: event['message']
              }
            }))
          },
          RETRY: null
        }
      },
      statusCheck: {
        entry: startPaymentStatusPinger,
        on: {
          SUCCESSFULL: {
            target: 'statusSuccess',
            actions: createResponse,
          },
          FAILURE: {
            target: 'failure',
            actions: assign((_, event) => ({
              paymentType: 'momopay', //This is required, otherwise retries will get the card payment provider instead
              info: {
                message: event['message']
              }
            }))
          },
          EXPIRED: {
            target: 'failure'
          }
        }
      },
      statusSuccess: {
        type: 'final',
      },
      failure: {
        on: {
          RETRY: {
            target: 'loading',
          },
          FAILURE: null,
        },
      },
    },
  }
);

export { paymentMachine };
