const checkWebhookAutoRetry = async (
  executeAutoRetry = false,
) => {
  try {
    if (executeAutoRetry === false || !executeAutoRetry) {
      return false;
    }

    return true;
  } catch (err) {
    logger.error('[fm-api][order/polling] - error', err);
    throw err;
  }
};

export { checkWebhookAutoRetry };
