import { assign } from 'xstate';
import { v4 as uuidv4 } from 'uuid';
import { buildPayment, buildStatus } from '../../../utilities/builders';

const createPayload = assign(
  (
    context,
    {
      amount: receivedAmount,
      config: receivedConfig,
      idempotencyKey: receivedItempotencyKey = uuidv4(),
    },
  ) => {
    const {
      amount: contextAmount = null,
      config: contextConfig = null,
      itempotencyKey: contextIdempotencyKey = null,
    } = context || {};

    const amount = contextAmount || receivedAmount;
    const config = contextConfig || receivedConfig;
    const idempotencyKey = contextIdempotencyKey || receivedItempotencyKey;

    logger.info('[@fingermarkglobal/adyen][createPayload]: adyen - payment transaction started...');

    const { payload, transactionId } = buildPayment({ amount, config });

    const { payload: statusPayload } = buildStatus({ config });

    return {
      amount,
      config,
      request: payload,
      statusRequest: statusPayload,
      idempotencyKey,
      transactionId,
    };
  },
);

export { createPayload };
