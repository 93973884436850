import { assign } from 'xstate';

const handleError = assign((context, event) => {
  const { data } = event || {};
  const { message = '' } = data || {};

  return {
    ...context,
    message, // catches `Error` objects that are thrown as it cant automatically destrucutre the `message` prop
    ...data,
  };
});

export { handleError };
