const hexEncode = str => {
  if (!str) return '';
  const result = [];

  for (let i = 0; i < str.length; i += 1) {
    const hex = str.charCodeAt(i).toString(16);
    result.push(`000${hex}`.slice(-4));
  }

  return result.join('');
};

export { hexEncode };
