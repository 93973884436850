import { request, getJwtToken } from '@fingermarkglobal/request';
import { WebkassaRequestDetail } from '@fingermarkglobal/types';
import { check } from '@fingermarkglobal/validation';
import { buildEndShiftEndpoint, buildRequestOptions } from '../../shared';

const endShift = async (
  payload: WebkassaRequestDetail,
  timeout: number = 30,
  ip: string = '',
  provider: string = '',
) => {
  try {
    logger.info('[fm-api][shift/end] - started');

    const invalid = check({
      name: 'endShift',
      parameters: { payload },
    });
    if (invalid) throw new Error(`[fm-api][shift/end] - failed to validate parameters [${invalid}]`);

    const token = await getJwtToken();
    if (!token) throw new Error(`[fm-api][shift/end] error generating jwt token`);
    
    const endpoint = buildEndShiftEndpoint({ip, provider});

    const options = buildRequestOptions({
      endpoint,
      payload,
      token,
      timeout,
    });

    const response = await request.post(endpoint, options).json();

    if (!response) throw new Error(`[fm-api][shift/end] request failed with response {${response}}`);

    return response;
  } catch (err) {
    logger.error('[fm-api][shift/end] - error:', err);
    throw err;
  } finally {
    logger.info('[fm-api][shift/end] - finished');
  }
};

export { endShift };
