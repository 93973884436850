import { Tags } from '../enums';

const getMessageLengthBytes = hex => {
  const buf = Buffer.allocUnsafe(2);

  buf.writeInt16BE(hex.length / 2);

  return buf.toString('hex');
};

const getTransactionBytes = transaction => {
  return Tags.TRANSACTION + transaction;
};

const getAmountBytes = amount => {
  return Tags.AMOUNT + (amount * 100).toString().padStart(12, '0');
};

const getOriginalRrn = originalRrn => {
  return Tags.ORIGINAL_RRN + Buffer.from(originalRrn.toString()).toString('hex');
};

const getOriginalDateTime = originalDateTime => {
  const year = originalDateTime.substr(0, 4);
  const month = originalDateTime.substr(4, 2);
  const day = originalDateTime.substr(6, 2);

  return Tags.ORIGINAL_DATE_TIME + year + month + day;
};

const getCardPan = cardPan => {
  return Tags.CARD_PAN + Buffer.from(cardPan.toString()).toString('hex');
};

const getAppIdBytes = () => {
  return Tags.APP_ID;
};

const getEcrPrintingAreaBytes = ecrPrintingArea => {
  return (
    Tags.ECR_PRINTING_AREA +
    Buffer.from(ecrPrintingArea.toString())
      .toString('hex')
      .padEnd(20, 'ff')
  );
};

const getEcrRefNumberBytes = ecrRefNumber => {
  return (
    Tags.ECR_REFERENCE_NUMBER +
    Buffer.from(ecrRefNumber.toString())
      .toString('hex')
      .padStart(32, '30')
  );
};

export {
  getAmountBytes,
  getAppIdBytes,
  getCardPan,
  getEcrPrintingAreaBytes,
  getEcrRefNumberBytes,
  getMessageLengthBytes,
  getOriginalDateTime,
  getOriginalRrn,
  getTransactionBytes,
};
