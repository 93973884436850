import { atom, selectorFamily } from 'recoil';
import { sessionState } from '../session';
import { formattedSettingsState } from '../system';

const loyaltyState = atom({
  key: 'loyaltyState',
  default: null,
});

const formattedLoyaltyPayloadState = selectorFamily({
  key: 'formattedLoyaltyPayloadState',
  get: () => ({ get }) => {
    const { id: sessionId } = get(sessionState) || {};
    const {
      kiosk: { organisation: organisationId, serial: kioskSerialKey, storeId },
    } = get(formattedSettingsState) || {};
    return {
      sessionId,
      organisationId,
      kioskSerialKey,
      storeId,
    };
  },
});

export { formattedLoyaltyPayloadState, loyaltyState };
