import { transactionsMachine } from '@fingermarkglobal/transactions.resolver';
import { settingsMachine } from '@fingermarkglobal/settings.resolver';
import { serialMachine } from '@fingermarkglobal/serial.resolver';
import { createMachine } from 'xstate';

import { RestaurantMachineContext } from '@fingermarkglobal/types';

import {
  setSerialAction,
  setSettingsAction,
  setTransactionsAction,
  setSerialFailureAction,
  setSettingsFailureAction,
  setTransactionsFailureAction
} from './actions';

const restaurantMachine = createMachine<RestaurantMachineContext>(
  {
    id: 'resolution-machine',
    initial: 'serial',
    context: {
      port: null,
      count: null,
      serial: null,
      origin: null,
      message: null,
      settings: null,
      transactions: null
    },
    states: {
      serial: {
        invoke: {
          id: 'serial-machine',
          src: serialMachine
        },
        on: {
          SERIAL_MACHINE_FAILURE: {
            target: 'failure',
            actions: ['setSerialFailureAction']
          },
          SERIAL_MACHINE_SUCCESS: {
            target: 'settings',
            actions: ['setSerialAction']
          }
        }
      },
      settings: {
        invoke: {
          id: 'settings-machine',
          src: settingsMachine,
          data: {
            serial: context => context?.serial
          }
        },
        on: {
          SETTINGS_MACHINE_FAILURE: {
            target: 'failure',
            actions: ['setSettingsFailureAction']
          },
          SETTINGS_MACHINE_SUCCESS: {
            target: 'transactions',
            actions: ['setSettingsAction']
          }
        }
      },
      transactions: {
        invoke: {
          id: 'transactions-machine',
          src: transactionsMachine,
          data: {
            settings: context => context?.settings
          }
        },
        on: {
          TRANSACTIONS_MACHINE_FAILURE: {
            target: 'failure',
            actions: ['setTransactionsFailureAction']
          },
          TRANSACTIONS_MACHINE_SUCCESS: {
            target: 'waiting',
            actions: ['setTransactionsAction']
          }
        }
      },
      // NOTE:
      // - This state exists explicitly for UI interaction
      // - This allows for any 'intergration' steps
      // - Primarily for when context over React is needed
      waiting: {
        on: {
          EXTERNAL_TRANSITION_FAILURE: 'failure',
          EXTERNAL_TRANSITION_SUCCESS: 'success'
        }
      },
      failure: {
        on: {
          EXTERNAL_TRANSITION_RESET: 'serial'
        }
      },
      success: {
        type: 'final'
      }
    }
  },
  {
    actions: {
      setSerialAction,
      setSettingsAction,
      setTransactionsAction,
      setSerialFailureAction,
      setSettingsFailureAction,
      setTransactionsFailureAction
    }
  }
);

export { restaurantMachine };
