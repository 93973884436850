import { validate } from '@fingermarkglobal/validation';
import { resetCategoryProductTree } from '../utilities';
import { formatValidatedValues } from '../utilities/validate';

// add item by increasing count
const createAdder = ({ uid, update } = {}) => () => {
  validate({ name: 'Cart Adder', paramaters: { uid, update } });

  const handler = item => {
    const { count } = item;
    const { current: currentCount, max } = count;

    if (max !== -1 && currentCount >= max)
      return {
        ...item,
        count: { ...count, updatedByHandler: true },
      };

    return {
      ...item,
      count: { ...count, current: currentCount + 1, updatedByHandler: true },
      validated: false,
    };
  };

  update({ uid, handler });
};

// removes item by reducing count
const createRemover = ({ uid, update } = {}) => () => {
  validate({ name: 'Cart Remover', paramaters: { uid, update } });

  const handler = item => {
    const { count } = item;
    const { current: currentCount, min } = count;

    if (currentCount <= min)
      return {
        ...item,
        count: { ...count, updatedByHandler: true },
      };

    return {
      ...item,
      count: { ...count, current: currentCount - 1, updatedByHandler: true },
      validated: false,
    };
  };

  update({ uid, handler });
};

// removes item by reducing count to 0
const createRemoverAll = ({ uid, update } = {}) => () => {
  validate({ name: 'Cart Remover All', paramaters: { uid, update } });
  const handler = item => {
    const { count } = item;
    return {
      ...item,
      count: { ...count, current: 0 },
    };
  };

  update({ uid, handler });
};

const createSelector = ({ uid, update, categoryUid } = {}) => ({
  resetDefaultToZero = false,
} = {}) => {
  validate({ name: 'Cart Selector', paramaters: { uid, update, categoryUid } });

  const pre = ({ uid: originUid, item }) =>
    resetCategoryProductTree({
      product: item,
      currentCategoryUid: categoryUid,
      originUid,
      resetDefaultToZero,
    });

  const handler = item => {
    const { count, price } = item;
    const { unit } = price;

    return {
      ...item,
      count: { ...count, current: Number(!count.current) },
      price: { ...price, current: unit },
      validated: false,
    };
  };

  update({ uid, handler, pre });
};

const createEditor = ({ uid, update } = {}) => ({ isEditing = false }) => {
  validate({ name: 'Cart Editor', paramaters: { uid, update } });
  const handler = item => {
    return {
      ...item,
      isEditing,
    };
  };

  update({ uid, handler });
};

const createSetValidatedValues = ({ uid, update } = {}) => ({ price, isAvailable = true }) => {
  validate({ name: 'Cart Set Validated Values', paramaters: { uid, update } });
  const handler = item => formatValidatedValues({ item, price, isAvailable });
  update({ uid, handler });
};

export {
  createAdder,
  createRemover,
  createSelector,
  createRemoverAll,
  createEditor,
  createSetValidatedValues,
};
