import { interpret } from '@fingermarkglobal/utilities';
import { createLoyaltyOffersAndRewardsMachine } from './machines/loyalty-offers-and-rewards';

const doLoyaltyOffersAndRewards = ({ transition = null, token, storeId }) => {
  const machine = createLoyaltyOffersAndRewardsMachine({ token, storeId });

  const { instance: loyaltyOffersAndRewardsMachine } = interpret({
    name: 'loyaltyOffersAndRewardsMachine',
    machine,
    transition,
  });

  return { loyaltyOffersAndRewardsMachine };
};

export { doLoyaltyOffersAndRewards };
