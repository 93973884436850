import { assign } from 'xstate';

const createPayload = assign((context, { amount, config }) => {
  logger.info(
    'kaspi-pay - payment transaction started... context: ',
    context,
    '-amount: ',
    amount,
    '-config: ',
    config,
  );

  const { settings } = config || {};
  const { ownCheque } = settings || {};

  const request = { amount, ownCheque };

  return {
    config,
    request,
  };
});

export { createPayload };
