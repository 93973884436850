import { ChromeSerialSettings } from '@fingermarkglobal/types';
import {
  retry,
  timeout,
  request,
  hawkeEndpoint,
  HTTPError,
  TimeoutError,
  getJwtToken,
} from '@fingermarkglobal/request';
import { isLocalUrl } from '../utils/is-local-url';

const getCategoriesRequest = async (settings: ChromeSerialSettings) => {
  const organisationId = process.env.POI_APP_ORGANISATION_ID || null;

  const { storeId: id } = settings.settings.datasource;
  const { customRestaurantEndpoint = null } = settings.settings.labs || {};

  const endpoint = customRestaurantEndpoint || hawkeEndpoint;

  try {
    const token = await getJwtToken();

    const categories = await request
      .get(`${endpoint}/menu/categories/${organisationId}/${id}`, {
        retry,
        timeout,
        headers: {
          Authorization: `Bearer ${token}`,
          'x-api-apex-logging-project': process.env.POI_APP_LOGGER_PROJECT,
        },
        ...(isLocalUrl(endpoint) && { prefixUrl: '' }),
      })
      .json();

    return categories;
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;

    logger.error('restaurant - getCategoriesRequest - error:', err);

    throw err;
  }
};

export { getCategoriesRequest };
