import { parseResponse } from './response';
import { parseStatus } from './status';
import { isTransactionApproved } from '../helpers';

import { Characters, MessageType, statuses } from '../enums';

const parseMessage = payload => {
  const firstByte = payload.slice(0, 1).toString('hex');
  const messageType = payload.slice(1, 2).toString('hex');

  // Validates payload has SOH
  if (firstByte !== Characters.SOH) throw new Error('Message does not start with SOH.');

  const message = {
    messageType,
  };

  switch (messageType) {
    case MessageType.STATUS:
      message.statusArray = parseStatus(payload);
      break;

    case MessageType.RESPONSE:
      message.parsed = parseResponse(payload);

      if (!isTransactionApproved(message.parsed.transactionResponse.value)) {
        throw new Error(`${message.parsed.responseCodeDescription.value.split('|')[3]}`);
      }

      break;

    default:
      throw new Error(`Unknown message type [${messageType}]`);
  }

  return message;
};

const isMessageResponse = message => {
  return message.messageType === MessageType.RESPONSE;
};

const isMessageStatus = message => {
  return message.messageType === MessageType.STATUS;
};

const isStatusIdle = statusArray => {
  return statusArray != null && statusArray.includes(statuses.idleScreen.id);
};

export { parseMessage, isMessageResponse, isMessageStatus, isStatusIdle };
