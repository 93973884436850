import { interpret } from '@fingermarkglobal/utilities';
import { createLoyaltyLogInMachine } from './machines/loyalty-login';

const doLoyaltyLogIn = ({ payload = null, transition = null }) => {
  const machine = createLoyaltyLogInMachine({ payload });

  const { instance: loyaltyLoginMachine } = interpret({
    name: 'loyaltyLoginMachine',
    machine,
    transition,
  });

  return { loyaltyLoginMachine };
};

export { doLoyaltyLogIn };
