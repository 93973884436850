import { HTTPClient } from '@fingermarkglobal/protocols';

const executeRequest = async ({ context, status = false } = {}) => {
  const { request: payload, statusRequest, config, idempotencyKey } = context;
  const { settings, port } = config;
  const { apiKey, poiId, test = true, timeout, isLocal = true } = settings;

  const environment = test ? 'test' : 'live';

  const endpoint = isLocal
    ? `https://${poiId}.${environment}.terminal.adyen.com:8443/nexo`
    : `https://supersonic-zodac-bqqvoxpupq-ew.a.run.app/https://terminal-api-${environment}.adyen.com/sync`;

  try {
    // Protocols should be smart enough to get a port if ones not already initialised
    // maybe store on the window once initialised?
    const http = new HTTPClient({ port, config: { timeout } });
    // https://github.com/sindresorhus/ky#kyinput-options
    const request = {
      headers: {
        'x-API-key': apiKey, // should headers be included in request building?
        'Idempotency-Key': idempotencyKey,
      },
      url: endpoint,
      method: 'post',
      data: status ? statusRequest : payload,
      timeout: status ? 20000 : timeout * 1000,
    };

    const { data: response } = await http.send({ payload: request });
    return response;
  } catch (error) {
    throw new Error('Failed to send request');
  }
};

export { executeRequest };
