import { v4 as uuid } from 'uuid';

const isPortValid = ({ port }) => {
  if (port && port.onMessage) {
    return true;
  }
  throw new Error('Service worker is not running');
};

const connect = (port, ip, tcpPort, identifier) => {
  if (isPortValid({ port })) {
    port.postMessage({
      method: 'create',
      tcpPort,
      ip,
      id: identifier,
      topic: 'request/tcp',
    });
  }
};

const disconnect = (port, socket, handler) => {
  if (isPortValid({ port })) {
    port.onMessage.removeListener(handler);

    port.postMessage({
      method: 'clear',
      id: uuid(),
      socket,
      topic: 'request/tcp',
    });
  }
};

const send = (payload, port, socket, handler) => {
  if (!socket) {
    disconnect(port, {}, handler);
    throw new Error('Socket not defined');
  }

  if (isPortValid({ port })) {
    port.postMessage({
      method: 'write',
      socket,
      payload,
      id: uuid(),
      topic: 'request/tcp',
    });
  }
};

const addListener = (port, handler) => {
  if (isPortValid({ port })) {
    port.onMessage.addListener(handler);
  }
};

export { send, disconnect, addListener, connect };
