import { request, getJwtToken } from '@fingermarkglobal/request';
import { PlaceOrderPayload } from '@fingermarkglobal/types';
import { check, validate } from '@fingermarkglobal/validation';
import { buildOrderPollingEndpoint, buildRequestOptions } from '../../shared';

const pollingOrder = async (
  payload: PlaceOrderPayload,
  timeout: number = 30,
  ip?: string,
  provider: string = '',
) => {
  try {
    const { settings } = payload || {};
    const { interval } = settings || {};
    const { autoRetry } = interval || {};
  
    if (autoRetry?.webhookPolling) await wait(autoRetry.webhookPolling * 1000)

    validate({ name: 'webhookPolling', parameters: { payload } });

    const invalid = check({
      name: 'Place-order',
      parameters: { payload },
    });

    if (invalid)
      throw new Error(`[fm-api][order/polling] - failed to validate parameters: [${invalid}]`);
    
    const { customPollingOrderEndpoint: customEndpoint } = payload?.settings?.labs || {};
    const endpoint = buildOrderPollingEndpoint({customEndpoint, ip, provider});

    const token = await getJwtToken();
    if (!token) throw new Error(`[fm-api][order/polling] error generating jwt token`);

    const options = buildRequestOptions({
      endpoint,
      payload,
      token,
      timeout,
    });

    const response = await request.post(endpoint, options).json();

    if (!response) throw new Error(`[fm-api][order/polling] - failed with response {${response}}`);

    return response;
  } catch (err) {
    logger.error('[fm-api][order/polling] - error:', err);
    throw err;
  } finally {
    logger.info('[fm-api][order/polling] - finished');
  }
};

const wait = async miliseconds => {
  return new Promise(resolve => setTimeout(resolve, miliseconds));
};

export { pollingOrder };
