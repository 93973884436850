import { atom, selector } from 'recoil';

const upsellState = atom({
  key: 'upsellState',
  default: [],
});

const trackedUpsellsState = atom({
  key: 'trackedUpsellsState',
  default: [],
});

const addedUpsellState = atom({
  key: 'addedUpsellState',
  default: [],
});

const removedUpsellState = atom({
  key: 'removedUpsellState',
  default: [],
});

const formattedAddedUpsell = selector({
  key: 'formattedAddedUpsell',
  get: ({ get }) => {
    const addedUpsells = get(addedUpsellState);
    return addedUpsells.reduce((cur, val) => {
      if (!cur[val.productId])
        cur[val.productId] = [{ productId: val.main.productId, nameText: val.main.name.text }];
      else cur[val.productId].push({ productId: val.main.productId, nameText: val.main.name.text });
      return cur;
    }, {});
  },
});

export {
  upsellState,
  trackedUpsellsState,
  addedUpsellState,
  removedUpsellState,
  formattedAddedUpsell,
};
