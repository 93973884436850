export { fields, fieldsMap, getFieldById } from './fields';
export { statuses, statusMap, getStatusById } from './status';
export { Tags } from './tags';

const Characters = {
  SOH: '01',
  ACK: '06',
};

const MessageType = {
  RESPONSE: '01',
  STATUS: '00',
};

const Transactions = {
  PURCHASE: '0100',
  REFUND: '0102',
};

const SuccessCodes = ['000', '001', '003', '007', '087', '089', '400', '500'];

export { Characters, MessageType, SuccessCodes, Transactions };
