import { createMachine } from 'xstate';
import { transactionsResolve } from '../../utilities/transactions'
import { TransactionsMachineContext } from '@fingermarkglobal/types';
import {
  setTransactionsAction,
  sendParentFailureAction,
  sendParentSuccessAction
} from './actions';

const transactionsMachine = createMachine<TransactionsMachineContext>(
  {
    id: 'transactions-machine',
    initial: 'populating',
    context: {
      settings: null,
      transactions: null
    },
    states: {
      populating: {
        invoke: {
            src: (context) => transactionsResolve(context?.settings),
            onDone: {
              target: 'success',
              actions: ['setTransactionsAction']
            },
            onError: {
              target: 'failure'
            }
        }
      },
      success: {
        entry: ['sendParentSuccessAction'],
        type: 'final'
      },
      failure: {
        entry: ['sendParentFailureAction'],
        type: 'final'
      }
    }
  },
  {
    actions: {
      setTransactionsAction,
      sendParentFailureAction,
      sendParentSuccessAction
    }
  }
);

export { transactionsMachine };
