const isShowQRCode = ({ status = '' }) => {
  const allowedStatus = [
    'ORDER_CONFIRMATION_SUCCESS',
    'ORDER_REFUND_CANCELLED',
    'ORDER_CONFIRMATION_ERROR',
  ];
  return allowedStatus.includes(status);
};

export { isShowQRCode };
