const buildPaymentPayload = (orderId, deviceConfig, totalPrice, settings, mockState) => {
  const adyen = settings?.settings?.payment?.adyen || {};
  const noon = settings?.settings?.payment?.noon || {};
  const verifone = settings?.settings?.payment?.verifone || {};
  const fab = settings?.settings?.payment?.fab || {};
  const paymentTimeout = settings?.settings?.interval?.pinpad?.payment || 30;
  const siteId = settings?.settings?.order?.storeId || 0;
  const storeId = settings?.settings?.site?.storeId || 0;
  const ksaMada = settings?.settings?.payment?.ksaMada || {};

  const payload = {
    paymentInfo: {
      amount: totalPrice,
      paymentTimeout,
    },
    settings: {
      adyen: {
        ...adyen,
        orderSettings: {
          siteId,
          storeId,
          orderId,
        },
      },
      noon,
      verifone,
      marshal: fab,
      skip: {},
      'ksa-mada': ksaMada,
    },
    deviceConfig,
    mock: {
      state: null,
    },
  };

  if (!mockState) {
    delete payload.mock;
  }

  return payload;
};

export { buildPaymentPayload };
