import { atom } from 'recoil';

// This is for managing template updates
// header props etc...
const templateState = atom({
  key: 'templateState',
  default: {},
});

export { templateState };
