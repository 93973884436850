import ReactGA from 'react-ga4';

const registerProductView = ({
  sessionId,
  position,
  productVariant,
  categoryName,
  product,
  listName,
  storeAddress,
  organisation,
  serial,
  upsell = '',
} = {}) => {
  return new Promise((resolve, reject) => {
    const details = {
      index: 1,
      sessionId,
      storeAddress,
      serial,
      listName,
      productVariant,
      organisation,
      categoryName,
      productName: product?.name?.text,
      productSku: product?.id,
      position,
      upsell,
    };

    try {
      logger?.info(`google-analytics - google analytics 4 - Preparing product_detail_view event.`);
      ReactGA?.event('product_detail_view', {
        event_category: 'product_detail_view',
        event_label: 'product_detail_view',
        ...details,
      });
      setTimeout(resolve, 2000);
    } catch (error) {
      logger?.warn(
        `google-analytics - google analytics 4 - Error on product_detail_view event. Err: ${error}`,
      );
      reject(error);
    }
  });
};

export { registerProductView };
