import { createMachine, assign } from 'xstate';
import { sendSMS as sendSMSRoutemobile } from './providers/routemobile';
import { sendSMS as sendSMSMock } from './providers/mock';

const setConfig = assign({
  message: (context, { message }) => message,
  settings: (context, { settings }) => settings,
  phoneNumber: (context, { phoneNumber }) => phoneNumber,
  countryCode: (context, { countryCode }) => countryCode,
});

const setError = assign({
  error: (context, { data }) => data,
});

const setResponse = assign({
  response: (context, event) => event,
});

const sendSMS = ({ countryCode, phoneNumber, message, settings }) => {
  const { type: provider = '' } = settings || {};

  switch (provider) {
    case 'routemobile':
      return sendSMSRoutemobile({ countryCode, phoneNumber, message, settings });
    default:
      return sendSMSMock({ countryCode, phoneNumber, message });
  }
};

const smsMachine = createMachine(
  {
    id: 'sms',
    initial: 'idle',
    context: {
      error: null,
      countryCode: null,
      phoneNumber: null,
      settings: null,
      message: null,
    },
    states: {
      idle: {
        on: {
          SEND: {
            target: 'sending',
            actions: ['setConfig'],
          },
        },
      },
      sending: {
        invoke: {
          id: 'sendSMS',
          src: ({ message, settings, phoneNumber, countryCode }) =>
            sendSMS({ message, settings, phoneNumber, countryCode }),
          onDone: {
            target: 'success',
            actions: ['setResponse'],
          },
          onError: {
            target: 'error',
            actions: ['setError'],
          },
        },
      },
      success: { type: 'final' },
      error: { type: 'final' },
    },
  },
  {
    actions: {
      setConfig,
      setError,
      setResponse,
    },
  },
);

export { smsMachine };
