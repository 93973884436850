import { assign } from 'xstate';

const createPayload = assign((context, { config, transactionId, paymentMethod }) => {
  logger.info(
    'kaspi-pay - refund transaction started... context: ',
    context,
    '-transactionId: ',
    transactionId,
    '-config: ',
    config,
    'paymentMethod',
    paymentMethod,
  );

  const { settings, amount } = config || {};
  const { ownCheque } = settings || {};

  const request = { amount, transactionId, paymentMethod, ownCheque };

  return {
    config,
    request,
  };
});

export { createPayload };
