import successResponse from '../../test/data/launchPaySucessResponse';
import successRefundResponse from '../../test/data/launchRefundSucessResponse';
import { executeRequest } from '../utilities/request';
import { buildRequest } from './buildRequest';
import { buildEndpoint } from './buildEndpoint';

const INTERVAL = 1000;
const REQUEST_NAME = 'status';

const retrieveStatus = async requestParams => {
  const { config, endpoint } = requestParams;
  logger.info('kaspi-pay - getStatus - retrieveStatus: ', config);
  const result = await executeRequest({ config, endpoint, method: 'GET' });
  logger.info('kaspi-pay - getStatus - retrieveStatus result: ', result);
  return { statusCode: result.status, data: result.data };
};

const autoCall = callCredentials => {
  const { config, endpoint, interval } = callCredentials;
  const requestParams = { config, endpoint };
  const { mock } = config;

  if (mock) {
    const { toMock } = config;
    return new Promise(res => {
      logger.info('kaspi-pay - getStatus autoCall - mocking the response');
      if (toMock === 'Pay') {
        res(successResponse);
      } else {
        res(successRefundResponse);
      }
    });
  }
  return new Promise((res, rej) => {
    const timer = setInterval(async function() {
      const status = await retrieveStatus(requestParams);
      const { statusCode, data } = status;

      if (statusCode === 500 || data.status === 'fail') {
        clearInterval(timer);
        rej("Request for status has been suspended because 'Fail' status is received");
      }

      if (data.status !== 'wait') {
        clearInterval(timer);
        res(data);
      }
    }, interval);
  });
};

const getStatus = async context => {
  const { config = {}, processId } = context || {};

  const { settings } = config;
  const { pinpadIp, port } = settings;

  try {
    logger.info(`kaspi-pay - getStatus - request: `, context);

    const queryParams = {
      processId,
    };

    const newEndpoint = `${buildEndpoint(pinpadIp, port)}${REQUEST_NAME}`;
    const requestUrl = buildRequest(newEndpoint, queryParams);

    const interval = INTERVAL;

    const callCredentials = {
      config,
      endpoint: requestUrl,
      interval,
    };

    const statusResponse = await autoCall(callCredentials);

    return statusResponse;
  } catch (err) {
    logger.error('kaspi-pay][getStatus]: Error in getStatus:', err);
    throw err;
  }
};

export { getStatus };
