import { atom, DefaultValue, selector } from 'recoil';
import { trim } from '../utilities/trim';
import { destruct } from '../utilities/destruct';

import { evaluate } from '../utilities/evaluate';
import { stack } from '../utilities/stack';

const cartStateAtom = atom({
  key: 'cartStateAtom',
  default: [],
});

const secondaryCartStateAtom = atom({
  key: 'secondaryCartStateAtom',
  default: [],
});

const formattedCartState = selector({
  key: 'formattedCartState',
  get: ({ get }) => {
    const cart = get(cartStateAtom);

    const destructered = cart.map(destruct);
    const formatted = destructered.map(trim);

    logger.debug('cart - formattedCartState - formatted cart:', {
      cart: formatted,
    });
    return formatted;
  },
});

// We're using `selector` rather than `effects_UNSTABLE` to evaluate
// the cart states because of performance issues. See: https://fingermarkglobal.atlassian.net/browse/S2-2393
const cartStateSelector = selector({
  key: 'cartStateSelector',
  get: ({ get }) => get(cartStateAtom),
  set: ({ set }, value) => {
    const safeValue = value instanceof DefaultValue ? [] : value;

    const cart = safeValue
      .filter(product => product.count.current > 0)
      .map(product => evaluate({ product }))
      .reduce(stack, []);

    // Check if cart has a single item with isBag === true. If so, remove it.
    if (cart?.length === 1 && cart[0]?.isBag === true) {
      console.debug('cart - cartStateSelector - Clearing bag from cart');
      set(cartStateAtom, []);
    } else {
      // NOTE: This is intentionally a `console` and not a `logger`
      // - WE NEVER WANT TO SEND THIS TO APEX
      console.debug('cart - cartStateSelector - cart:', { cart });
      set(cartStateAtom, cart);
    }
  },
});

const secondaryCartStateSelector = selector({
  key: 'secondaryCartStateSelector',
  get: ({ get }) => get(secondaryCartStateAtom),
  set: ({ set }, value) => {
    const safeValue = value instanceof DefaultValue ? [] : value;

    const secondaryCart = safeValue
      .filter(product => product.count.current > 0)
      .map(product => evaluate({ product }))
      .reduce(stack, []);

    // Check if cart has a single item with isBag === true. If so, remove it.
    if (secondaryCart?.length === 1 && secondaryCart[0]?.isBag === true) {
      console.debug('cart - secondaryCartStateSelector - Clearing bag from cart');
      set(secondaryCartStateAtom, []);
    } else {
      // NOTE: This is intentionally a `console` and not a `logger`
      // - WE NEVER WANT TO SEND THIS TO APEX
      console.debug('cart - secondaryCartStateSelector - secondary cart:', { secondaryCart });
      set(secondaryCartStateAtom, secondaryCart);
    }
  },
});

export {
  cartStateSelector as cartState,
  secondaryCartStateSelector as secondaryCartState,
  formattedCartState,
};
