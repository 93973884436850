const transactionType = {
  PRE_AUTH: 1,
  SALE: 2,
  VOID: 3,
  REFUND: 4,
  SETTLE: 5,
  REPRINT_TRANS: 6,
  REPRINT_TOTAL: 7,
};

export { transactionType };
