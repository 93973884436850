import { CreateCouponMachineProps, Product } from '../types';

const getProductsFromCoupons = async ({
  couponNumber,
  coupons,
}: Partial<CreateCouponMachineProps>): Promise<Product[]> => {
  const couponsArray = coupons?.coupons ?? [];
  const [filteredCoupon] = couponsArray.filter(
    coupon => `${couponNumber}` === `${coupon.couponId}`,
  );

  return new Promise((resolve, reject) => {
    if (filteredCoupon && filteredCoupon.products && filteredCoupon.products.length) {
      resolve(filteredCoupon.products);
    }

    reject([]);
  });
};

export { getProductsFromCoupons };
