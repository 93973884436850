import loglevel from 'loglevel';

import { isObject } from 'mout/lang';
import { validate } from '@fingermarkglobal/validation';

class FallbackLogger {
  constructor() {
    this.loglevel = loglevel;

    // Show all logs in the console...
    this.loglevel.setDefaultLevel(0);

    // Catch all for non existent methods...
    // https://stackoverflow.com/questions/40961778/returning-es6-proxy-from-the-es6-class-constructor
    // https://stackoverflow.com/questions/2666602/is-there-a-way-to-catch-an-attempt-to-access-a-non-existant-property-or-method
    return new Proxy(this, {
      get(target, prop) {
        if (target[prop] === undefined)
          return () => {
            console.warn(`Attempting to call a non-existent method '${prop}' in 'FallbackLogger'!`);
          };
        return target[prop];
      },
    });
  }

  addLog({ level = null, options = [] } = {}) {
    validate({ name: 'log', parameters: { level } });

    // Determine whether or not there are 'custom' options
    const duplicate = [...options];
    const config = duplicate.pop();
    const custom = isObject(config) && config.loggerOptions;

    // Strip custom options if needed
    const messages = custom ? config : options;

    this.loglevel[level](...messages);
  }

  // Methods from loglevel...
  // https://github.com/pimterry/loglevel#documentation
  log(...args) {
    this.addLog({ level: 'log', options: args });
  }

  info(...args) {
    this.addLog({ level: 'info', options: args });
  }

  warn(...args) {
    this.addLog({ level: 'warn', options: args });
  }

  trace(...args) {
    this.addLog({ level: 'trace', options: args });
  }

  debug(...args) {
    this.addLog({ level: 'debug', options: args });
  }

  error(...args) {
    this.addLog({ level: 'error', options: args });
  }
}

export { FallbackLogger };
