import { HTTPClient } from '@fingermarkglobal/protocols';

const executeRequest = async ({
  config = {},
  payload = {},
  endpoint = '',
  method = 'POST',
} = {}) => {
  const { settings, port } = config || {};
  const { timeout } = settings || {};

  try {
    const http = new HTTPClient({ port, config: { timeout } });

    let request;

    switch (method) {
      case 'GET':
        request = {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'x-requested-with': 'XMLHttpRequest',
          },
          url: endpoint,
          method,
          timeout: timeout * 1000,
        };
        break;
      default:
        request = {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          url: endpoint,
          method,
          data: payload,
          timeout: timeout * 1000,
        };
    }

    const { data: response = {} } = await http.send({ payload: request });

    return response;
  } catch (err) {
    logger.error('cbq-ecr - executeRequest - error:', err);
    throw err;
  }
};

export { executeRequest };
