import { selector } from 'recoil';
import { orderState } from '../order';
import { beaconState } from '../beacon';
import { paymentState } from '../payment';
import { sessionState } from '../session';
import { customerState } from '../customer';
import { settingsState } from '../system';

const computedMetadata = selector({
  key: 'computedMetadata',
  get: ({ get }) => {
    const payment = get(paymentState);
    const beaconNumber = get(beaconState);
    const customerData = get(customerState);
    const { settings } = get(settingsState);
    const { payment: paymentSettings } = settings || {};
    const { currency } = paymentSettings || {};
    const { timestamp, sessionId } = get(sessionState) || {};
    const { orderId, cartType, orderNumber, voucherCode, paymentMethod } = get(orderState) || {};

    return {
      payment,
      orderId,
      currency,
      cartType,
      timestamp,
      sessionId,
      voucherCode,
      orderNumber,
      beaconNumber,
      customerData,
      paymentMethod,
    };
  },
});

export { computedMetadata };
