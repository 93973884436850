import { useCallback } from 'react';

import {
  analytics,
  getEventTrackEnabled,
  formatProduct,
  formatProductsCart,
  JITSU_LOG_TAG,
} from '../utilities';

export const useSetTrack = () => {
  const getBaseURLWithHome = useCallback(() => {
    return window.location.origin
      ? `${window.location.origin}/home`
      : 'https://localhost:4000/home';
  }, []);

  const trackEvent = useCallback((eventName, eventProperties) => {
    if (!getEventTrackEnabled()) return;
    try {
      analytics.track(eventName, eventProperties);
    } catch (error) {
      logger.error(`${JITSU_LOG_TAG} - ${eventName} error: ${error.message}`);
    }
  }, []);

  const setDineMode = useCallback(
    async dine_mode => {
      trackEvent('Dine Mode Selected', {
        dine_mode,
        context: {
          page: {
            path: '/home',
            url: getBaseURLWithHome(),
          },
        },
      });
    },
    [trackEvent, getBaseURLWithHome],
  );

  const setPage = useCallback(
    async page_info => {
      trackEvent('page', { page_info });
    },
    [trackEvent],
  );

  const setProductRemoved = useCallback(
    async (product, quantity) => {
      const formattedProduct = formatProduct(product);
      trackEvent('Product Removed', {
        ...formattedProduct,
        quantity,
      });
    },
    [trackEvent],
  );

  const setProductAddedToCart = useCallback(
    async (product, staged) => {
      if (product?.type === 'grouped') return;
      const formattedProduct = formatProduct(product);

      trackEvent('Product Added', {
        ...formattedProduct,
        quantity: staged?.count?.current,
      });
    },
    [trackEvent],
  );

  const setUpsellAddedToCart = useCallback(
    (product, main) => {
      try {
        const formattedProduct = formatProduct(product, main);

        trackEvent('Upsell Added', {
          ...formattedProduct,
          quantity: 1,
        });
      } catch (error) {
        logger.error(`${JITSU_LOG_TAG} - Error adding upsell to cart error: ${error.message}`);
      }
    },
    [trackEvent],
  );

  const setOrderViewed = useCallback(async () => {
    trackEvent('Order Viewed');
  }, [trackEvent]);

  const setOrderUpdated = useCallback(async () => {
    trackEvent('Order Updated');
  }, [trackEvent]);

  const setOrderCompleted = useCallback(async () => {
    trackEvent('Order Completed');
  }, [trackEvent]);

  const setPaymentStarted = useCallback(
    async paymentType => {
      trackEvent('Payment Started', { paymentType });
    },
    [trackEvent],
  );

  const setUpsellDismissed = useCallback(
    async products => {
      trackEvent('Upsell Dismissed', { products });
    },
    [trackEvent],
  );

  const setPaymentCompleted = useCallback(
    async (order, cart, addedUpsells) => {
      const products = formatProductsCart(cart, addedUpsells);
      const items = cart.length;
      const total = order.cart?.total || null;
      const paymentType = order?.paymentType || order.payment?.paymentType;
      const cardType = order.payment?.cardType;
      const { orderNumber, transactionId } = order;

      trackEvent('Payment Completed', {
        items,
        products,
        revenue: total,
        paymentType,
        cardType,
        orderNumber,
        transactionId,
      });
    },
    [trackEvent],
  );

  const setProductViewed = useCallback(
    async (id, product, currency) => {
      trackEvent('Product Viewed', {
        product_id: id,
        product_name: product?.name
          ? product?.name?.text
          : product?.grouped
          ? product?.grouped
              .map(item => `${item?.name?.text}  | `)
              .join(' ')
              .slice(0, -3)
          : product?.options
          ? product?.options
              .map(item => `${item?.name?.text}  | `)
              .join(' ')
              .slice(0, -3)
          : '',
        product_type: product?.type || product?.dataTag?.split('-').shift() || '',
        price: product?.price,
        currency,
      });
    },
    [trackEvent],
  );

  return {
    setDineMode,
    setProductAddedToCart,
    setProductRemoved,
    setUpsellAddedToCart,
    setOrderUpdated,
    setOrderCompleted,
    setPaymentStarted,
    setOrderViewed,
    setPaymentCompleted,
    setProductViewed,
    setUpsellDismissed,
    setPage,
  };
};
