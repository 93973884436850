import SHA1 from 'crypto-js/sha1';

const getResultMessage = () => {
  return {
    rawResponse: null,
    success: false,
    message: null,
  };
};

const isAckMessage = message => {
  if (message?.ack) return true;

  return false;
};

const isResponseMessage = message => {
  if (message?.response) return true;

  return false;
};

const parseMessage = buffer => {
  if (!buffer) throw new Error('Error while reading message');

  const toString = Buffer.from(buffer.buffer, 'hex').toString();
  const message = JSON.parse(toString);
  const result = getResultMessage();

  if (isAckMessage(message)) {
    if (message.ack.responseCode !== 0) throw new Error(message.ack.responseMessage);

    if (message.SHA1 !== SHA1(JSON.stringify(message.ack)).toString())
      throw new Error('Malformed ack message');

    return {
      ...result,
      success: true,
    };
  }

  if (isResponseMessage(message)) {
    if (message.response.responseCode !== 0) throw new Error(message.response.responseMessage);

    if (message.SHA1 !== SHA1(JSON.stringify(message.response)).toString())
      throw new Error('Malformed response message');

    return {
      ...result,
      success: true,
      rawResponse: message,
    };
  }

  throw new Error('Unknown message type');
};

export { isAckMessage, isResponseMessage, parseMessage };
