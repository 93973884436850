import { USBClient } from '@fingermarkglobal/protocols';
import { Characters } from '../enums';
import { isAcknowledged } from '../helpers';

const request = async ({ buffer = '', config = {} } = {}) => {
  logger.debug('marshal - request - start');

  const { port, settings } = config || {};
  const { timeout, pid, vid } = settings || {};
  const usbClientConfig = { pid, vid, timeout };

  const usbClient = new USBClient({ port, config: usbClientConfig });

  try {
    const { payload: payloadResponse } = await usbClient.send({
      payload: { buffer },
      bufferTimeout: 0,
    });
    const { buffer: hexResponse } = payloadResponse || {};

    if (!isAcknowledged(hexResponse)) {
      throw new Error('The message was not acknowledged by the device');
    }

    const { payload: payloadAckResponse } = await usbClient.read({ bufferTimeout: 250 });

    // Set acknowledge
    await usbClient.write({
      payload: { buffer: Characters.ACK.toString('hex') },
    });

    const { buffer: hexAckResponse } = payloadAckResponse || {};

    logger.debug(
      `marshal - request - finish ${JSON.stringify({
        hexResponse,
        hexAckResponse,
      })}`,
    );

    return hexAckResponse;
  } catch (error) {
    logger.error(`marshal - request - error: ${JSON.stringify(error)}`);
    throw new Error(error);
  } finally {
    if (usbClient?.connectionId) await usbClient.disconnect();
  }
};

export { request };
