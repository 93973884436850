import ReactGA from 'react-ga4';
import { getCustomisations } from './get-customisations';

const getProcessedProductDetails = (item, index, shadowUpsell) => {
  const isUpsell = item.id && shadowUpsell.includes(item.id);
  const productSelected = item?.productsSelected || {};
  const productSelectedCategories = productSelected?.product?.customise?.categories || [];
  const customisations = getCustomisations(productSelectedCategories, item.customisations || []);

  return {
    item_id: item.product?.id,
    item_name: item.product?.name,
    index: index + 1,
    item_category: item.categoryNameAnalytics,
    item_category2: item.product?.id,
    item_category3: isUpsell ? 'upsell' : 'normal',
    item_category4: customisations.length > 0 ? 'customised' : 'normal',
    item_variant: item.product?.type === 'combo' && item.filter ? item.filter.name.text : '',
    price: item.price,
    quantity: item.quantity,
  };
};

const registerProductPurchase = (
  { upsellState = [] },
  { currency, orderNumber = '', totalRevenue, products = [] } = {},
) => {
  return new Promise((resolve, reject) => {
    const shadowUpsell = [...upsellState].map(String);
    const items = products.map((product, idx) =>
      getProcessedProductDetails(product, idx, shadowUpsell),
    );

    try {
      logger?.info(`google-analytics - google analytics 4 - Preparing purchase event.`);
      ReactGA?.gtag('event', 'purchase', {
        currency,
        transaction_id: orderNumber,
        value: totalRevenue,
        items,
      });
      setTimeout(resolve, 2000);
    } catch (error) {
      logger?.warn(
        `google-analytics - google analytics 4 - Error on purchase event. Err: ${error}`,
      );
      reject(error);
    }
  });
};

export { registerProductPurchase };
