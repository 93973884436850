import { v4 as uuid } from 'uuid';
import {
  connect,
  send,
  disconnect,
  addListener,
  handleResponse,
} from '@fingermarkglobal/protocols';
import { validate } from '@fingermarkglobal/validation';
import { specialCharacters, isHeartbeat, parseResponse } from '../utilities';

const executeRequest = async ({ config = {}, request = [] } = {}) => {
  validate({ name: 'Global Payments Execute Request', paramaters: { request, config } });

  const identifier = uuid();

  const { port = {} } = config || {};

  const { settings } = config || {};
  const { ip, tcpPort, timeout } = settings || {};

  const formattedRequest = request.join(specialCharacters.FIELD_SEPARATOR);

  const requestBuffer = Buffer.from(formattedRequest, 'utf-8').toString('hex');

  // still thinking on how to not use this
  let heartbeatTimeout;
  let socket;

  let resolvedResponse = { status: '', rawResponse: {} };

  return new Promise((resolve, reject) => {
    const responseHandler = async tcpResponse => {
      try {
        const { status = null, payload = null } = handleResponse(tcpResponse) || {};

        if (payload) {
          if (payload.socketId) {
            socket = payload;
            await send(requestBuffer, port, socket);
          } else {
            clearTimeout(requestTimeout);

            if (!status) reject(new Error('Message status not found'));

            if (status === 'FAILED') {
              reject(new Error('Failed communication to device'));
            }

            if (isHeartbeat(payload)) {
              if (heartbeatTimeout) {
                clearTimeout(heartbeatTimeout);
              }

              heartbeatTimeout = heartBeatTimeout();

              return;
            }
            if (heartbeatTimeout) {
              clearTimeout(heartbeatTimeout);
            }

            const response = parseResponse(payload, port, socket);

            resolvedResponse = {
              status: response.status,
              rawResponse: { ...resolvedResponse.rawResponse, ...response.rawResponse },
            };

            if (response.status === 'success') {
              clearTimeout(heartbeatTimeout);
              await disconnect(port, socket, responseHandler);
              resolve(resolvedResponse);
            }
          }
        }
      } catch (error) {
        logger.error(`global-payments - executeRequest - Request error`, error);
        clearTimeout(heartbeatTimeout);
        await disconnect(port, socket, responseHandler);
        reject(new Error(error.message));
      }
    };

    const heartBeatTimeout = () => {
      return setTimeout(async () => {
        logger.error('global-payments - executeRequest - No Heartbeat received from the device');
        /* eslint no-use-before-define: 0 */
        await disconnect(port, socket, responseHandler);
        reject(new Error('No heartbeat received'));
      }, 15 * 1000);
    };

    const requestTimeout = setTimeout(async () => {
      /* eslint no-use-before-define: 0 */
      await disconnect(port, socket, responseHandler);
      reject(new Error('Payment timed out'));
    }, timeout * 1000);

    const connectAndAddListener = async () => {
      await addListener(port, responseHandler);
      await connect(port, ip, tcpPort, identifier);
    };

    connectAndAddListener();
  });
};

export { executeRequest };
