import crypto from 'crypto';
import axios from 'axios';
import { Buffer } from 'buffer';
import formatStoreId from '../utils/format-store-id';

export const getPaymentUrl = async ( {settings, order} ) => {

    const apiEndpoint = `${settings.baseUrl}/v2/gateway/api/create`;

    // Construct payload
    const payload = {
      partnerCode: settings.partnerCode,
      storeId: formatStoreId(settings.storeId),
      requestType: 'captureWallet',
      ipnUrl: 'null',
      redirectUrl: 'null',
      orderId: `${order.orderId}`,
      amount: `${order.amount}`,
      orderInfo: 'kiosk',
      requestId: `${order.requestId}`,
      extraData: Buffer.from(JSON.stringify(order.extraData)).toString("base64"),
      lang: 'en',
      autoCapture: 'true',
    };
    
    const signatureRaw = 
      "accessKey=" + settings.accessKey +
      "&amount=" + payload.amount +
      "&extraData=" + payload.extraData +
      "&ipnUrl=" + payload.ipnUrl +
      "&orderId=" + payload.orderId +
      "&orderInfo=" + payload.orderInfo +
      "&partnerCode=" + payload.partnerCode +
      "&redirectUrl=" + payload.redirectUrl +
      "&requestId=" + payload.requestId +
      "&requestType=" + payload.requestType;

    payload['signature'] = crypto.createHmac('sha256', settings.secretKey).update(signatureRaw).digest('hex');

    // Make API request
    const request = JSON.stringify(payload);

    logger.info(`[MOMO] - Sending payload to MOMO Api ${request}`);

    try {
      const response = await axios.post(apiEndpoint, request, {
          headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.data.resultCode == 0) {
        logger.info(`[MOMO] - Success response from MOMO Api ${response.data}`);

        const payUrl = response.data.qrCodeUrl;
        return { url: payUrl };  
      }

      const msg = `Momo payment, service failure: ${response.data?.message}, error code: ${response.data?.resultCode}`;
      logger.error('[MOMO] - payment-url - error: ', msg);
      return {
        error: msg
      }
    } catch (error) {
      const msg = `Momo payment, service failure: ${error.response?.data?.message}, error code: ${error.response?.data?.resultCode}`;
      logger.error('[MOMO] - payment-url - error: ', msg);
      return {
        error: msg
      }
    }
}
