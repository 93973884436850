import { validate } from '@fingermarkglobal/validation';
import { interpret } from '@fingermarkglobal/utilities';
import { createEndShiftMachine } from './machines/end-shift';
import { formatProvider } from '../shared';

const doEndShift = ({ payload = null, timeout = 30, transition = null, ip, provider: type = '' }) => {
  validate({ name: 'doEndShift', parameters: { payload } });
  const provider = formatProvider(type);
  const machine = createEndShiftMachine({ payload, timeout, ip, provider });

  const { instance: endShiftMachine } = interpret({
    name: 'endShiftMachine',
    machine,
    transition,
  });

  return { endShiftMachine };
};

export { doEndShift };
