import { validate } from '@fingermarkglobal/validation';
import { interpret } from '@fingermarkglobal/utilities';
import { createStartShiftMachine } from './machines/start-shift';
import { formatProvider } from '../shared';

const doStartShift = ({ payload = null, timeout = 30, transition = null, ip = '', provider: type = '' }) => {
  validate({ name: 'doStartShift', parameters: { payload } });
  const provider = formatProvider(type);
  const machine = createStartShiftMachine({ payload, timeout, ip, provider });

  const { instance: startShiftMachine } = interpret({
    name: 'startShiftMachine',
    machine,
    transition,
  });

  return { startShiftMachine };
};

export { doStartShift };
