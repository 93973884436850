import { check } from '@fingermarkglobal/validation';
import { ResetOrderPayload } from '@fingermarkglobal/types';
import { request, getJwtToken } from '@fingermarkglobal/request';
import { buildOrderResetEndpoint, buildRequestOptions } from '../../shared';

const resetOrder = async (
  payload: ResetOrderPayload,
  timeout: number = 30,
  ip: string = '',
  provider: string = '',
) => {
  try {
    logger.info('[fm-api][order/reset] - started');
    
    const invalid = check({
      name: 'resetOrder',
      parameters: { payload },
    });
    if (invalid) throw new Error(`[fm-api][order/reset] - failed to validate parameters [${invalid}]`);

    const { customResetOrderEndpoint: customEndpoint } = payload?.settings?.labs || {};
    const endpoint = buildOrderResetEndpoint({customEndpoint, ip, provider});

    const token = await getJwtToken();
    if (!token) throw new Error(`[fm-api][order/reset] error generating jwt token`);

    const options = buildRequestOptions({
      endpoint,
      payload,
      token,
      timeout,
    });

    const response = await request.post(endpoint, options).json();

    if (!response) throw new Error(`[fm-api][order/reset] - failed with response {${response}}`);

    return response;
  } catch (err) {
    logger.error('[fm-api][order/reset] - error', err);
    throw err;
  } finally {
    logger.info('[fm-api][order/reset] - finished');
  }
};

export { resetOrder };
