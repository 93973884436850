import React from 'react';

const Decoration = ({ className = '' } = {}) => {
  return (
    <div
      data-test="decoration"
      className={`flex flex-col items-center space-y-16 pointer-events-none ${className}`}
    >
      <div className="flex items-center justify-center w-full">
        <div className="inline-block w-24 h-12 bg-primary"></div>
        <div className="inline-block w-24 h-12 mx-24 bg-primary"></div>
        <div className="inline-block w-24 h-12 bg-primary"></div>
      </div>
    </div>
  );
};

export { Decoration };
