import { nanoid } from 'nanoid';
import { timestamp } from '@fingermarkglobal/utilities';
import { assign } from 'xstate';

const createResponse = assign((_, event) => {
  const { amount, config } = event;

  return {
    ...config,
    paymentType: 'CASH',
    info: {
      config,
      transactionId: nanoid(),
      timestamp: timestamp(),
      result: 'success',
      response: 'success',
      message: 'success',
      paymentType: 'CASH',
      authCode: nanoid(),
      amount,
    },
  };
});

export { createResponse };
