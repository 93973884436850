import { assign } from 'xstate';

const handlePayment = assign((context, event) => {
  const { request, config } = context;
  const { amount, transactionRequest } = request;
  const { transactionId } = transactionRequest;

  const { Payload } = event.data;
  const {
    response_issuername: cardType,
    response_emv_app_pan_key: cardNumber,
    response_authcode_key: authCode,
    response_tid_key: terminalId,
    response_mid_key: merchantId,
    response_aid_key: aid,
  } = Payload || {};

  // Amount is in cents
  const info = {
    transactionId,
    paymentType: 'CARD',
    terminalId,
    authCode,
    cardNumber,
    cardType,
    merchantId,
    aid,
    amount: parseFloat(amount),
    response: event.data,
  };

  logger.info('cbq-ecr - payment transaction finished');

  return {
    transactionId,
    timestamp: new Date().toISOString(),
    result: 'SUCCESS',
    config,
    info,
    message: '',
  };
});

export { handlePayment };
