import { createMachine } from 'xstate';
import { handleRefund } from '../../utilities/destructerers/refund';
import { executeRefund } from '../../utilities/request';

import { createPayload } from './actions';

const refundMachine = createMachine(
  {
    id: 'adyen-refund',
    initial: 'idle',
    context: {
      config: null,
      request: null,
      response: null,
    },
    states: {
      idle: {
        on: {
          INITIALISE: 'loading',
        },
      },
      loading: {
        entry: ['createPayload'],
        invoke: {
          id: 'adyenRequest',
          src: executeRefund,
          onDone: {
            target: 'success',
            actions: ['handleRefund'],
          },
          onError: {
            target: 'failure',
            actions: ['handleRefund'],
          },
        },
      },
      success: {
        type: 'final',
      },
      cancelled: {
        type: 'final',
      },
      failure: {
        on: {
          RETRY: {
            target: 'loading',
          },
          CANCEL: {
            target: 'cancelled',
          },
        },
      },
    },
  },
  {
    actions: {
      createPayload,
      executeRefund,
      handleRefund,
    },
  },
);

export { refundMachine };
