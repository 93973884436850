import { validate } from '@fingermarkglobal/validation';

const createRefundRequest = ({ amount = null, terminalId, transactionId, authCode }) => {
  validate({
    name: '[cbq-ecr] Refund request',
    paramaters: { amount, terminalId, transactionId, authCode },
  });

  const requestEndpoint =
    'https://supersonic-zodac-bqqvoxpupq-ew.a.run.app/https://cloud.amp360.amobilepayment.com/api/command/queue';

  const request = {
    sourceSerial: 'FM-AMP360', // this could be anything
    targetTerminal: terminalId,
    authCode,
    payload: {
      cmdType: 'Reversal',
      Endpoint: 'TRANSACTION',
      ReqPayload: {
        BaseAmount: amount.toString(),
        ReversedTransactionType: 'SALE',
        AutoPrint: 'true',
        UserDefinedEchoData: transactionId,
      },
    },
  };

  return {
    transactionId,
    request,
    requestEndpoint,
  };
};

export { createRefundRequest };
