import { FallbackLogger } from '@fingermarkglobal/logger';

export { doLoyaltyLogIn } from './loyalty/do-loyalty-login';
export { loyaltyLogin } from './loyalty/requests/loyalty-login';

export { doLoyaltyLogOut } from './loyalty/do-loyalty-logout';
export { loyaltyLogout } from './loyalty/requests/loyalty-logout';

export { doLoyaltyClaim } from './loyalty/do-loyalty-claim';
export { loyaltyClaim } from './loyalty/requests/loyalty-claim';

export { doLoyaltyGuestClaim } from './loyalty/do-loyalty-guest-claim';
export { loyaltyGuestClaim } from './loyalty/requests/loyalty-guest-claim';

export { doLoyaltyOffersAndRewards } from './loyalty/do-loyalty-offers-and-rewards';
export { loyaltyOffersAndRewards } from './loyalty/requests/loyalty-offers-and-rewards';

export { doPreOrder } from './order/do-pre-order';
export { preOrder } from './order/requests/pre-order';

export { doPlaceOrder } from './order/do-place-order';
export { placeOrder } from './order/requests/place-order';

export { doSaveOrder } from './order/do-save-order';
export { saveOrder } from './order/requests/save-order';

export { doCancelOrder } from './order/do-cancel-order';
export { cancelOrder } from './order/requests/cancel-order';

export { doResetOrder } from './order/do-reset-order';
export { resetOrder } from './order/requests/reset-order';

export { doFiscalManagement } from './fiscal/do-fiscal-management';
export { checkShift } from './fiscal/requests/check-shift';

export { doStartShift } from './fiscal/do-start-shift';
export { startShift } from './fiscal/requests/start-shift';

export { doEndShift } from './fiscal/do-end-shift';
export { endShift } from './fiscal/requests/end-shift';

export { resetCouponMachine, getCouponProducts } from './coupon';

export * from './customisation';

declare global {
  interface Window {
    logger: any;
  }
}

window.logger = window.logger || new FallbackLogger();
