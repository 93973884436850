const responseCodes = {
  '0000': 'Transaction successful',
  1001: 'Transaction cancelled',
  1002: 'Transaction timed out',
  1003: 'Invalid input',
  1005: 'Record not found',
  1006: 'Transaction not allowed',
  2001: 'Key exchange not successful',
  2002: 'Parameter download is not successful',
  2003: 'EMV download is not successful',
  2004: 'Transaction is reversed',
};

const handleResponseStatus = (data, reject) => {
  const { Payload = null } = data || {};

  if (!Payload) reject(new Error('Malformed Response'));

  const { response_resultcode_key: key = '' } = Payload || {};

  if (!key || key === null) reject(new Error('No result code found'));

  if (key !== '0000' && key !== '-2004')
    reject(
      new Error(
        `Response error [${key.length > 3 ? responseCodes[key.substr(-4)] : `Not found ${key}`}]`,
      ),
    );
};

export { handleResponseStatus };
