import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('123456789', 8);

const buildTransactionId = ({ format, values = null }) => {
  const { siteId, storeId, kioskName, orderId } = values;

  const replace = {
    siteId,
    storeId,
    kioskName,
    orderId,
    randomId: nanoid(),
  };

  // scary regex 😱
  // needed for frequent changes to transaction id formats
  // this is for future proofing those changes
  return format.replace(/siteId|storeId|kioskName|orderId|randomId/gi, key => {
    return replace[key];
  });
};

export { buildTransactionId };
