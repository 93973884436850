/** 
    @see https://www.npmjs.com/package/@jitsu/sdk-js
*/
import { useCallback, useEffect } from 'react';
import { setIdentify } from './identify';
import { useSetTrack } from './track';
import { setResetAnalytics } from './reset';
import { JITSU_LOG_TAG } from './utilities';

export const useAnalytics = ({ isFirstCall = false } = {}) => {
  useEffect(() => {
    if (isFirstCall) {
      if (process.env.POI_APP_JITSU_API_KEY) {
        logger.debug(`${JITSU_LOG_TAG} enabled`);
      } else {
        logger.error(`${JITSU_LOG_TAG} disabled. Jitsu API key is missing`);
      }
    }
  }, [isFirstCall]);

  const {
    setDineMode,
    setProductAddedToCart,
    setProductRemoved,
    setUpsellAddedToCart,
    setOrderCompleted,
    setPaymentStarted,
    setPaymentCompleted,
    setProductViewed,
    setOrderViewed,
    setOrderUpdated,
    setUpsellDismissed,
    setPage,
  } = useSetTrack();

  const setAnalyticsIdentify = useCallback(async (session_id, settings) => {
    await setIdentify(session_id, settings);
  }, []);

  const resetAnalytics = useCallback(() => {
    setResetAnalytics();
  }, []);

  return {
    setAnalyticsIdentify,
    resetAnalytics,
    dineModeTrack: setDineMode,
    productAddedToCartTrack: setProductAddedToCart,
    productRemovedTrack: setProductRemoved,
    upsellAddedToCartTrack: setUpsellAddedToCart,
    orderCompletedTrack: setOrderCompleted,
    paymentStartedTrack: setPaymentStarted,
    paymentCompletedTrack: setPaymentCompleted,
    productViewedTrack: setProductViewed,
    orderViewedTrack: setOrderViewed,
    orderUpdatedTrack: setOrderUpdated,
    upsellDismissedTrack: setUpsellDismissed,
    setPageTrack: setPage,
  };
};
