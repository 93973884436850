import ky from 'ky';

import { check } from '@fingermarkglobal/validation';
import { GetCustomisationPayload } from '@fingermarkglobal/types';
import { HTTPError, TimeoutError } from '@fingermarkglobal/request';

interface GetCustomisationParams {
  payload: GetCustomisationPayload;
}

export const getCustomisation = async ({ payload }: GetCustomisationParams) => {
  logger.info('[fm-api][customisation/get] - started request', payload);

  try {
    const invalid = check({
      name: 'customisation',
      parameters: { payload },
    });

    if (invalid) throw new Error(`failed to validate parameters [${invalid}]`);
    if (!process.env.POI_APP_CUSTOMISATION_SERVICE_ENDPOINT) {
      throw new Error(
        'failed to get endpoint in the environment file with the key POI_APP_CUSTOMISATION_SERVICE_ENDPOINT.',
      );
    }

    const endpoint = `${process.env.POI_APP_CUSTOMISATION_SERVICE_ENDPOINT}/me/${payload.serial}`;
    const response = await ky.get(endpoint, {
      timeout: 30 * 1000,
      searchParams: {
        storeId: payload.storeId,
        organisationId: payload.organisationId,
      },
    });

    try {
      const jsonResponse = await response.json();

      logger.info('[fm-api][customisation/get] - finished', response);

      return jsonResponse;
    } catch {
      return {};
    }
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;

    logger.error('[fm-api][customisation/get] - error:', err.message || err);

    throw err;
  }
};
