import { getPaymentUrl } from '../../../api/payment-url';
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('123456789', 10);

let currentMachine;
export const fetchUrl = async (_, event) => {

  const { amount, config } = event;
  let { paymentMachine } = event;
  if (paymentMachine) {
    currentMachine = paymentMachine;
  } else {
    paymentMachine = currentMachine;
  }

  const { order: { orderId } } = config;
  const order = {
    amount,
    orderId: nanoid(),
    requestId: nanoid(),
    extraData: {
      orderId: orderId
    }
  };

  const response = await getPaymentUrl({ order, settings: config.settings });

  if (response.url) {
    const sendEvent = {
      url: response.url,
      config,
      order,
      paymentMachine
    };

    currentMachine.send('URL', sendEvent)
    return sendEvent;  

  } else {
    logger.error('[MOMO] - query-status - error: ', response.error);
    currentMachine.send('FAILURE', { message: response.error });
  }
};
