import { nanoid } from 'nanoid';
import { timestamp } from '@fingermarkglobal/utilities';
import { assign } from 'xstate';

const createResponse = assign((_, event) => {

  const amount = event['amount'];
  const txnId = event['txnId'];

  return {
    paymentType: 'MOMOPAY',
    amount,
    info: {
      transactionId: txnId,
      timestamp: timestamp(),
      result: 'success',
      response: 'success',
      message: 'success',
      paymentType: 'MOMOPAY',
      authCode: nanoid(),
      amount
    },
  };
});

export { createResponse };
