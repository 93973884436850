import { validate } from '@fingermarkglobal/validation';
import { interpret } from '@fingermarkglobal/utilities';
import { createFiscalManagementMachine } from './machines/fiscal-management';
import { formatProvider } from '../shared';

const doFiscalManagement = ({
  payload = null,
  timeout = 30,
  transition = null,
  ip = '',
  provider: type = '',
}) => {
  validate({ name: 'doFiscalManagement', parameters: { payload } });
  const provider = formatProvider(type);
  const machine = createFiscalManagementMachine({ payload, timeout, ip, provider });

  const { instance: fiscalManagementMachine } = interpret({
    name: 'fiscalManagementMachine',
    machine,
    transition,
  });

  return { fiscalManagementMachine };
};

export { doFiscalManagement };
