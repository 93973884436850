const createFlushRequest = ({ terminalId, authCode }) => {
  const requestEndpoint = `https://supersonic-zodac-bqqvoxpupq-ew.a.run.app/https://cloud.amp360.amobilepayment.com/api/command/flush`;
  const request = {
    serialNumber: terminalId,
    authCode,
  };

  return {
    requestEndpoint,
    request,
  };
};

export { createFlushRequest };
