export default {
  chequeInfo: {
    address: 'Абиш Кекильбайулы, 34, офис 2-01',
    amount: '- 2 ₸',
    bin: '070440007370',
    city: 'г. Алматы',
    date: '15.02.23 19:02:51',
    method: 'qr',
    orderNumber: '3018279599',
    status: 'Возврат успешно совершен',
    storeName: 'Finger Mark',
    terminalId: '31139931',
    type: 'refund',
  },
  processId: '1676466171540',
  status: 'success',
  transactionId: '3018279599',
};
