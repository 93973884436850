import { createMachine } from 'xstate';
import { createResponse } from './actions';

const paymentMachine = createMachine(
  {
    id: 'cash-payment',
    initial: 'idle',
    context: {},
    states: {
      idle: {
        on: {
          INITIALISE: {
            target: 'success',
            actions: 'createResponse',
          },
        },
      },
      success: {
        type: 'final',
      },
    },
  },
  { actions: { createResponse } },
);

export { paymentMachine };
