import { validateLrc } from '../helpers/lrc';
import { getFieldById, Tags } from '../enums';

const parseResponse = payload => {
  let parsedPayload = payload;

  /**
   * length does not include:
   *
   * SOH    (1 byte)
   * Length (2 bytes)
   * LRC    (1 byte)
   */
  const length = parsedPayload.readInt16BE(1) + 1 + 2 + 1;

  /**
   * Trimming to the exact message size as it is expected
   * that it will come multiple times repeated to
   * "ensure the host gets the message", as per direct
   * Geidea response.
   */
  parsedPayload = parsedPayload.slice(0, length);

  validateLrc(parsedPayload);

  const message = parsedPayload.slice(6, -1).toString('hex');

  const values = message.split(Tags.SEPARATOR).map(field => {
    return Buffer.from(field, 'hex');
  });

  const response = {};

  values.forEach(temp => {
    const id = temp.slice(0, 1).toString('hex');

    if (id === '') return;

    const valueBuffer = temp.slice(2);

    const field = getFieldById(id);

    const value = field.hex ? valueBuffer.toString('hex') : valueBuffer.toString();

    response[field.name] = { id, value };
  });

  return response;
};

export { parseResponse };
