import { validate } from '@fingermarkglobal/validation';

const replace = ({ uid, handler, item, products: siblings = [] } = {}) => {
  validate({ name: 'Cart Replace', paramaters: { uid, handler, item } });

  const { uid: entityUid, customisations = [], options = [] } = item;

  // gives access to sibling products
  if (uid === entityUid) return handler(item, siblings);

  const formattedCustomisations = customisations.map(category => {
    const { products = [] } = category || {};
    return {
      ...category,
      products: products.map(product => replace({ uid, handler, item: product, products })),
    };
  });

  const formattedOptions = options.map(option => {
    const { products = [] } = option || {};
    return {
      ...option,
      products: products.map(product => replace({ uid, handler, item: product, products })),
    };
  });

  return {
    ...item,
    options: formattedOptions,
    customisations: formattedCustomisations,
  };
};

export { replace };
