import { createDecipheriv } from 'crypto';
import { xor } from './xor';

export const decrypt = ({ initVector, cipherKey } = {}, nonce, data) => {
  const algorithm = 'aes-256-cbc';
  const finalInitVector = xor(initVector, nonce);
  const decipher = createDecipheriv(algorithm, cipherKey, finalInitVector);

  const decoded = decipher.update(data);

  const list = [decoded, decipher.final()];
  const decrypted = Buffer.concat(list);

  return JSON.parse(decrypted.toString());
};
