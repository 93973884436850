import { pbkdf2Sync } from 'crypto';
import { NEXO_CIPHER_KEY_LENGTH, NEXO_HMAC_KEY_LENGTH, NEXO_IV_LENGTH } from './enums';

export const derive = secret => {
  const pass = Buffer.from(secret, 'binary');
  const salt = Buffer.from('AdyenNexoV1Salt', 'binary');
  const keylen = NEXO_HMAC_KEY_LENGTH + NEXO_CIPHER_KEY_LENGTH + NEXO_IV_LENGTH;
  const iterations = 4000;

  const key = pbkdf2Sync(pass, salt, iterations, keylen, 'sha1');

  const hmacKey = key.slice(0, 32);
  const cipherKey = key.slice(32, 64);
  const initVector = key.slice(64, 80);

  return {
    key,
    hmacKey,
    cipherKey,
    initVector,
  };
};
