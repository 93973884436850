export default {
  processId: '1579245612135',
  transactionId: '9064466741',
  addInfo: {
    LoanOfferName: '',
    IsOffer: false,
    LoanTerm: 0,
    ProductType: 'Gold',
  },
  chequeInfo: {
    address: 'yn. Maynehoba, 120',
    amount: '10 T',
    bin: '86100230099',
    city: 'z.Anmambi',
    date: '01.02.22 12:01:45',
    method: 'qr',
    orderNumber: '9064466741',
    status: 'Nokynka c Kaspi Gold. Ooodpeho',
    storeName: 'Tecmobblu',
    terminalId: '990171299',
    type: 'payment',
  },
};
