import { assign } from 'xstate';

const handlePayment = assign((context, event) => {
  const { request, config } = context;
  const { amount } = request;
  const { rawResponse, transactionId, cardNumber, cardType, paymentReceipt, authCode } = event.data;

  // Amount is in cents
  const info = {
    paymentType: 'CARD',
    transactionId,
    authCode,
    cardNumber,
    cardType,
    amount: parseFloat(amount),
    response: rawResponse,
    paymentReceipt,
  };

  logger.info('verifone - EFT transaction finished');

  return {
    transactionId,
    timestamp: new Date().toISOString(),
    result: 'SUCCESS',
    config,
    request,
    info,
    message: '',
  };
});

export { handlePayment };
