const fields = {
  primaryAccountNumber: {
    id: '02',
    name: 'primaryAccountNumber',
    description: 'Primary Account Number',
  },
  transactionAmount: {
    id: '04',
    name: 'transactionAmount',
    description: 'Transaction Amount',
    hex: true,
  },
  transactionStan: {
    id: '0b',
    name: 'transactionStan',
    description: 'Transaction STAN or Trace Number',
  },
  transactionDateTime: {
    id: '0c',
    name: 'transactionDateTime',
    description: 'Transaction Date and Time',
  },
  cardExpiryDate: { id: '0e', name: 'cardExpiryDate', description: 'Card Expiry Date', hex: true },
  retrievalReferenceNumber: {
    id: '25',
    name: 'retrievalReferenceNumber',
    description: 'Retrieval Reference Number',
  },
  transactionAuthCode: {
    id: '26',
    name: 'transactionAuthCode',
    description: 'Transaction AuthCode',
  },
  transactionResponse: {
    id: '27',
    name: 'transactionResponse',
    description: 'Transaction Response',
  },
  cardAcceptorTerminalId: {
    id: '29',
    name: 'cardAcceptorTerminalId',
    description: 'Card Acceptor Terminal Id',
  },
  cardAcceptorName: { id: '82', name: 'cardAcceptorName', description: 'Card Acceptor Name' },
  cardAcceptorIdCode: {
    id: '2a',
    name: 'cardAcceptorIdCode',
    description: 'Card Acceptor Id Code',
  },
  additionalAmount: {
    id: '36',
    name: 'additionalAmount',
    description: 'Additional Amount',
    hex: true,
  },
  cardAcceptorName2: { id: '87', name: 'cardAcceptorName2', description: 'Card Acceptor Name 2' },
  cardAcceptorBusinessCode: {
    id: '88',
    name: 'cardAcceptorBusinessCode',
    description: 'Card Acceptor Business Code',
  },
  dateTime: { id: '89', name: 'dateTime', description: 'Date Time' },
  emvTagsIssuer: { id: '90', name: 'emvTagsIssuer', description: 'EMV Tags Issuer' },
  englishCampaignMessage: {
    id: '7f',
    name: 'englishCampaignMessage',
    description: 'English Campaign Message',
  },
  arabicCampaignMessage: {
    id: '80',
    name: 'arabicCampaignMessage',
    description: 'Arabic Campaign Message',
  },
  barcodeData: { id: '7e', name: 'barcodeData', description: 'Barcode Data' },
  transactionType: { id: '30', name: 'transactionType', description: 'Transaction Type' },
  merchantInfo: { id: '91', name: 'merchantInfo', description: 'Merchant Info' },
  responseCodeDescription: {
    id: '28',
    name: 'responseCodeDescription',
    description: 'Response code Description',
  },
  ecrReferenceNumber: { id: '81', name: 'ecrReferenceNumber', description: 'ECR Reference Number' },
};

const fieldsMap = {
  '02': fields.primaryAccountNumber,
  '04': fields.transactionAmount,
  '0b': fields.transactionStan,
  '0c': fields.transactionDateTime,
  '0e': fields.cardExpiryDate,
  25: fields.retrievalReferenceNumber,
  26: fields.transactionAuthCode,
  27: fields.transactionResponse,
  29: fields.cardAcceptorTerminalId,
  82: fields.cardAcceptorName,
  '2a': fields.cardAcceptorIdCode,
  36: fields.additionalAmount,
  87: fields.cardAcceptorName2,
  88: fields.cardAcceptorBusinessCode,
  89: fields.dateTime,
  90: fields.emvTagsIssuer,
  '7f': fields.englishCampaignMessage,
  80: fields.arabicCampaignMessage,
  '7e': fields.barcodeData,
  30: fields.transactionType,
  91: fields.merchantInfo,
  28: fields.responseCodeDescription,
  81: fields.ecrReferenceNumber,
};

function getFieldById(id) {
  const field = fieldsMap[id];

  if (!field) {
    return { id, name: `unknownField-${id}`, description: `Unknown field ${id}` };
  }

  return field;
}

export { fields, fieldsMap, getFieldById };
