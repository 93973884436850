const parseStatus = payload => {
  const statusArray = [];

  for (let i = 0; i < payload.length; i += 6) {
    const messageCodeOffset = i + 4;

    const messageCode = payload.slice(messageCodeOffset, messageCodeOffset + 1).toString('hex');

    statusArray.push(messageCode);
  }

  return statusArray;
};

export { parseStatus };
