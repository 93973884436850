import { createMachine, assign, EventObject } from 'xstate';
import { CreateCouponMachineProps } from '../types';
import { getCouponProductsService } from '../services';

const setConfig = assign((_, { couponNumber, coupons, settings }: CreateCouponMachineProps) => ({
  couponNumber,
  coupons,
  settings,
}));

const setError = assign({
  error: (_, event: EventObject) => event,
});

const setResponse = assign({
  response: (context, event: EventObject) => event,
});

type CreateCouponMachineContext = CreateCouponMachineProps & {
  error: any;
};

const couponMachine = createMachine<CreateCouponMachineContext>(
  {
    id: 'coupon',
    initial: 'idle',
    context: {
      error: null,
      couponNumber: null,
      coupons: null,
      settings: null,
    },
    states: {
      idle: {
        on: {
          GET: {
            target: 'getting',
            actions: ['setConfig'],
          },
        },
      },
      getting: {
        invoke: {
          id: 'gettingProducts',
          src: ({ couponNumber, coupons, settings }) =>
            getCouponProductsService({ couponNumber, coupons, settings }),

          onDone: {
            target: 'success',
            actions: ['setResponse'],
          },
          onError: {
            target: 'error',
            actions: ['setError'],
          },
        },
      },
      success: { type: 'final', on: { RESET: 'idle' } },
      error: { type: 'final', on: { RESET: 'idle' } },
    },
  },
  {
    actions: {
      setConfig,
      setError,
      setResponse,
    },
  },
);

export { couponMachine };
