import { validate } from '@fingermarkglobal/validation';

const sendSMS = ({ countryCode, phoneNumber, message }) => {
  try {
    validate({
      name: 'sendSMS',
      parameters: { countryCode, phoneNumber, message },
    });

    if (countryCode === '100' && phoneNumber === '100000000')
      return new Promise((resolve, reject) =>
        setTimeout(() => reject('smsMockErrorMessage'), 1000),
      );

    return new Promise(resolve => setTimeout(() => resolve('smsMockSuccessMessage'), 1000));
  } catch (error) {
    throw new Error(`[sendSMS] Fail to handle request: ${error}`);
  }
};

export { sendSMS };
