import ReactGA from 'react-ga4';

const registerProductCheckout = ({
  sessionId,
  productVariant,
  categoryName,
  product,
  price,
  quantity,
  listName,
  storeAddress,
  organisation,
  serial,
  upsell,
}) => {
  return new Promise((resolve, reject) => {
    const details = {
      index: 1,
      sessionId,
      storeAddress,
      serial,
      listName,
      productVariant,
      organisation,
      categoryName,
      productName: product.name,
      productSku: product.id,
      upsell,
      quantity,
      price,
    };

    try {
      logger?.info(`google-analytics - google analytics 4 - Preparing checkout event.`);
      ReactGA?.event('checkout', {
        event_category: 'checkout',
        event_label: 'checkout',
        ...details,
      });
      setTimeout(resolve, 2000);
    } catch (error) {
      logger?.warn(
        `google-analytics - google analytics 4 - Error on checkout event. Err: ${error}`,
      );
      reject(error);
    }
  });
};

export { registerProductCheckout };
