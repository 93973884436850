/** 
    @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset
*/

import { analytics, getEventTrackEnabled, JITSU_LOG_TAG } from '../utilities';

export const setResetAnalytics = () => {
  if (!getEventTrackEnabled()) return;
  const eventName = 'Reset';
  logger.info(`${JITSU_LOG_TAG} type: track, name: ${eventName}`);
  analytics.track(eventName);
};
