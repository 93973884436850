import { CreateCouponMachineProps, Product } from '../types';
import { fetchProductsFromCoupon } from './fetch-products-from-coupon';
import { getProductsFromCoupons } from './get-products-from-coupons';

const getCouponProductsService = async ({
  couponNumber,
  coupons,
  settings,
}: CreateCouponMachineProps): Promise<Product[]> => {
  const { checkCouponsByApi, type, headers } = settings?.settings?.order || {};

  if (!checkCouponsByApi) {
    return getProductsFromCoupons({ couponNumber, coupons });
  }

  return fetchProductsFromCoupon({
    couponNumber,
    provider: settings?.settings?.order?.[type],
    type,
    headers,
  });
};

export { getCouponProductsService };
