import { assign, sendParent } from "xstate"
import { SettingsActionEvent } from '@fingermarkglobal/types';

const setSettingsAction = assign((_, event: { data: SettingsActionEvent }) => ({
    count: event?.data?.count,
    settings: event?.data?.settings
}));

const sendParentFailureAction = sendParent(() => ({
    type: 'SETTINGS_MACHINE_FAILURE',
}))

const sendParentSuccessAction = sendParent((context: SettingsActionEvent) => ({
    type: 'SETTINGS_MACHINE_SUCCESS',
    count: context?.count,
    settings: context?.settings
}));

export {
    setSettingsAction,
    sendParentFailureAction,
    sendParentSuccessAction
}