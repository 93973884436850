import { executeRequest } from '../../../utilities/request';
import { buildRequest, buildEndpoint, getStatus } from '../../../utils';

const REQUEST_NAME = 'payment';

const launchPayment = async context => {
  logger.info(`kaspi-pay - launchPayment - context: [${JSON.stringify(context)}]`);

  const { request = {}, config = {} } = context || {};

  const { settings } = config;
  const { pinpadIp, port } = settings;

  const { amount, ownCheque } = request;

  try {
    const queryParams = {
      amount,
      ownCheque,
    };

    const newEndpoint = `${buildEndpoint(pinpadIp, port)}${REQUEST_NAME}`;

    const requestUrl = buildRequest(newEndpoint, queryParams);

    const launchPaymentResponse = await executeRequest({
      config,
      endpoint: requestUrl,
      method: 'GET',
    });

    logger.info(`kaspi-pay - launchPayment - response: `, JSON.stringify(launchPaymentResponse));

    const { data } = launchPaymentResponse;
    const { processId } = data;

    if (!processId) {
      throw new Error('processId can not be undefined.');
    }

    const statusContext = { config, processId };

    const statusResponse = await getStatus(statusContext);

    logger.info(`kaspi-pay - launchPayment - STATUS RES:`, JSON.stringify(statusResponse));

    return statusResponse;
  } catch (err) {
    logger.error('kaspi-pay - launchPayment - error:', err);
    throw err;
  }
};

export { launchPayment };
