import { request, parseMessage } from '../../../utilities';

const executeRefund = async context => {
  logger.info(`marshal - executeRefund - context [${JSON.stringify(context)}]`);

  const { buffer = '', config = {} } = context || {};

  try {
    const response = await request({ buffer, config });

    const fields = parseMessage(response);

    const { responseCode = '', responseMessage = '' } = fields || {};

    if (responseCode !== '000' && responseCode !== '00') {
      throw new Error(responseMessage);
    }

    return { fields };
  } catch (err) {
    logger.error('marshal - executeRefund - Error:', err);
    throw err;
  }
};

export { executeRefund };
