import { Enums } from '../enums';

export const buildSettlementRequest = payload => {
  const { tid } = payload || {};
  const tag = Enums.messageType.sett;

  const payloadString = `${tag},${tid}`;
  const buf = Buffer.from(payloadString);
  const hex = buf.toString('hex');

  return hex;
};
