/**
 * Creates a LRC checksum based off the data received.
 * @param {Buffer} data - Buffer for the LRC to XOR.
 */
export const lrcBuilder = data => {
  if (!Buffer.isBuffer(data)) throw new Error('Payload for LRC check is NOT a buffer.');
  const s = data.toString('hex').match(/../g);
  let lrc = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const hexbyte of s) {
    const n = 1 * `0x${hexbyte}`;
    lrc ^= n;
  }

  lrc = lrc.toString(16);
  if (lrc.length % 2) lrc = `0${lrc}`;

  return lrc;
};
