import {
  retry,
  timeout,
  request,
  hawkeEndpoint,
  HTTPError,
  TimeoutError,
  getJwtToken,
} from '@fingermarkglobal/request';
import { RestaurantMenuCount, ChromeSerialSettings } from '@fingermarkglobal/types';
import { isLocalUrl } from '../utils/is-local-url';

const getProductsRequest = async (count: RestaurantMenuCount, settings: ChromeSerialSettings) => {
  const organisationId = process.env.POI_APP_ORGANISATION_ID || null;

  const { numberOfProductsPage: pages } = count;

  const { menuId = 0 } = settings || {};
  const { storeId: id } = settings.settings.datasource;
  const { customRestaurantEndpoint = null } = settings.settings.labs || {};

  const endpoint = customRestaurantEndpoint || hawkeEndpoint;

  try {
    const token = await getJwtToken();

    const requests = [];

    // This fixes some tests but breaks other things
    // return [];

    for (let page = 1; page <= pages; page++) {
      requests.push(
        request
          .get(`${endpoint}/menu/products/${organisationId}/${id}/page/${page}/?currentMenuId=${menuId}`, {
            retry,
            timeout,
            headers: {
              Authorization: `Bearer ${token}`,
              'x-api-apex-logging-project': process.env.POI_APP_LOGGER_PROJECT,
            },
            ...(isLocalUrl(endpoint) && { prefixUrl: '' }),
          })
          .json(),
      );
    }

    const response = await Promise.all(requests);

    const products = response.flat(1);

    return products;
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;
    logger.error('restaurant - getProductsRequest - error:', err);
    throw err;
  }
};

export { getProductsRequest };
