import { get, set } from '@fingermarkglobal/cache';

const validateSerial = (serial: string): boolean => serial !== null;

const getSerial = get(validateSerial);
const setSerial = set(validateSerial);

export {
    getSerial,
    setSerial
}