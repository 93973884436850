import { v4 as uuid } from 'uuid';
import { validate } from '@fingermarkglobal/validation';
import { handleResponse } from '@fingermarkglobal/protocols';

const executePayment = async ({ config = {}, request = {} } = {}) => {
  validate({ name: 'Verifone Execute Payment', paramaters: { request, config } });

  const identifier = uuid();

  const { settings } = config || {};
  const { pinpadIp: ip, port: tcpPort, timeout: paymentTimeout = 60 } = settings || {};
  const { sessionId, port, retry } = config || {};

  const { amountInCents, transactionId, previousTransactionId } = request || {};

  return new Promise((resolve, reject) => {
    const onResponse = message => {
      handleResponse(message); // Logs extension logs into the browser.

      if (message.id === identifier) {
        port.onMessage.removeListener(onResponse);

        const { status, payload } = message;
        if (status === 'SUCCESS') {
          resolve(payload);
        } else {
          const transactionError = new Error(payload.error);
          transactionError.transaction = payload;
          reject(transactionError);
        }
      }
    };
    port.onMessage.addListener(onResponse);
    const message = {
      topic: 'request/verifone',
      id: identifier,
      payload: {
        socket: {
          ip,
          port: tcpPort,
          timeout: paymentTimeout,
        },
        amountInCents,
        transactionId,
        previousTransactionId,
        retrying: retry,
        sessionId,
      },
    };
    port.postMessage(message);
  });
};

export { executePayment };
