import { createResetOrderMachine } from './machines/reset-order';
import { validate } from '@fingermarkglobal/validation';
import { interpret } from '@fingermarkglobal/utilities';
import { formatProvider } from '../shared';

const doResetOrder = ({ payload = null, timeout = 30, transition = null, ip, provider:type = ''}) => {
  validate({ name: 'doResetOrder', parameters: { payload } });

  const provider = formatProvider(type);
  const machine = createResetOrderMachine({ payload, timeout, ip, provider });

  const { instance: resetOrderMachine } = interpret({
    name: 'resetOrderMachine',
    machine,
    transition,
  });

  return { resetOrderMachine };
};

export { doResetOrder };
