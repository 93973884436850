const auth = [
  { field: 'tag', description: 'auth' },
  { field: 'responseCode', description: { '000': 'Success' } },
  { field: 'responseMessage', description: 'nil' },
  { field: 'transactionType', description: { 1: 'Sale' } },
  { field: 'ecrRcptNum', description: 'nil' },
  { field: 'amount', description: 'nil' },
  { field: 'cardNumber', description: 'nil' },
  { field: 'expiry', description: 'YYMM' },
  { field: 'cardHolderName', description: 'nil' },
  { field: 'messNumber', description: 'nil' },
  { field: 'cardSchemeName', description: 'nil' },
  {
    field: 'transactionSource',
    description: {
      1: 'Manual Entry of Card Number',
      2: 'Card Swipe (MagStripe)',
      3: 'Transaction Processed by ICC',
      4: 'Fallback Swipe',
    },
  },
  {
    field: 'authMode',
    description: {
      1: 'Online',
      2: 'Offline',
    },
  },
  {
    field: 'cardHolderVerification',
    description: {
      1: 'Signature',
      2: 'PIN',
      3: 'Offline PIN',
    },
  },
  { field: 'approvalCode', description: 'nil' },
  { field: 'datetime', description: 'YYYYMMDDHHmmss' },
  { field: 'emvLabel', description: 'nil' },
  { field: 'emvAid', description: 'nil' },
  { field: 'emvTvr', description: 'nil' },
  { field: 'emvTsi', description: 'nil' },
  { field: 'emvAc', description: 'nil' },
  { field: 'tid', description: 'Terminal ID' },
  { field: 'batch', description: 'nil' },
  { field: 'mid', description: 'Merchant ID' },
  { field: 'rrn', description: 'Retrieval Reference Number' },
  { field: 'cid', description: 'Cryptogram Information Data' },
];

const voidRes = [
  { field: 'tag', description: 'void' },
  { field: 'responseCode', description: { '000': 'Success' } },
  { field: 'responseMessage', description: 'nil' },
  { field: 'ecrRcptNum', description: 'nil' },
  { field: 'amount', description: 'nil' },
  { field: 'cardNumber', description: 'nil' },
  { field: 'cardHolderName', description: 'nil' },
  { field: 'expiry', description: 'YYMM' },
  { field: 'messNumber', description: 'nil' },
  { field: 'cardSchemeName', description: 'nil' },
  {
    field: 'transactionSource',
    description: {
      1: 'Manual Entry of Card Number',
      2: 'Card Swipe (MagStripe)',
      3: 'Transaction Processed by ICC',
      4: 'Fallback Swipe',
    },
  },
  {
    field: 'authMode',
    description: {
      1: 'Online',
      3: 'Offline Terminal for Approval Code below Floor Limit',
      5: 'Offline',
    },
  },
  {
    field: 'cardHolderVerification',
    description: {
      1: 'Signature',
      2: 'PIN',
      3: 'Offline PIN',
    },
  },
  { field: 'approvalCode', description: 'nil' },
  { field: 'datetime', description: 'YYYYMMDDHHmmss' },
  { field: 'emvLabel', description: 'nil' },
  { field: 'emvAid', description: 'nil' },
  { field: 'emvTvr', description: 'nil' },
  { field: 'emvTsi', description: 'nil' },
  { field: 'emvAc', description: 'nil' },
  { field: 'tid', description: 'Terminal ID' },
  { field: 'batch', description: 'nil' },
  { field: 'mid', description: 'Merchant ID' },
  { field: 'rrn', description: 'Retrieval Reference Number' },
  { field: 'cid', description: 'Cryptogram Information Data' },
];

const settlement = [
  { field: 'tag', description: 'settlement' },
  { field: 'responseCode', description: { '000': 'Success' } },
  { field: 'responseMessage', description: 'nil' },
  { field: 'DBCount', description: 'nil' },
  { field: 'CRCount', description: 'nil' },
  { field: 'DBAmount', description: 'nil' },
  { field: 'CRAmount', description: 'nil' },
  { field: 'tid', description: 'Terminal ID' },
  { field: 'batch', description: 'nil' },
  { field: 'mid', description: 'Merchant ID' },
  { field: 'rrn', description: 'Retrieval Reference Number' },
];

export { auth, voidRes, settlement };
