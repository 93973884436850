import { Enums } from '../enums';

export const parseAuth = message => {
  const hexMessage = message.toString('hex');
  const fields = hexMessage.split('2c').map(field => {
    return Buffer.from(field, 'hex').toString();
  });

  if (fields[0] !== '02') {
    throw new Error('Auth tag is not 02, wrong tag or out of order fields.');
  }

  const response = {};

  fields.forEach((value, i) => {
    const Enum = Enums.responses.auth[i];
    if (!Enum) {
      response.unknown = value;
      return;
    }
    const { field } = Enum;
    logger.debug(`marshal - parseAuth - parsing field [${field}] with value [${value}]`);
    response[field] = value;
  });

  return response;
};
