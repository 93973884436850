import { createMachine, assign } from 'xstate';
import { loyaltyLogin } from '../requests/loyalty-login';
import { LoyaltyLoginPayload } from '@fingermarkglobal/types';

const populateMessage = assign({
  data: (_, payload: any) => {
    const message = payload?.message;
    const error = message?.message;
    const data = payload?.data;

    if (data) {
      logger.debug(`api - loyaltyLogin - FSM data recieved:`, data);
      return data
    }

    if (error) {
      logger.error(`api - loyaltyLogin - FSM error recieved:`, error);
      return error
    }

    logger.error(`api - loyaltyLogin - FSM message recieved:`, message);
    return message;
  },
});

const createLoyaltyLogInMachine = ({ payload = null } = {}) =>
  createMachine<{
    data: unknown;
    payload: null | LoyaltyLoginPayload;
  }>(
    {
      id: 'loyaltyLogin',
      initial: 'processing',
      context: {
        payload,
        data: null,
      },
      states: {
        processing: {
          invoke: {
            src: ({ payload }) => loyaltyLogin({ payload }),
            onDone: {
              target: 'success',
              actions: ['populateMessage']
            },
            onError: {
              target: 'error',
              actions: ['populateMessage']
            }
          }
        },
        success: {
          on: {
            IDLE: 'processing'
          }
        },
        error: { type: 'final' }
      }
    },
    {
      actions: {
        populateMessage
      }
    }
  );

export { createLoyaltyLogInMachine };
