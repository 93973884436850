const launchRefund = async context => {
  logger.info(`kaspi-pay - launchRefund - context: [${JSON.stringify(context)}]`);

  return new Promise((resolve, reject) => {
    const data = {
      response: {},
      result: 'notallowed',
      errorCondition: 'This pinpad does not allow refunds',
    };

    reject(data);
  });
};

export { launchRefund };
