import { sender } from '@fingermarkglobal/chrome-messages';

class USBClient {
  constructor({
    port = null,
    config = { timeout: 30, pid: null, vid: null, usbPort: null, baudRate: 9600 },
  } = {}) {
    logger.debug(
      `protocols - usb - creating USBClient instance with params [${JSON.stringify({
        port,
        config,
      })}]`,
    );

    const { vid, pid, timeout, usbPort, baudRate = 9600 } = config;
    this.post = sender({ port, config });
    this.connectionId = null;
    this.vid = vid;
    this.pid = pid;
    this.timeout = timeout * 1000;
    this.usbPort = usbPort;
    this.baudRate = baudRate;

    logger.debug(`protocols - usb - USBClient instance created`);
  }

  async connect() {
    const request = {
      type: 'usb',
      method: 'connect',
      payload: {
        timeout: this.timeout,
        vid: this.vid,
        pid: this.pid,
        port: this.usbPort,
        baudRate: this.baudRate,
      },
    };

    logger.debug(`protocols - usb - connect start [${JSON.stringify({ request })}]`);

    try {
      const { payload } = await this.post(request);
      const { connectionId } = payload || {};

      this.connectionId = connectionId;

      logger.debug(`protocols - usb - connect finish [${JSON.stringify({ connectionId })}]`);
    } catch (err) {
      logger.error('protocols - usb - Error in connect:', err);
      throw err;
    }
  }

  async disconnect() {
    const request = {
      type: 'usb',
      method: 'disconnect',
    };
    logger.debug(`protocols - usb - disconnect start [${JSON.stringify({ request })}]`);

    try {
      await this.post(request);

      logger.debug(`protocols - usb - disconnect finish`);
    } catch (err) {
      logger.error('protocols - usb - Error in disconnect:', err);
      throw err;
    }
  }

  async write({ payload = {} }) {
    if (!this.connectionId) throw new Error('No USB connection');

    const request = {
      type: 'usb',
      method: 'write',
      payload: {
        connectionId: this.connectionId,
        timeout: this.timeout,
        ...payload,
      },
    };

    logger.info(`protocols - usb - write start [${JSON.stringify({ request })}]`);

    try {
      await this.post(request);

      logger.debug(`protocols - usb - write finish`);
    } catch (err) {
      logger.error('protocols - usb - error in write:', err);
      throw err;
    }
  }

  async read({ bufferTimeout } = {}) {
    if (!this.connectionId) throw new Error('No USB connection');

    const request = {
      type: 'usb',
      method: 'read',
      payload: {
        timeout: this.timeout,
        connectionId: this.connectionId,
        bufferTimeout,
      },
    };

    logger.debug(`protocols - usb - read start [${JSON.stringify({ request })}]`);

    try {
      const response = await this.post(request);

      logger.info(`protocols - usb - read finish [${JSON.stringify({ response })}]`);

      return response;
    } catch (err) {
      logger.error('protocols - usb - error in read:', err);
      throw err;
    }
  }

  async send({ payload = null, bufferTimeout } = {}) {
    logger.debug(
      `protocols - usb - send start [${JSON.stringify({
        payload,
        bufferTimeout,
      })}]`,
    );

    try {
      if (!this.connectionId) await this.connect();

      await this.write({ payload });

      const response = await this.read({ bufferTimeout });

      logger.debug(`protocols - usb - send finish [${JSON.stringify({ response })}]`);

      return response;
    } catch (err) {
      logger.error('protocols - usb - Error in send:', err);
      throw err;
    }
  }
}

export { USBClient };
