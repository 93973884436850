import { interpret } from '@fingermarkglobal/utilities';
import { createLoyaltyClaimMachine } from './machines/loyalty-claim';

const doLoyaltyClaim = ({ transition = null, token, transactionId, cart }) => {
  const machine = createLoyaltyClaimMachine({ token, transactionId, cart });

  const { instance: loyaltyClaimMachine } = interpret({
    name: 'loyaltyClaimMachine',
    machine,
    transition,
  });

  return { loyaltyClaimMachine };
};

export { doLoyaltyClaim };
