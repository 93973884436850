import ky from 'ky';
import { FallbackLogger } from '@fingermarkglobal/logger';

window.logger = window.logger || new FallbackLogger();

const { HTTPError, TimeoutError } = ky;

const hawkeEndpoint =
  process.env.POI_APP_HAWKE_LOCAL_ENDPOINT || process.env.POI_APP_HAWKE_ENDPOINT;
const clientTimeout = process.env.POI_APP_REQUEST_TIMEOUT;
const clientRetries = process.env.POI_APP_REQUEST_RETRIES;
const corsProxy = process.env.POI_APP_CORS_PROXY;

const timeout = clientTimeout || 30 * 1000; // 30 Seconds

const retry = {
  limit: clientRetries || 3,
};

const request = ky.extend({
  prefixUrl: corsProxy,
  hooks: {
    afterResponse: [
      async (process, options, response) => {
        const { timeout: expiry, json: payload } = options;
        const { url, method, credentials, headers } = process;
        const formattedHeaders = headers && headers.values() ? Array.from(headers.values()) : [];

        if (!response.ok) {
          logger.error(
            `request - request to: '${url}' responded with:`,
            await response.text(),
            'request options:',
            {
              expiry,
              payload,
              credentials,
              method,
              headers: formattedHeaders,
            },
          );

          return response;
        }

        logger.debug(
          `request - request to: '${url}' responded with:`,
          response,
          'request options:',
          {
            expiry,
            payload,
            credentials,
            method,
            headers: formattedHeaders,
          },
        );
        return response;
      },
    ],
  },
});

export { retry, timeout, request, hawkeEndpoint, HTTPError, TimeoutError };
