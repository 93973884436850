import { request, getJwtToken } from '@fingermarkglobal/request';
import { WebkassaUserDetails } from '@fingermarkglobal/types';
import { check } from '@fingermarkglobal/validation';
import { buildStartShiftEndpoint, buildRequestOptions } from '../../shared';

const startShift = async (
  // TODO review what is the correct type for the payload
  payload: WebkassaUserDetails,
  timeout: number = 30,
  ip: string = '',
  provider: string = '',
) => {
  try {
    logger.info('[fm-api][shift/start] - started');

    const invalid = check({
      name: 'startShift',
      parameters: { payload },
    });

    if (invalid) throw new Error(`[fm-api][shift/start] - failed to validate parameters [${invalid}]`);
  
    const token = await getJwtToken();
    if (!token) throw new Error(`[fm-api][shift/start] error generating jwt token`);

    const endpoint = buildStartShiftEndpoint({ip, provider});

    const options = buildRequestOptions({
      endpoint,
      payload,
      token,
      timeout,
    });

    const response = await request.post(endpoint, options).json();
    if (!response) throw new Error(`[fm-api][shift/start] request failed with response {${response}}`);

    return response;
  } catch (err) {
    logger.error('[fm-api][shift/start] - error:', err);
    throw err;
  } finally {
    logger.info('[fm-api][shift/start] - finished');
  }
};

export { startShift };
