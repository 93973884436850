const formatValidatedValues = ({
  item,
  price,
  isAvailable,
  unitPrice,
  customisations,
  options,
}) => ({
  ...item,
  customisations: customisations || item.customisations,
  options: options || item.options,
  price: { ...item.price, current: price, unit: unitPrice },
  isAvailable,
  validated: true,
});

export { formatValidatedValues };
