import { HTTPError, TimeoutError } from '@fingermarkglobal/request';
import ky from 'ky';

const loyaltyLogout = async ({ token }: { token: string }) => {
  console.info('fm-api - loyaltyLogout - started');

  try {
    const endpoint = `${process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT}/loyalty/logout`;
    if (!process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT) {
      throw new Error(
        'fm-api - loyaltyLogout - failed to get loyalty logout endpoint in the environment file with the key POI_APP_LOYALTY_SERVICE_ENDPOINT.',
      );
    }

    const response = await ky.post(endpoint, {
      timeout: 30 * 1000,
      headers: { 'x-authorization-loyalty': `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error('fm-api - loyaltyLogout - request failed. No response received');
    }

    console.info('fm-api - loyaltyLogout - finished', response);

    return response;
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;

    console.error('fm-api - loyaltyLogout - error:', err);
    throw err;
  }
};

export { loyaltyLogout };
