import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";

const timestamp = () => {
  return format(new Date(Date.now()), FORMAT);
};

const getFormattedDateTime = ({ currentTimestamp = new Date(), timezone = null }) => {
  const formattedTimestamp = format(parseISO(currentTimestamp), FORMAT);
  const currentTimezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  return format(utcToZonedTime(formattedTimestamp, currentTimezone), 'dd/MM/yyyy hh:mm aa');
};

export { timestamp, getFormattedDateTime, FORMAT };
