import * as crypto from 'crypto';
import axios from 'axios';

export const queryStatus = async ({settings, order}) => {

    const apiEndpoint = `${settings.baseUrl}/v2/gateway/api/query`;

    // Construct payload
    const payload = {
      partnerCode: settings.partnerCode,
      orderId: `${order.orderId}`,
      requestId: `${order.requestId}`,
      lang: 'en',
      autoCapture: 'true',
    };
    
    const signatureRaw = 
      "accessKey=" + settings.accessKey +
      "&orderId=" + payload.orderId +
      "&partnerCode=" + payload.partnerCode +
      "&requestId=" + payload.requestId;

    payload['signature'] = crypto.createHmac('sha256', settings.secretKey).update(signatureRaw).digest('hex');

    // Make API request
    const request = JSON.stringify(payload);    
    try {
      const response = await axios.post(apiEndpoint, request, {
          headers: {
          'Content-Type': 'application/json'
        }
      });
      logger.info(`[MOMO] - Status response: ${response.data}`);
      const txnStatus = response.data;
      return txnStatus;

    } catch (error) {
      const msg = `Momo payment, service failure: ${error.response?.data?.message}, error code: ${error.response?.data?.resultCode}`;
      logger.error('[MOMO] - payment-url - error: ', msg, error);
      return {
        error: msg
      }
    }
}
