import { customAlphabet } from 'nanoid';
import { nexoEncrypt } from '@fingermarkglobal/nexo';

const nanoid = customAlphabet('123456789', 10);

const buildRequest = ({ body, config, category }) => {
  const { settings } = config;
  const { poiId: POIID, keyIdentifier, keyVersion, passphrase, saleId, isLocal = true } = settings;

  // https://docs.adyen.com/point-of-sale/terminal-api-fundamentals#api-structure
  const header = {
    ProtocolVersion: '3.0',
    MessageClass: 'Service',
    MessageCategory: category,
    MessageType: 'Request',
    SaleID: saleId,
    ServiceID: nanoid(),
    POIID,
  };

  // entire message structure needs to be encrypted
  const message = {
    SaleToPOIRequest: {
      MessageHeader: header,
      ...body,
    },
  };
  // Cloud comms does not need to be encrypted
  if (!isLocal) return message;

  const { blob, trailer } = nexoEncrypt({
    keyId: keyIdentifier,
    keyVersion,
    secret: passphrase,
    data: message,
  });

  // encrypted message structure put inside unencrypted message structure 🤯
  return {
    SaleToPOIRequest: {
      MessageHeader: header,
      NexoBlob: blob,
      SecurityTrailer: trailer,
    },
  };
};

export { buildRequest };
