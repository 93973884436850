import { createMachine, assign, DoneInvokeEvent } from 'xstate';
import { placeOrder } from '../requests/place-order';
import { pollingOrder } from '../requests/polling-order';
import { PlaceOrderPayload, CognizantOrderProviderSettings } from '@fingermarkglobal/types';
import { checkWebhookUse } from '../helpers/checkWebhookUse';
import { checkWebhookAutoRetry } from '../helpers/checkWebhookAutoRetry';

const populateMessage: any = assign({
  data: (_, payload: any) => {
    const message = payload?.message;
    const data = payload?.data;
    const error = message?.message || data?.message;
    
    if (data && !error) {
      logger.debug(`[fm-api][order/place] - data received:`, data);
      return data
    }

    if (error) {
      logger.error(`[fm-api][order/place] - error received:`, error);
      return error
    }

    logger.error(`[fm-api][order/place] - message received:`, message);
    return message;
  }
});

const updateSessionContext: any = assign({
  payload: (context: any, payload: any) => {
    if (payload?.data?.session && context?.payload) {
      const { kitchenId } = payload?.data?.session;
      return {
        ...context?.payload,
        session: {
          ...context?.payload.session,
          ...(kitchenId ? { kitchenId } : null),
        }
      };
    }
    return context?.payload;
  }
});

const updateWebhookUse: any = assign({
  webhookUse: (_, event: DoneInvokeEvent<any>) => event.data
});

const updateWebhookAutoRetryUse: any = assign({
  webhookAutoRetryUse: (_, event: DoneInvokeEvent<any>) => event.data
});

const updateWebhookPollingAutoRetry: any = assign({
  executeAutoRetry: (_, payload: any) => {
    const { executeAutoRetry: executeAutoRetryFromResponse = false } = payload?.data?.session || {};

    if (executeAutoRetryFromResponse && executeAutoRetryFromResponse === true) {
      logger.debug('[fm-api][order/place] - executeAutoRetryFromResponse is true');
      return true;
    }

    logger.debug('[fm-api][order/place] - executeAutoRetryFromResponse is false');
    return false;
  }
});

const createPlaceOrderMachine = ({
  payload = null,
  timeout = 30,
  ip = undefined,
  provider = ''
} = {}) =>
  createMachine<{
    ip?: string;
    data: unknown;
    timeout: number;
    payload: null | PlaceOrderPayload;
    provider: string;
    executeAutoRetry: boolean;
    webhookUse: null;
    webhookAutoRetryUse: null;
  }>(
    {
      id: 'placeOrder',
      initial: 'processing',
      context: {
        ip,
        payload,
        timeout,
        data: null,
        provider,
        executeAutoRetry: false,
        webhookUse: null,
        webhookAutoRetryUse: null,
      },
      states: {
        processing: {
          invoke: {
            src: 'placeOrder',
            onDone: {
              target: 'checkWebhookUse',
              actions: ['populateMessage', 'updateSessionContext']
            },
            onError: {
              target: 'error',
            }
          }
        },
        checkWebhookUse: {
          invoke: {
            src: 'checkWebhookUse',
            onDone: {
              actions: ['updateWebhookUse'],
            },
            onError: {
              target: 'success', //This will be triggered if no webhook is used, skipping the step
            }
          },
          always: [
            {
              target: 'performWebhookPolling',
              cond: (context) => context.webhookUse === true,
            },
            {
              target: 'success',
              cond: (context) => context.webhookUse === false,
            },
          ],
        },
        performWebhookPolling: {
          invoke: {
            src: 'webhookPolling',
            onDone: {
              target: 'checkWebhookAutoRetry',
              actions: ['populateMessage', 'updateWebhookPollingAutoRetry']
            },
            onError: {
              target: 'error',
              actions: ['populateMessage']
            }
          }
        },
        checkWebhookAutoRetry: {
          invoke: {
            src: 'checkWebhookAutoRetry',
            onDone: {
              target: 'retryPooling',
              actions: ['updateWebhookAutoRetryUse'],
            },
            onError: {
              target: 'success',
            }
          },
        },
        retryPooling: {
          always: [
            {
              target: 'performWebhookPolling',
              cond: context => context.webhookAutoRetryUse === true,
            },
            {
              target: 'success',
              cond: context => context.webhookAutoRetryUse === false,
            }
          ]
        },
        success: {
          on: {
            // TODO rename this to `RETRY`
            IDLE: 'processing'
          }
        },
        error: { type: 'final', entry: ['populateMessage'] }
      }
    },
    {
      actions: {
        populateMessage,
        updateSessionContext,
        updateWebhookPollingAutoRetry,
        updateWebhookUse,
        updateWebhookAutoRetryUse
      },
      services: {
        placeOrder: ({ payload, timeout, ip }) => placeOrder(payload as any, timeout, ip, provider),
        checkWebhookUse: ({ payload }) => checkWebhookUse(payload?.settings?.provider as CognizantOrderProviderSettings),
        webhookPolling: ({ payload, timeout, ip }) => pollingOrder(payload as PlaceOrderPayload, timeout, ip, provider),
        checkWebhookAutoRetry: ({ executeAutoRetry }) => checkWebhookAutoRetry(executeAutoRetry as boolean),
      }
    }
  );

export { createPlaceOrderMachine };
