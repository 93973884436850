import { FetchCouponResponse, Headers, Product, Provider } from '../types';
import { request, getJwtToken } from '@fingermarkglobal/request';
import { check } from '@fingermarkglobal/validation';
import { buildOrderCouponsEndpoint } from '../../shared/endpoint.builder';

const buildAdditionalHeaders = ({
  settingsHeaders,
  requestHeaders,
}: {
  settingsHeaders: Headers;
  requestHeaders: Headers;
}) => {
  for (const [key, value] of Object.entries(settingsHeaders)) {
    requestHeaders[key] = value;
  }
};

const fetchProductsFromCoupon = async ({
  couponNumber,
  provider,
  type,
  headers: settingsHeaders,
}: {
  couponNumber: number | null;
  provider?: Provider;
  type?: string;
  headers?: Headers;
}): Promise<Product[]> => {
  try {
    logger.info('[fm-api][order/coupon] - started');

    const invalid = check({
      name: 'fetchProductsFromCoupon',
      parameters: { provider },
    });
    if (invalid) throw new Error(`[fm-api][order/coupon] - failed to validate parameters [${invalid}]`);

    const jwtToken = await getJwtToken();
    if (!jwtToken) throw new Error(`[fm-api][order/coupon] error generating jwt token`);

    const payload = {
      couponCode: couponNumber,
      settings: {
        provider: {
          endpoint: provider?.endpoint,
          apiKey: provider?.apiKey,
        },
        kiosk: {
          headers: {},
        },
      },
    };

    const requestHeaders = {
      Authorization: `Bearer ${jwtToken}`,
      'x-api-key': provider?.apiKey,
      'Content-Type': 'application/json',
    };

    if (settingsHeaders) {
      buildAdditionalHeaders({ settingsHeaders, requestHeaders });
    }

    const endpoint = buildOrderCouponsEndpoint({provider: type});

    const response: FetchCouponResponse = await request
      .post(endpoint, {
        json: payload,
        headers: requestHeaders,
      })
      .json();

    if (response?.isValidCoupon) {
      return new Promise(resolve =>
        resolve([{ productId: response.menuItemId, price: null, filterId: response.filterId }]),
      );
    }
    return new Promise(reject => reject([]));
  } catch (error) {
    logger.error(`[fm-api][order/coupon] - error received:`, error);
    throw error;
  } finally {
    logger.info('[fm-api][order/coupon] - finished');
  }
};

export { fetchProductsFromCoupon };
