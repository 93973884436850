const checkTransactionResponse = response => {
  if (!response) {
    throw new Error('Invalid response');
  }

  const { result = {}, canReload = false } = response;
  const { commandId = '' } = result;

  if (!canReload) throw new Error('Failed to queue payment.');

  if (!commandId) {
    throw new Error('Failed to queue payment. No Command ID found.');
  }

  return result?.commandId;
};

export { checkTransactionResponse };
